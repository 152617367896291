import { useEffect, useState } from 'react';
import { assertIsError } from './assert';
import { setIntervalWithTimeout } from './utils';
import { useWindowFocus } from './windowActive';

export const useRefreshToken = (
  fn: (...args: any) => Promise<any> | any,
  duration: number,
  clearTime?: () => boolean,
) => {
  const [error, setError] = useState<Error>();
  const isWindowActive = useWindowFocus();
  useEffect(() => {
    if (!isWindowActive || clearTime?.()) {
      return;
    }
    const refreshFn = async () => {
      try {
        await fn();
      } catch (e) {
        assertIsError(e);
        setError(e);
      }
    };
    return setIntervalWithTimeout(() => refreshFn(), duration, clearTime);
  }, [clearTime, duration, fn, isWindowActive]);
  if (error != null) {
    throw error;
  }
};
