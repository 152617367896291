import { UserRoleEnum } from '@clients/graphql-client';
import { services } from '@clients/rest-api-client';
import authStore from '../auth/auth.store';
import { assertIsError } from './assert';
import { getLimitError } from './limitError';
import { calculatePDFHash } from './utils';

type UploadFileResponse =
  | {
      status: 'done';
      conversation_id: string;
    }
  | {
      status: 'error';
      message: React.ReactNode;
    };

const getLimit = (role: UserRoleEnum) => {
  switch (role) {
    case UserRoleEnum.Admin:
    case UserRoleEnum.Vip:
      return 30;
    case UserRoleEnum.User:
      return 5;
    default:
      return 3;
  }
};
    
export const uploadFile = async (file: any) => {
  const limit = getLimit(authStore.user?.role ?? UserRoleEnum.Guest);

  const isLimit = file.size / 1024 / 1024 < limit;
  if (!isLimit) {
    const data: UploadFileResponse = {
      status: 'error',
      message: `文件必须小于 ${limit}MB!`,
    };
    return data;
  }

  try {
    const hash = await calculatePDFHash(file);

    const result =
      await services.fileService.fileApiControllerGenerateFilePresignedUrl({
        GenerateFilePresignedUrlRequest: {
          file_name: file.name,
          hash: hash,
        },
      });

    if (result.is_uploaded) {
      const data: UploadFileResponse = {
        conversation_id: result.conversation_id,
        status: 'done',
      };
      return data;
    }
    await fetch(result.url, {
      method: 'PUT',
      body: file,
    });

    const res =
      await services.fileService.fileApiControllerChangeFileUploaded({
        ChangeFileUploadedRequest: {
          file_id: result.file_id,
          file_name: file.name,
          hash: hash,
          size: file.size,
        },
      });

    const data: UploadFileResponse = {
      conversation_id: res.conversation_id,
      status: 'done',
    };
    return data;
  } catch (e) {
    assertIsError(e);
    const data: UploadFileResponse = {
      status: 'error',
      message: getLimitError(e) ?? e.message,
    };

    return data;
  }
};
