import { Avatar, Button, Menu, Modal } from 'antd';
import clsx from 'clsx';
import { ComponentProps, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import authStore from '../../auth/auth.store';
import { HelpDoc } from '../../components/doc/Helper';
import {
  AntdIconWithSvgIcon,
  SvgNameKeys,
  ZSvgNameKeys,
} from '../../components/icon';
import { useObservable } from '../../utils/useObservable';
import { Preferences } from './Preferences';
import { ResetPassword } from './ResetPassword';
import { UserInfo } from './UserInfo';

enum MenuEnum {
  HOME = 'HOME',
  PREFERENCES = 'PREFERENCES',
  SCHEDULE = 'SCHEDULE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  HELP = 'HELP',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

type itemType = NonNullable<ComponentProps<typeof Menu>['items']>[0] & {
  key: MenuEnum;
  icon: SvgNameKeys;
  component: React.FC;
};

const menuItems: itemType[] = [
  {
    key: MenuEnum.HOME,
    icon: ZSvgNameKeys.Enum.User,
    label: '个人信息',
    component: UserInfo,
  },
  {
    key: MenuEnum.PREFERENCES,
    icon: ZSvgNameKeys.Enum.Preferences,
    label: '偏好设置',
    component: Preferences,
  },
  // {
  //   key: MenuEnum.SCHEDULE,
  //   icon: ZSvgNameKeys.Enum.Schedule,
  //   label: '当前计划',
  //   component: UserInfo,
  // },
  // {
  //   key: MenuEnum.SUBSCRIPTION,
  //   icon: ZSvgNameKeys.Enum.Subscription,
  //   label: '订阅记录',
  //   component: UserInfo,
  // },
  {
    key: MenuEnum.RESET_PASSWORD,
    icon: ZSvgNameKeys.Enum.ResetPassword,
    label: '修改密码',
    component: ResetPassword,
  },
  {
    key: MenuEnum.HELP,
    icon: ZSvgNameKeys.Enum.Help,
    label: '帮助中心',
    component: () => (
      <div className="p-4">
        <HelpDoc />
      </div>
    ),
  },
];

export const UserCenter = () => {
  const navigate = useNavigate();
  const user = authStore.user;
  const [currentMenu, setCurrentMenu] = useState(MenuEnum.HOME);
  const MenuComponent = useMemo(() => {
    return menuItems.find(r => r.key === currentMenu)?.component;
  }, [currentMenu]);

  return (
    <div className="flex h-full user_center">
      <div
        className={clsx(
          'w-36 pb-[10px]',
          'flex flex-col justify-between items-center gap-4',
          'border-0 border-r-1 border-[#00000033] border-solid',
        )}
      >
        <div className="w-full text-center flex flex-col gap-2">
          <div
            className={clsx(
              'py-3 px-2 flex justify-start items-center gap-2',
              'border-0 border-b-1 border-solid border-[#00000033]',
            )}
          >
            <div className="w-[30px]">
              <Avatar
                className={clsx('cursor-pointer select-none', {
                  'bg-gray-300': user.avatar_url != null,
                  'bg-[#f56a00]': user.avatar_url == null,
                })}
                src={user.avatar_url}
              >
                {(user.nickname ?? user.email)?.substring(0, 1).toUpperCase() ??
                  'U'}
              </Avatar>
            </div>
            <div className="flex-1 overflow-hidden flex flex-col justify-start items-start text-[12px]">
              <div className="overflow-hidden whitespace-nowrap text-ellipsis block w-full text-left">
                {user.nickname ?? user.email}
              </div>
              <div className="w-1/2 border-0 border-b-1 border-solid border-[#00000033]" />
              <div className="overflow-hidden whitespace-nowrap text-ellipsis block w-full text-left">
                {user.email}
              </div>
            </div>
          </div>
          <Menu
            onClick={e => {
              setCurrentMenu(e.key as MenuEnum);
            }}
            selectedKeys={[currentMenu]}
            mode="inline"
            items={menuItems.map(r => ({
              ...r,
              icon: (
                <AntdIconWithSvgIcon
                  className={clsx({
                    'text-[#1890ff]': r.key === currentMenu,
                  })}
                  iconName={r.icon}
                />
              ),
            }))}
          />
        </div>
        <Button
          className="!text-[12px] text-gray-500"
          size="small"
          type="dashed"
          onClick={() => {
            Modal.confirm({
              title: '确认退出账号？',
              okType: 'danger',
              maskClosable: true,
              onOk: async () => {
                await authStore.logout();
                toggleUserCenterModal(false);
                navigate('/');
              },
            });
          }}
        >
          退出账号
        </Button>
      </div>
      <div className="overflow-auto h-full flex-1">
        {MenuComponent && <MenuComponent />}
      </div>
    </div>
  );
};

const userCenterModal$ = new BehaviorSubject(false);

export const toggleUserCenterModal = (isOpen: boolean) => {
  userCenterModal$.next(isOpen);
};

export const UserCenterModal = () => {
  const isModalOpen = useObservable(() => userCenterModal$, 'userCenterModal');

  const handleCancel = () => {
    toggleUserCenterModal(false);
  };

  return (
    <Modal
      open={!!isModalOpen}
      width={700}
      title={
        <div
          className={clsx(
            'px-[24px] pt-[20px] pb-[10px]',
            'border-0 border-b-1 border-solid border-[#00000033]',
          )}
        >
          个人中心
        </div>
      }
      onCancel={handleCancel}
      footer={null}
      style={{ top: 60 }}
      styles={{
        content: { padding: 0 },
        header: { margin: 0 },
        body: { padding: 0, height: 'calc(100vh - 200px)' },
      }}
    >
      <UserCenter />
    </Modal>
  );
};
