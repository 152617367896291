/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface ChangeFileUploadedRequest
 */
export interface ChangeFileUploadedRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeFileUploadedRequest
     */
    file_id: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeFileUploadedRequest
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeFileUploadedRequest
     */
    file_name: string;
    /**
     * 
     * @type {number}
     * @memberof ChangeFileUploadedRequest
     */
    size: number;
}
/**
 * 
 * @export
 * @interface ChangeFileUploadedResponse
 */
export interface ChangeFileUploadedResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeFileUploadedResponse
     */
    user_file_id: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeFileUploadedResponse
     */
    conversation_id: string;
}
/**
 * 
 * @export
 * @interface ChangeLLMModelRequest
 */
export interface ChangeLLMModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeLLMModelRequest
     */
    model: ChangeLLMModelRequestModelEnum;
}


/**
 * @export
 */
export const ChangeLLMModelRequestModelEnum = {
    _35: 'openai:gpt-3.5',
    _4: 'openai:gpt-4'
} as const;
export type ChangeLLMModelRequestModelEnum = typeof ChangeLLMModelRequestModelEnum[keyof typeof ChangeLLMModelRequestModelEnum];

/**
 * 
 * @export
 * @interface CreateChatRequest
 */
export interface CreateChatRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateChatRequest
     */
    conversation_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChatRequest
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChatRequest
     */
    language_option?: CreateChatRequestLanguageOptionEnum;
}


/**
 * @export
 */
export const CreateChatRequestLanguageOptionEnum = {
    En: 'en',
    Zh: 'zh',
    SourceLanguage: 'sourceLanguage'
} as const;
export type CreateChatRequestLanguageOptionEnum = typeof CreateChatRequestLanguageOptionEnum[keyof typeof CreateChatRequestLanguageOptionEnum];

/**
 * 
 * @export
 * @interface CreateChatResponse
 */
export interface CreateChatResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateChatResponse
     */
    conversation_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateChatResponse
     */
    answer: string;
}
/**
 * 
 * @export
 * @interface CreateOrGetInviteCodeResponse
 */
export interface CreateOrGetInviteCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateOrGetInviteCodeResponse
     */
    code: string;
}
/**
 * 
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    orderUrl: string;
}
/**
 * 
 * @export
 * @interface CreateQuestionsBySummaryRequest
 */
export interface CreateQuestionsBySummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateQuestionsBySummaryRequest
     */
    question_num?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionsBySummaryRequest
     */
    conversation_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionsBySummaryRequest
     */
    language_option?: CreateQuestionsBySummaryRequestLanguageOptionEnum;
}


/**
 * @export
 */
export const CreateQuestionsBySummaryRequestLanguageOptionEnum = {
    En: 'en',
    Zh: 'zh',
    SourceLanguage: 'sourceLanguage'
} as const;
export type CreateQuestionsBySummaryRequestLanguageOptionEnum = typeof CreateQuestionsBySummaryRequestLanguageOptionEnum[keyof typeof CreateQuestionsBySummaryRequestLanguageOptionEnum];

/**
 * 
 * @export
 * @interface CreateQuestionsBySummaryResponse
 */
export interface CreateQuestionsBySummaryResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateQuestionsBySummaryResponse
     */
    questions: Array<string>;
}
/**
 * 
 * @export
 * @interface GenPublicImageUploadURLRequest
 */
export interface GenPublicImageUploadURLRequest {
    /**
     * 
     * @type {string}
     * @memberof GenPublicImageUploadURLRequest
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface GenPublicImageUploadURLResponse
 */
export interface GenPublicImageUploadURLResponse {
    /**
     * 
     * @type {string}
     * @memberof GenPublicImageUploadURLResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GenPublicImageUploadURLResponse
     */
    imageUrl: string;
}
/**
 * 
 * @export
 * @interface GenerateFilePresignedUrlRequest
 */
export interface GenerateFilePresignedUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateFilePresignedUrlRequest
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateFilePresignedUrlRequest
     */
    file_name: string;
}
/**
 * 
 * @export
 * @interface GenerateFilePresignedUrlResponse
 */
export interface GenerateFilePresignedUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateFilePresignedUrlResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateFilePresignedUrlResponse
     */
    user_file_id: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateFilePresignedUrlResponse
     */
    file_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateFilePresignedUrlResponse
     */
    is_uploaded: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateFilePresignedUrlResponse
     */
    conversation_id: string;
}
/**
 * 
 * @export
 * @interface GetFileUrlResponse
 */
export interface GetFileUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetFileUrlResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface GetSummaryRequest
 */
export interface GetSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof GetSummaryRequest
     */
    conversation_id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryRequest
     */
    language_option?: GetSummaryRequestLanguageOptionEnum;
}


/**
 * @export
 */
export const GetSummaryRequestLanguageOptionEnum = {
    En: 'en',
    Zh: 'zh',
    SourceLanguage: 'sourceLanguage'
} as const;
export type GetSummaryRequestLanguageOptionEnum = typeof GetSummaryRequestLanguageOptionEnum[keyof typeof GetSummaryRequestLanguageOptionEnum];

/**
 * 
 * @export
 * @interface GetSummaryResponse
 */
export interface GetSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponse
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryResponse
     */
    language: string;
}
/**
 * 
 * @export
 * @interface GuestLoginRequest
 */
export interface GuestLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof GuestLoginRequest
     */
    device_id: string;
}
/**
 * 
 * @export
 * @interface GuestLoginResponse
 */
export interface GuestLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof GuestLoginResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof GuestLoginResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface LoginByPasswordRequest
 */
export interface LoginByPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginByPasswordRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginByPasswordRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LoginByPasswordResponse
 */
export interface LoginByPasswordResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginByPasswordResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof LoginByPasswordResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface MockUserLoginRequest
 */
export interface MockUserLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof MockUserLoginRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface MockUserLoginResponse
 */
export interface MockUserLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof MockUserLoginResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof MockUserLoginResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    refresh_token: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface RegisterByEmailRequest
 */
export interface RegisterByEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailRequest
     */
    register_code: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailRequest
     */
    invite_code?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailRequest
     */
    device_id?: string;
}
/**
 * 
 * @export
 * @interface RegisterByEmailResponse
 */
export interface RegisterByEmailResponse {
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterByEmailResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    old_password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    new_password: string;
}
/**
 * 
 * @export
 * @interface SendBugMessageToSlackRequest
 */
export interface SendBugMessageToSlackRequest {
    /**
     * 
     * @type {string}
     * @memberof SendBugMessageToSlackRequest
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SendBugMessageToSlackRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SendBugMessageToSlackRequest
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface SendRegisterCaptchaByEmailRequest
 */
export interface SendRegisterCaptchaByEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendRegisterCaptchaByEmailRequest
     */
    email: string;
}
