import { ZLanguageOption } from '@libs/types';
import { Button, Dropdown } from 'antd';
import { languageItems } from '../../../../../types/chat';
import { useContext } from 'react';
import { ChatStoreContext } from '../../../chat.store';

export const SwitchLanguage = () => {
  const store = useContext(ChatStoreContext);

  return (
    <Dropdown
      placement="topLeft"
      menu={{
        selectable: true,
        defaultSelectedKeys: [store.language],
        items: languageItems.map(r => {
          return {
            key: r.key,
            label: r.label,
            disabled:
              ZLanguageOption.Enum.sourceLanguage === r.key &&
              !store.fileInfo?.file?.language,
            onClick: () => {
              store.changeLanguage(r.key);
            },
          };
        }),
      }}
    >
      <Button size="small">
        {languageItems.find(r => store.language === r.key)?.label ?? '语言设置'}
      </Button>
    </Dropdown>
  );
};
