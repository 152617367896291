export { ReactComponent as Collapse } from './svgs/collapse.svg';
export { ReactComponent as Cry } from './svgs/cry.svg';
export { ReactComponent as Expand } from './svgs/expand.svg';
export { ReactComponent as Feedback } from './svgs/feedback.svg';
export { ReactComponent as Gift } from './svgs/gift.svg';
export { ReactComponent as Help } from './svgs/help.svg';
export { ReactComponent as Preferences } from './svgs/preferences.svg';
export { ReactComponent as Remove } from './svgs/remove.svg';
export { ReactComponent as Rocket } from './svgs/rocket.svg';
export { ReactComponent as Schedule } from './svgs/schedule.svg';
export { ReactComponent as Smile } from './svgs/smile.svg';
export { ReactComponent as Subscription } from './svgs/subscription.svg';
export { ReactComponent as User } from './svgs/user.svg';
// reset_password
export { ReactComponent as ResetPassword } from './svgs/reset_password.svg';
// redo
export { ReactComponent as Redo } from './svgs/redo.svg';
// book
export { ReactComponent as Book } from './svgs/book.svg';
// book2
export { ReactComponent as Book2 } from './svgs/book2.svg';
// alipay
export { ReactComponent as Alipay } from './svgs/alipay.svg';
// plan
export { ReactComponent as Plan } from './svgs/plan.svg';