import { ReactNode, createContext, useContext } from 'react';
import authService from './auth.service';
import chatService from './chat.service';
import fileService from './file.service';
import imageService from './image.service';
import userService from './user.service';
import payService from './pay.service';

export const services = {
  authService,
  userService,
  payService,
  fileService,
  imageService,
  chatService,
};

const ServiceFactoryContext = createContext<ServiceFactory>(null as any);

export type ServiceFactory = typeof services;

export const useServiceFactory = (): ServiceFactory =>
  useContext(ServiceFactoryContext);

export const ServiceFactoryProvider = (props: {
  services?: typeof services;
  children: ReactNode;
}) => {
  return (
    <ServiceFactoryContext.Provider value={props.services ?? services}>
      {props.children}
    </ServiceFactoryContext.Provider>
  );
};
