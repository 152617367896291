import {
  ApiError,
  ErrorInfo,
  LocalizedMessage,
  StatusCode,
} from '@chat/libs-types';
import { Middleware, ResponseContext } from '../../../generated/api';
import { DisplayableError } from '../../DisplayableError';

export class APIErrorWrapper extends DisplayableError {
  constructor(readonly error: Error | ApiError['error']) {
    if (isApiError(error)) {
      super(messageFromErrorDetails(error.details) ?? error.message, {
        cause: error,
      });
    } else {
      super(error.message, { cause: error });
    }
  }

  get code(): undefined | number {
    return isApiError(this.error) ? this.error.code : undefined;
  }

  get status(): undefined | StatusCode {
    return isApiError(this.error) ? this.error.status : undefined;
  }

  get details(): undefined | ApiError['error']['details'] {
    return isApiError(this.error) ? this.error.details : undefined;
  }
}

export const isErrorInfo = (
  detail: ApiError['error']['details'][number],
): detail is ErrorInfo => {
  return detail['@type'] === 'type.googleapis.com/google.rpc.ErrorInfo';
};

const isApiError = (
  err: Error | ApiError['error'],
): err is ApiError['error'] => {
  const isErrNotNullable = err != null;
  const isErrSupportInOperator = typeof err === 'object';
  return isErrNotNullable && isErrSupportInOperator && 'details' in err;
};

const isUnauthorizedError = (err: ApiError['error']) => {
  return err.status === StatusCode.UNAUTHENTICATED;
};

const messageFromErrorDetails = (
  details: ApiError['error']['details'],
): undefined | string => {
  return details.find(
    (d): d is LocalizedMessage =>
      d['@type'] === 'type.googleapis.com/google.rpc.LocalizedMessage',
  )?.message;
};

class ErrorWrappingMiddleware implements Middleware {
  async post(context: ResponseContext): Promise<Response | void> {
    if (!context.response.ok) {
      const text = await context.response.text();
      let error: Error;
      try {
        const json = JSON.parse(text) as ApiError;
        error = new APIErrorWrapper(json.error);
      } catch {
        error = new APIErrorWrapper(new Error(text));
      }
      throw error;
    }
    return context.response;
  }
}

export default ErrorWrappingMiddleware;
