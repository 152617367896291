import { useDebugValue, useEffect } from 'react';
import { BehaviorSubject, NEVER, Observable, of } from 'rxjs';
import { fromSubscribable } from '../utils/rxExtensions';
import { useUpdateEffect } from './reactExtensions';
import { ObservableResource } from './rxReact/ObservableResource';
import { useObservableResourceHolder } from './rxReact/ObservableResourceHolder';
import { useObservableResource } from './rxReact/useObservableResource';

type LegacyArgs<T> = [
  source: Observable<T> | (() => Observable<T>),
  observableId: string,
  startWithValue?: T,
];

type WithInputsArgs<T, Inputs extends any[]> = [
  source: (inputs$: Observable<Inputs>) => Observable<T>,
  inputs: Inputs,
  observableId: string,
  startWithValue?: T,
];

const isDependencyListArgs = <T, Inputs extends any[]>(
  args: LegacyArgs<T> | WithInputsArgs<T, Inputs>,
): args is WithInputsArgs<T, Inputs> => {
  return Array.isArray(args[1]);
};

export function useObservable<T>(...args: LegacyArgs<T>): T;
export function useObservable<T, Inputs extends any[]>(
  ...args: WithInputsArgs<T, Inputs>
): T;
export function useObservable<T, Inputs extends any[]>(
  ...args: LegacyArgs<T> | WithInputsArgs<T, Inputs>
): T {
  const resourceHolder = useObservableResourceHolder();

  const [source, inputs, observableId, startWithValue] = isDependencyListArgs(
    args,
  )
    ? args
    : [args[0], undefined, args[1], args[2]];

  const inputsResource = resourceHolder.getOrCreate(
    `${observableId}$$$$inputs`,
    () => new ObservableResource(inputs == null ? NEVER : of(inputs)),
  );

  const valueResource = resourceHolder.getOrCreate(
    observableId,
    () =>
      new ObservableResource<T>(
        typeof source === 'function'
          ? source(fromSubscribable(inputsResource))
          : source,
      ),
  );

  useUpdateEffect(
    () => {
      if (inputs == null) return;
      inputsResource.reset(of(inputs));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputsResource, ...(inputs ?? [])],
  );

  const latestValue = useObservableResource(valueResource, startWithValue);
  useDebugValue(latestValue);
  return latestValue;
}

const globalBehaviorSubjects: Map<string, BehaviorSubject<unknown>> = new Map();
export function useObservableAutoResetInput<T>(
  initValue: T,
  key: string,
): BehaviorSubject<T> {
  useEffect(() => {
    globalBehaviorSubjects.get(key)?.next(initValue);
    return () => globalBehaviorSubjects.get(key)?.next(initValue);
  }, [initValue, key]);
  return useObservableInput(initValue, key);
}
export function useObservableInput<T>(
  initValue: T,
  key: string,
): BehaviorSubject<T> {
  if (globalBehaviorSubjects.has(key)) {
    return globalBehaviorSubjects.get(key)! as BehaviorSubject<T>;
  } else {
    const subject = new BehaviorSubject(initValue);
    globalBehaviorSubjects.set(key, subject as any);
    return subject;
  }
}
