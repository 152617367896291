import { Button } from 'antd';
import { Markdown } from '../Markdown';
import { ModalButton } from './ModalButton';

type TermsProps = {
  buttonProps?: React.ComponentProps<typeof Button>;
  children?: React.ReactNode;
};

export const Terms = ({ buttonProps, children }: TermsProps) => {
  return (
    <ModalButton
      modalProps={{
        title: <div className="text-center">ChatPDF 条款文档</div>,
      }}
      modalContent={<Markdown>{termsDoc}</Markdown>}
    >
      {children ?? <Button {...buttonProps}>条款</Button>}
    </ModalButton>
  );
};

const termsDoc = `
重庆南杉图科技有限公司在本网站上向您提供信息和产品，并遵守以下条款和条件。访问本网站即表示您同意这些条款和条件。ChatPDF 保留对任何违反这些条款和条件的行为寻求法律和衡平法上的所有补救措施的权利。

保留本文未明确授予的任何权利。

使用任何可在互联网上下载的软件都存在固有的危险，在下载任何软件之前，请确保您完全了解所有风险（包括但不限于您的计算机可能受到感染）。系统因计算机病毒而导致数据丢失）。您全权负责对与任何软件相关的数据和设备进行充分保护和备份。

**图像：** 本网站上显示的所有徽标、启动画面、页眉、图像和图形均为 ChatPDF 或其第三方许可方的服务标记、商标和/或商业外观（统称为“标记”）。除非明确许可在此，未经 ChatPDF 明确书面许可，禁止以任何形式或方式使用、复制、传输、展示、修改或分发任何标志，并且可能违反版权、商标、隐私或其他当地法律。

**第三方网站和产品：** ChatPDF 的网站和产品可能包括第三方网站拥有的产品或服务。第三方网站拥有的此类产品或服务只是为了您的方便。ChatPDF 不对第三方网站拥有的此类产品或服务的任何内容的许可限制和合法性负责。在使用第三方网站拥有的此类产品或服务时，您可能需要查看并同意适用的使用规则。此外，第三方网站的链接并不意味着 ChatPDF 认可该网站或其中引用的产品或服务。

**赔偿：** 您同意为 ChatPDF、其附属公司及其管理人员、董事、代理人和员工辩护、赔偿并使其免受任何和所有索赔、损失、损害、责任、成本和费用（包括律师费）的损害。来自或与您的用户内容、对本网站的使用或违反任何这些条款有关的行为。

**反馈：** 发送至 ChatPDF 的任何意见或材料，包括但不限于反馈，例如有关本软件、本网站或 ChatPDF 的任何其他产品、程序或服务的问题、评论、建议或任何相关信息（“反馈”），应视为非机密。ChatPDF 对此类反馈不承担任何义务，并且可以自由复制、使用、披露、展示、展示、转换、创建衍生作品以及将反馈分发给其他人，不受限制，并且可以自由使用任何想法此类反馈中包含的概念、专有技术或技术，用于任何目的，包括但不限于开发、

**复制品：** 本文所含任何信息的任何授权复制品必须在您制作的材料的任何副本上包含 ChatPDF 的版权声明、商标或其他专有图例。当地法律管辖软件的许可和本网站的使用。

**版权：** 本网站的版权（包括但不限于文本、图形、徽标、声音和软件）归我司所有并授权。本网站包含的所有材料均受当地和国际版权法保护，不得未经我司事先书面许可，不得以任何形式、任何手段或在任何媒体上复制、转载、分发、传播、展示、出版、改编或处理。您不得更改或删除任何版权或内容。内容副本中的其他通知。

如有任何疑问，请联系我们。谢谢。
`;
