import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Observer,
  Subject,
  switchAll,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { shareReplayWithDelay } from '../../utils/rxExtensions';
import { assertNever } from '../../utils/typeHelper';

type ObservableResourceState<T> =
  | { type: 'error'; error: any }
  | { type: 'value'; value: T }
  | { type: 'idle' };

export class ObservableResource<T> {
  private observableSource: Subject<Observable<T>>;

  private finalObservable: Observable<T>;

  private state: ObservableResourceState<T> = { type: 'idle' };

  constructor(observable: Observable<T>) {
    this.observableSource = new BehaviorSubject(observable);
    this.finalObservable = this.wrapObservable(this.observableSource);

    /**
     * Do a quick subscribe, if `observable` ready has value (like
     * ReplaySubject), make `ObservableResource` become "value" state more
     * eagerly
     */
    this.finalObservable.subscribe().unsubscribe();
  }

  subscribe(observer?: Partial<Observer<T>>): {
    unsubscribe: () => void;
  } {
    return this.finalObservable.subscribe(observer);
  }

  reset(observable?: Observable<T>): void {
    if (observable != null) {
      this.observableSource.next(observable);
    }
    this.state = { type: 'idle' };
  }

  read() {
    if (this.state.type === 'error') {
      throw this.state.error;
    }
    if (this.state.type === 'value') {
      return this.state.value;
    }
    if (this.state.type === 'idle') {
      throw firstValueFrom(this.finalObservable);
    }
    assertNever(this.state);
  }

  reveal():
    | { type: 'error'; error: any }
    | { type: 'value'; value: T }
    | { type: 'pending'; getPromise: () => Promise<T> } {
    if (this.state.type === 'error') {
      return { type: 'error', error: this.state.error };
    }
    if (this.state.type === 'value') {
      return { type: 'value', value: this.state.value };
    }
    if (this.state.type === 'idle') {
      return {
        type: 'pending',
        getPromise: () => firstValueFrom(this.finalObservable),
      };
    }
    assertNever(this.state);
  }

  private wrapObservable(
    observableSource: Observable<Observable<T>>,
  ): Observable<T> {
    return observableSource.pipe(
      switchAll(),
      tap({
        next: v => {
          this.state = { type: 'value', value: v };
        },
        error: e => {
          this.state = { type: 'error', error: e };
        },
      }),
      shareReplayWithDelay(
        {
          bufferSize: 1,
        },
        10 * 1000,
      ),
    );
  }
}
