/* tslint:disable */
/* eslint-disable */
/**
 * AI Chat PDF API
 * AI Chat PDF API definitions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrGetInviteCodeResponse,
  GuestLoginRequest,
  GuestLoginResponse,
  LoginByPasswordRequest,
  LoginByPasswordResponse,
  MockUserLoginRequest,
  MockUserLoginResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  RegisterByEmailRequest,
  RegisterByEmailResponse,
  ResetPasswordRequest,
  SendRegisterCaptchaByEmailRequest,
} from '../models';

export interface AuthApiControllerGuestLoginRequest {
    GuestLoginRequest: GuestLoginRequest;
}

export interface AuthApiControllerLoginByPasswordRequest {
    LoginByPasswordRequest: LoginByPasswordRequest;
}

export interface AuthApiControllerMockUserLoginRequestRequest {
    MockUserLoginRequest: MockUserLoginRequest;
}

export interface AuthApiControllerRefreshTokenRequest {
    RefreshTokenRequest: RefreshTokenRequest;
}

export interface AuthApiControllerRegisterByEmailRequest {
    RegisterByEmailRequest: RegisterByEmailRequest;
}

export interface AuthApiControllerResetPasswordRequest {
    ResetPasswordRequest: ResetPasswordRequest;
}

export interface AuthApiControllerSendRegisterCaptchaByEmailRequest {
    SendRegisterCaptchaByEmailRequest: SendRegisterCaptchaByEmailRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * create or get invite code
     */
    async authApiControllerCreateOrGetInviteCodeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrGetInviteCodeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/auth/invite-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * create or get invite code
     */
    async authApiControllerCreateOrGetInviteCode(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrGetInviteCodeResponse> {
        const response = await this.authApiControllerCreateOrGetInviteCodeRaw(initOverrides);
        return await response.value();
    }

    /**
     * guest login 游客登录
     */
    async authApiControllerGuestLoginRaw(requestParameters: AuthApiControllerGuestLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GuestLoginResponse>> {
        if (requestParameters.GuestLoginRequest === null || requestParameters.GuestLoginRequest === undefined) {
            throw new runtime.RequiredError('GuestLoginRequest','Required parameter requestParameters.GuestLoginRequest was null or undefined when calling authApiControllerGuestLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/auth/guest-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.GuestLoginRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * guest login 游客登录
     */
    async authApiControllerGuestLogin(requestParameters: AuthApiControllerGuestLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GuestLoginResponse> {
        const response = await this.authApiControllerGuestLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * use password login, 密码登录
     */
    async authApiControllerLoginByPasswordRaw(requestParameters: AuthApiControllerLoginByPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginByPasswordResponse>> {
        if (requestParameters.LoginByPasswordRequest === null || requestParameters.LoginByPasswordRequest === undefined) {
            throw new runtime.RequiredError('LoginByPasswordRequest','Required parameter requestParameters.LoginByPasswordRequest was null or undefined when calling authApiControllerLoginByPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.LoginByPasswordRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * use password login, 密码登录
     */
    async authApiControllerLoginByPassword(requestParameters: AuthApiControllerLoginByPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginByPasswordResponse> {
        const response = await this.authApiControllerLoginByPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 模拟用户登录
     */
    async authApiControllerMockUserLoginRequestRaw(requestParameters: AuthApiControllerMockUserLoginRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MockUserLoginResponse>> {
        if (requestParameters.MockUserLoginRequest === null || requestParameters.MockUserLoginRequest === undefined) {
            throw new runtime.RequiredError('MockUserLoginRequest','Required parameter requestParameters.MockUserLoginRequest was null or undefined when calling authApiControllerMockUserLoginRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/auth:mock-user-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.MockUserLoginRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 模拟用户登录
     */
    async authApiControllerMockUserLoginRequest(requestParameters: AuthApiControllerMockUserLoginRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MockUserLoginResponse> {
        const response = await this.authApiControllerMockUserLoginRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * refresh token
     */
    async authApiControllerRefreshTokenRaw(requestParameters: AuthApiControllerRefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshTokenResponse>> {
        if (requestParameters.RefreshTokenRequest === null || requestParameters.RefreshTokenRequest === undefined) {
            throw new runtime.RequiredError('RefreshTokenRequest','Required parameter requestParameters.RefreshTokenRequest was null or undefined when calling authApiControllerRefreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/auth/refresh-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.RefreshTokenRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * refresh token
     */
    async authApiControllerRefreshToken(requestParameters: AuthApiControllerRefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshTokenResponse> {
        const response = await this.authApiControllerRefreshTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * register by email 使用邮箱注册
     */
    async authApiControllerRegisterByEmailRaw(requestParameters: AuthApiControllerRegisterByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterByEmailResponse>> {
        if (requestParameters.RegisterByEmailRequest === null || requestParameters.RegisterByEmailRequest === undefined) {
            throw new runtime.RequiredError('RegisterByEmailRequest','Required parameter requestParameters.RegisterByEmailRequest was null or undefined when calling authApiControllerRegisterByEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/auth/register-by-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.RegisterByEmailRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * register by email 使用邮箱注册
     */
    async authApiControllerRegisterByEmail(requestParameters: AuthApiControllerRegisterByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterByEmailResponse> {
        const response = await this.authApiControllerRegisterByEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * reset password by old password
     */
    async authApiControllerResetPasswordRaw(requestParameters: AuthApiControllerResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ResetPasswordRequest === null || requestParameters.ResetPasswordRequest === undefined) {
            throw new runtime.RequiredError('ResetPasswordRequest','Required parameter requestParameters.ResetPasswordRequest was null or undefined when calling authApiControllerResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ResetPasswordRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * reset password by old password
     */
    async authApiControllerResetPassword(requestParameters: AuthApiControllerResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authApiControllerResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * send sms or email code for login 发注册短信/邮件
     */
    async authApiControllerSendRegisterCaptchaByEmailRaw(requestParameters: AuthApiControllerSendRegisterCaptchaByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.SendRegisterCaptchaByEmailRequest === null || requestParameters.SendRegisterCaptchaByEmailRequest === undefined) {
            throw new runtime.RequiredError('SendRegisterCaptchaByEmailRequest','Required parameter requestParameters.SendRegisterCaptchaByEmailRequest was null or undefined when calling authApiControllerSendRegisterCaptchaByEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/auth/captcha:register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.SendRegisterCaptchaByEmailRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * send sms or email code for login 发注册短信/邮件
     */
    async authApiControllerSendRegisterCaptchaByEmail(requestParameters: AuthApiControllerSendRegisterCaptchaByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authApiControllerSendRegisterCaptchaByEmailRaw(requestParameters, initOverrides);
    }

}
