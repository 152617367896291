import { Button, Tooltip, Tour, TourProps } from 'antd';
import { useLayoutEffect, useMemo, useRef } from 'react';
import authStore from '../../auth/auth.store';
import { preferenceStore } from '../../preference.store';
import { AntdIconWithSvgIcon } from '../icon';

export const Guide = ({ showTitle }: { showTitle?: boolean }) => {
  const ref = useRef(null);

  const steps: TourProps['steps'] = useMemo(() => {
    if (ref.current == null) {
      return [];
    }
    return [
      {
        title: '使用指南',
        description: '点击此处查看使用指南',
        target: ref.current,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, authStore.isGuest]);

  useLayoutEffect(() => {
    if (ref.current == null || preferenceStore.guide !== 'init') {
      return;
    }

    preferenceStore.setGuide('open');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <>
      <div
        ref={ref}
        className="cursor-pointer"
        onClick={() => {
          window.open(
            'https://placeholdersoft.notion.site/Q-A-8c068e4af2194110af9ce0c4a61ca3e2?pvs=4',
          );
        }}
      >
        <Tooltip title="使用指南" className="flex flex-col items-center">
          <AntdIconWithSvgIcon className="text-[30px]" iconName="Book" />
        </Tooltip>
      </div>
      <Tour
        open={preferenceStore.guide === 'open'}
        onClose={() => preferenceStore.setGuide('done')}
        steps={steps}
      />
    </>
  );
};

export const Guide2 = () => {
  const ref = useRef(null);

  const steps: TourProps['steps'] = useMemo(() => {
    if (ref.current == null) {
      return [];
    }
    return [
      {
        title: '使用指南',
        description: '点击此处查看使用指南',
        target: ref.current,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, authStore.isGuest]);

  useLayoutEffect(() => {
    if (ref.current == null || preferenceStore.guide !== 'init') {
      return;
    }

    preferenceStore.setGuide('open');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <div>
      <Button
        ref={ref}
        type="link"
        onClick={() => {
          window.open(
            'https://placeholdersoft.notion.site/Q-A-8c068e4af2194110af9ce0c4a61ca3e2?pvs=4',
          );
        }}
      >
        <div className="flex justify-center items-center gap-2">
          <AntdIconWithSvgIcon className="text-[25px]" iconName="Book2" />
          <span className="border-0 border-b border-solid px-2">使用指南</span>
        </div>
      </Button>
      <Tour
        open={preferenceStore.guide === 'open'}
        onClose={() => preferenceStore.setGuide('done')}
        steps={steps}
      />
    </div>
  );
};
