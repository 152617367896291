import { LoginRegisterModal } from '../../auth/components/LoginRegister';
import { Layout } from '../../components/Layout';
import { GenInviteCodeModal } from '../../user/components/Invite';
import { PlanTable } from './components/PlanTable';

export const PlanPage = () => {
  return (
    <Layout>
      <div className="flex-1 flex flex-col justify-start items-center w-4/5 mx-auto box-border">
        <h1 className="text-[#1890FF]">Chat with PDF</h1>
        <div className="flex flex-col items-center w-full mt-10">
          <PlanTable />
          <LoginRegisterModal />
          <GenInviteCodeModal />
        </div>
      </div>
    </Layout>
  );
};
