import { TLanguageOption } from '@libs/types';
import { action, makeObservable, observable } from 'mobx';
import { create, persist } from 'mobx-persist';

export enum PropertiesFormModel {
  FLOAT = 'FLOAT',
  FIXED = 'FIXED',
}

class PreferenceStore {
  constructor() {
    makeObservable(this);
  }

  @persist @observable guide: 'init' | 'open' | 'done' = 'init';

  @persist('object') @observable language: {
    [key: string]: TLanguageOption;
  } = {};

  @action
  setLanguage = (key: string, value: TLanguageOption) => {
    this.language = {
      ...this.language,
      [key]: value,
    };
  };

  @action
  setGuide = (value: 'init' | 'open' | 'done') => {
    this.guide = value;
  };
}

export const preferenceStore = new PreferenceStore();

create({
  storage: localStorage,
  jsonify: true,
})('com.chatfile.preference.store', preferenceStore).then(() =>
  console.log('preferenceStore has been hydrated'),
);
