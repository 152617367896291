/* tslint:disable */
/* eslint-disable */
/**
 * AI Chat PDF API
 * AI Chat PDF API definitions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateChatRequest,
  CreateChatResponse,
  CreateQuestionsBySummaryRequest,
  CreateQuestionsBySummaryResponse,
  GetSummaryRequest,
  GetSummaryResponse,
  SendBugMessageToSlackRequest,
} from '../models';

export interface ChatApiControllerCloseConversationRequest {
    conversation_id: string;
}

export interface ChatApiControllerCreateChatRequest {
    CreateChatRequest: CreateChatRequest;
}

export interface ChatApiControllerCreateQuestionsBySummaryRequest {
    CreateQuestionsBySummaryRequest: CreateQuestionsBySummaryRequest;
}

export interface ChatApiControllerDeleteChatsRequest {
    conversation_id: string;
}

export interface ChatApiControllerGetSummaryRequest {
    GetSummaryRequest: GetSummaryRequest;
}

export interface ChatApiControllerRetryChatRequest {
    chat_id: string;
}

export interface ChatApiControllerSendBugReportToSlackRequest {
    SendBugMessageToSlackRequest: SendBugMessageToSlackRequest;
}

/**
 * 
 */
export class ChatApi extends runtime.BaseAPI {

    /**
     * delete conversationId 标记删除会话id
     */
    async chatApiControllerCloseConversationRaw(requestParameters: ChatApiControllerCloseConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conversation_id === null || requestParameters.conversation_id === undefined) {
            throw new runtime.RequiredError('conversation_id','Required parameter requestParameters.conversation_id was null or undefined when calling chatApiControllerCloseConversation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/conversations/{conversation_id}`.replace(`{${"conversation_id"}}`, encodeURIComponent(String(requestParameters.conversation_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete conversationId 标记删除会话id
     */
    async chatApiControllerCloseConversation(requestParameters: ChatApiControllerCloseConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chatApiControllerCloseConversationRaw(requestParameters, initOverrides);
    }

    /**
     * create chat 向AI提问获取答案
     */
    async chatApiControllerCreateChatRaw(requestParameters: ChatApiControllerCreateChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateChatResponse>> {
        if (requestParameters.CreateChatRequest === null || requestParameters.CreateChatRequest === undefined) {
            throw new runtime.RequiredError('CreateChatRequest','Required parameter requestParameters.CreateChatRequest was null or undefined when calling chatApiControllerCreateChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/chats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.CreateChatRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * create chat 向AI提问获取答案
     */
    async chatApiControllerCreateChat(requestParameters: ChatApiControllerCreateChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateChatResponse> {
        const response = await this.chatApiControllerCreateChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * createQuestionsBySummary 向AI提问获取问题
     */
    async chatApiControllerCreateQuestionsBySummaryRaw(requestParameters: ChatApiControllerCreateQuestionsBySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateQuestionsBySummaryResponse>> {
        if (requestParameters.CreateQuestionsBySummaryRequest === null || requestParameters.CreateQuestionsBySummaryRequest === undefined) {
            throw new runtime.RequiredError('CreateQuestionsBySummaryRequest','Required parameter requestParameters.CreateQuestionsBySummaryRequest was null or undefined when calling chatApiControllerCreateQuestionsBySummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/chats/questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.CreateQuestionsBySummaryRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * createQuestionsBySummary 向AI提问获取问题
     */
    async chatApiControllerCreateQuestionsBySummary(requestParameters: ChatApiControllerCreateQuestionsBySummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateQuestionsBySummaryResponse> {
        const response = await this.chatApiControllerCreateQuestionsBySummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete chats by conversationId 标记删除会话id下的所有聊天记录
     */
    async chatApiControllerDeleteChatsRaw(requestParameters: ChatApiControllerDeleteChatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.conversation_id === null || requestParameters.conversation_id === undefined) {
            throw new runtime.RequiredError('conversation_id','Required parameter requestParameters.conversation_id was null or undefined when calling chatApiControllerDeleteChats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/conversations/{conversation_id}/chats`.replace(`{${"conversation_id"}}`, encodeURIComponent(String(requestParameters.conversation_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete chats by conversationId 标记删除会话id下的所有聊天记录
     */
    async chatApiControllerDeleteChats(requestParameters: ChatApiControllerDeleteChatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chatApiControllerDeleteChatsRaw(requestParameters, initOverrides);
    }

    /**
     * get file summary 获取总结
     */
    async chatApiControllerGetSummaryRaw(requestParameters: ChatApiControllerGetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSummaryResponse>> {
        if (requestParameters.GetSummaryRequest === null || requestParameters.GetSummaryRequest === undefined) {
            throw new runtime.RequiredError('GetSummaryRequest','Required parameter requestParameters.GetSummaryRequest was null or undefined when calling chatApiControllerGetSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/chats/summary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.GetSummaryRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * get file summary 获取总结
     */
    async chatApiControllerGetSummary(requestParameters: ChatApiControllerGetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSummaryResponse> {
        const response = await this.chatApiControllerGetSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * retry 重试问题
     */
    async chatApiControllerRetryChatRaw(requestParameters: ChatApiControllerRetryChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.chat_id === null || requestParameters.chat_id === undefined) {
            throw new runtime.RequiredError('chat_id','Required parameter requestParameters.chat_id was null or undefined when calling chatApiControllerRetryChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/chats/{chat_id}:retry`.replace(`{${"chat_id"}}`, encodeURIComponent(String(requestParameters.chat_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * retry 重试问题
     */
    async chatApiControllerRetryChat(requestParameters: ChatApiControllerRetryChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chatApiControllerRetryChatRaw(requestParameters, initOverrides);
    }

    /**
     * send slack message 一键报错
     */
    async chatApiControllerSendBugReportToSlackRaw(requestParameters: ChatApiControllerSendBugReportToSlackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.SendBugMessageToSlackRequest === null || requestParameters.SendBugMessageToSlackRequest === undefined) {
            throw new runtime.RequiredError('SendBugMessageToSlackRequest','Required parameter requestParameters.SendBugMessageToSlackRequest was null or undefined when calling chatApiControllerSendBugReportToSlack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/chats/bug-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.SendBugMessageToSlackRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * send slack message 一键报错
     */
    async chatApiControllerSendBugReportToSlack(requestParameters: ChatApiControllerSendBugReportToSlackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.chatApiControllerSendBugReportToSlackRaw(requestParameters, initOverrides);
    }

}
