import { services } from '@clients/rest-api-client';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { useCallback, useState } from 'react';
import { assertIsError } from '../../../../../utils/assert';

type ReportFrom = {
  title: string;
  description: string;
};

export const ReportError = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<ReportFrom>();
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values: ReportFrom) => {
      setLoading(true);
      try {
        await services.chatService.chatApiControllerSendBugReportToSlack({
          SendBugMessageToSlackRequest: {
            url: window.location.href,
            title: values.title,
            description: values.description,
          },
        });
        message.success('提交成功');
        setIsModalOpen(false);
        form.resetFields();
      } catch (e) {
        assertIsError(e);
        message.warning(e.message);
      } finally {
        setLoading(false);
      }
    },
    [form],
  );

  return (
    <>
      <Button size="small" danger onClick={() => setIsModalOpen(true)}>
        一键报错
      </Button>
      <Modal
        open={isModalOpen}
        title="一键报错"
        width={500}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        styles={{
          body: { padding: '0px 20px 0' },
        }}
      >
        <Form
          form={form}
          className="mt-4"
          name="basic"
          layout="vertical"
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="请选择您遇到的问题"
            rules={[{ required: true, message: '请选择您遇到的问题' }]}
          >
            <Select
              showSearch
              options={[
                {
                  label: '我问了文中提到的内容，但它无法回答我的问题',
                  value: '我问了文中提到的内容，但它无法回答我的问题',
                },
                {
                  label: '其他',
                  value: '其他',
                },
              ]}
              placeholder="请选择您遇到的问题"
              filterOption={(inputValue, option) =>
                option?.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="描述"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('title') === '其他' && !value) {
                    return Promise.reject(
                      '请详细描述你的问题，包括你的操作步骤、预期结果和实际结果。',
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.TextArea
              placeholder="请详细描述你的问题，包括你的操作步骤、预期结果和实际结果。"
              allowClear
              autoSize={{
                minRows: 7,
                maxRows: 7,
              }}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={loading}
              className="w-full"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
