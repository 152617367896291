/* tslint:disable */
/* eslint-disable */
/**
 * AI Chat PDF API
 * AI Chat PDF API definitions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrderResponse,
} from '../models';

/**
 * 
 */
export class PayApi extends runtime.BaseAPI {

    /**
     * alipay的异步回调
     */
    async payApiControllerAlipayNotifyAsyncRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/pay/notify/alipay-async`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * alipay的异步回调
     */
    async payApiControllerAlipayNotifyAsync(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payApiControllerAlipayNotifyAsyncRaw(initOverrides);
    }

    /**
     * alipay的return url
     */
    async payApiControllerAlipayReturnUrlRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/pay/notify/alipay-return`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * alipay的return url
     */
    async payApiControllerAlipayReturnUrl(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payApiControllerAlipayReturnUrlRaw(initOverrides);
    }

    /**
     * 创建订单返回支付URL
     */
    async payApiControllerCreateOrderRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pay/order:create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * 创建订单返回支付URL
     */
    async payApiControllerCreateOrder(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrderResponse> {
        const response = await this.payApiControllerCreateOrderRaw(initOverrides);
        return await response.value();
    }

}
