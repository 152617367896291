import { createContext, createElement, useContext, useMemo } from 'react';
import { FCC } from '../react';
import { useCreation } from '../useCreation';
import { ObservableResource } from './ObservableResource';

export interface ObservableResourceHolder {
  get<T>(id: unknown): undefined | ObservableResource<T>;

  getOrCreate<T>(
    id: unknown,
    resourceFactory: () => ObservableResource<T>,
  ): ObservableResource<T>;

  delete(id: unknown): void;
}

class ObservableResourceHolderBasicImpl implements ObservableResourceHolder {
  private preloaded: Map<unknown, ObservableResource<any>> = new Map();

  get<T>(id: unknown): undefined | ObservableResource<T> {
    return this.preloaded.get(id);
  }

  getOrCreate<T>(
    id: unknown,
    resourceFactory: () => ObservableResource<T>,
  ): ObservableResource<T> {
    if (this.preloaded.has(id)) {
      return this.preloaded.get(id) as ObservableResource<T>;
    } else {
      const resource = resourceFactory();
      this.preloaded.set(id, resource);
      return resource;
    }
  }

  delete(id: unknown) {
    if (this.preloaded.has(id)) {
      this.preloaded.delete(id);
    }
  }
}

class ObservableResourceHolderNamespacedImpl
  implements ObservableResourceHolder
{
  constructor(
    private namespace: string,
    private currentLevelHolder: ObservableResourceHolder,
    private superLevelHolder: ObservableResourceHolder,
  ) {}

  private doOperate<T>(
    id: unknown,
    operator: (holder: ObservableResourceHolder) => T,
  ): T {
    if (typeof id === 'string' && id.startsWith(this.namespace)) {
      return operator(this.currentLevelHolder);
    } else {
      return operator(this.superLevelHolder);
    }
  }

  get<T>(id: unknown): undefined | ObservableResource<T> {
    return this.doOperate(id, holder => holder.get(id));
  }

  getOrCreate<T>(
    id: unknown,
    resourceFactory: () => ObservableResource<T>,
  ): ObservableResource<T> {
    return this.doOperate(id, holder =>
      holder.getOrCreate(id, resourceFactory),
    );
  }

  delete(id: unknown) {
    this.doOperate(id, holder => holder.delete(id));
  }
}

const defaultHolder = new ObservableResourceHolderBasicImpl();

const ObservableSuspenseContext =
  createContext<ObservableResourceHolder>(defaultHolder);

export const ObservableResourceHolderProvider: FCC<{
  namespace: string;
}> = props => {
  const parentHolder = useObservableResourceHolder();

  const selfHolder = useCreation(
    () => new ObservableResourceHolderBasicImpl(),
    [],
  );

  const namespacedHolder: ObservableResourceHolder = useMemo(
    () =>
      new ObservableResourceHolderNamespacedImpl(
        props.namespace,
        selfHolder,
        parentHolder,
      ),
    [parentHolder, props.namespace, selfHolder],
  );

  return createElement(
    ObservableSuspenseContext.Provider,
    { value: namespacedHolder },
    props.children,
  );
};

export const useObservableResourceHolder = (): ObservableResourceHolder =>
  useContext(ObservableSuspenseContext);
