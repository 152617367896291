import { services } from '@clients/rest-api-client';
import { Modal, Tooltip } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AntdIconWithSvgIcon } from '../../../../components/icon';
import { ChatStoreContext, useResetConversation } from '../../chat.store';

export const HeaderTool = () => {
  const navigate = useNavigate();
  const store = useContext(ChatStoreContext);
  const [resetConversation] = useResetConversation();
  return (
    <div className="flex justify-between items-center gap-2 p-1 border-0 border-b border-gray-300 border-solid">
      <div>Chat</div>
      <div className="flex justify-end gap-2">
        <Tooltip title="重置对话">
          <AntdIconWithSvgIcon
            className="text-gray-400 cursor-pointer hover:text-red-600 text-[18px]"
            onClick={async () => {
              resetConversation();
            }}
            iconName="Redo"
          />
        </Tooltip>
        <Tooltip title="删除会话">
          <AntdIconWithSvgIcon
            className="text-gray-400 cursor-pointer hover:text-red-600 text-[18px]"
            onClick={async () => {
              Modal.confirm({
                title: '删除会话?',
                content: '删除会话后，所有对话记录将被清空，且无法恢复。',
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                maskClosable: true,
                onOk: async () => {
                  await services.chatService.chatApiControllerCloseConversation(
                    {
                      conversation_id: store.conversation_id,
                    },
                  );
                  navigate('/');
                },
              });
            }}
            iconName="Remove"
          />
        </Tooltip>
      </div>
    </div>
  );
};
