import AntdIcon from '@ant-design/icons';
import { SVGProps } from 'react';
import { z } from 'zod';
import * as AllSvgs from './Svgs';

export const ZSvgNameKeys = z.enum(
  Object.keys(AllSvgs) as [keyof typeof AllSvgs],
);

export type SvgNameKeys = z.infer<typeof ZSvgNameKeys>;

export const BuiltInIcon = ({
  iconName,
  ...props
}: { iconName: SvgNameKeys } & SVGProps<SVGSVGElement>) => {
  const Icon = AllSvgs[iconName];
  return <Icon {...props} />;
};

type AntdIconProps = React.ComponentProps<typeof AntdIcon>;

export const AntdIconWithSvgIcon = ({
  iconName,
  ...props
}: { iconName: SvgNameKeys } & AntdIconProps) => {
  const Icon = AllSvgs[iconName];

  return (
    <AntdIcon
      component={Icon as React.ForwardRefExoticComponent<any>}
      {...props}
    />
  );
};
