import { services } from '@clients/rest-api-client';
import { Button, Form, Input, Modal, Spin, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { LoginRegister } from '../../auth/components/LoginRegister';
import authStore from '../../auth/auth.store';
import { AntdIconWithSvgIcon } from '../../components/icon';
import { assertIsError } from '../../utils/assert';
import { useObservable } from '../../utils/useObservable';
import { copyText } from '../../utils/utils';

type InviteForm = {
  inviteCode: string;
};

export const Invite = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<InviteForm>();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    async (values: InviteForm) => {
      try {
        navigate('/login', {
          state: { invite_code: values.inviteCode, method: 'register' },
        });
      } catch (e) {
        assertIsError(e);
        message.warning(e.message);
      }
    },
    [navigate],
  );

  return (
    <div
      className="w-96 m-auto px-10 pt-10 pb-3 rounded"
      style={{
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
      }}
    >
      <Form
        form={form}
        name="basic"
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={
          {
            // inviteCode: searchParams.get('inviteCode') ?? '',
          }
        }
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item>
          <div className="text-[24px] font-[500] text-[#2593fc] text-center">
            <span role="img" aria-label="wave">
              欢迎体验 Chat With PDF 👋🏻
            </span>
          </div>
        </Form.Item>
        <Form.Item
          name="inviteCode"
          rules={[{ required: true, message: '请输入您的邀请码' }]}
        >
          <Input type="text" placeholder="请输入您的邀请码" />
        </Form.Item>
        <Form.Item>
          <div className="text-[#2593fc] text-[16px] text-center">
            <span role="img" aria-label="muscle">
              您的使用是对我们最大的支持 💪🏻
            </span>
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" className="w-full" htmlType="submit">
            填好了
          </Button>
        </Form.Item>
      </Form>
      <div className="text-right" onClick={() => navigate('/')}>
        <Button type="link">跳过这一步, 直接开始{'>>'}</Button>
      </div>
    </div>
  );
};

export const useGenInviteCode = () => {
  const [loading, setLoading] = useState(false);

  const genInviteCode = useCallback(async (): Promise<string | undefined> => {
    try {
      setLoading(true);
      const result =
        await services.authService.authApiControllerCreateOrGetInviteCode();

      return result.code;
    } catch (e) {
      assertIsError(e);
      message.warning(e.message);
      return;
    } finally {
      setLoading(false);
    }
  }, []);

  return [genInviteCode, loading] as const;
};

type GenInviteCodeProps = {
  callback?: () => void;
};

export const GenInviteCode = ({ callback }: GenInviteCodeProps) => {
  const [inviteCode, setInviteCode] = useState<string>();
  const [genInviteCode] = useGenInviteCode();

  const inviteText = useMemo(() => {
    return `${
      authStore.user.nickname ?? authStore.user.email
    } 邀请你来用 Chat with PDF，用 ChatGPT 读文档可以自动生成摘要，有任何疑问直接向 AI 提问，即可获得解答和原文信息出处

点击链接：${window.location.origin}/invite
邀请码：${inviteCode}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode, authStore.user.email, authStore.user.nickname]);

  const onFinish = useCallback(async () => {
    try {
      if (inviteCode == null) {
        message.warning('生成失败请重试');
      }

      await copyText(inviteText);

      message.info('已复制到剪切板');
      callback?.();
    } catch (e) {
      assertIsError(e);
      message.warning(e.message);
    }
  }, [callback, inviteCode, inviteText]);

  useEffect(() => {
    (async () => {
      setInviteCode(await genInviteCode());
    })();
  }, [genInviteCode]);

  if (inviteCode == null) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <div className="text-[24px] font-[500] text-[#2593fc] text-center">
        <AntdIconWithSvgIcon className="text-[26px]" iconName="Gift" />
        <span className="mx-2">邀请好友一起体验</span>
        <span role="img" aria-label="party popper">
          🎉
        </span>
      </div>
      <div className="font-bold mt-4">邀请信息：</div>
      <div className="whitespace-pre-wrap	mt-2 mb-4 ">{inviteText}</div>
      <div className="text-red-600">
        * 请注意 ，目前只支持电脑操作，记得告知你朋友哟 ^_^
      </div>
      <Button
        type="primary"
        className="w-full mt-6 mb-2"
        onClick={() => onFinish()}
      >
        生成并复制邀请链接
      </Button>
    </div>
  );
};

const genInviteCodeModal$ = new BehaviorSubject(false);

export const toggleGenInviteCodeModal = (isOpen: boolean) => {
  genInviteCodeModal$.next(isOpen);
};

export const GenInviteCodeModal = ({ callback }: { callback?: () => void }) => {
  const isModalOpen = useObservable(
    () => genInviteCodeModal$,
    'genInviteCodeModal',
  );

  const handleCancel = () => {
    toggleGenInviteCodeModal(false);
  };

  return (
    <Modal
      open={!!isModalOpen}
      width={500}
      onCancel={handleCancel}
      footer={null}
      styles={{
        body: { padding: '0px 20px 0' },
      }}
    >
      {authStore.isRealUser ? (
        <GenInviteCode
          callback={() => {
            handleCancel();
            callback?.();
          }}
        />
      ) : (
        <LoginRegister prePage="invite" />
      )}
    </Modal>
  );
};
