import { APIErrorWrapper } from '@clients/rest-api-client';

export function assertIsError(e: unknown): asserts e is Error {
  if (!(e instanceof Error)) {
    throw new Error('e 必须是 Error 类型');
  }
}

export function assertIsAPIError(e: unknown): asserts e is APIErrorWrapper {
  if (!(e instanceof APIErrorWrapper)) {
    throw new Error('e 必须是 ApiError 类型');
  }
}
