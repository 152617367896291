import { InboxOutlined, LinkOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Space,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authStore from '../../../auth/auth.store';
import { SwitchModelSelect } from '../../../components/SwitchModel';
import { uploadFile } from '../../../utils/uploadfile';

const { Dragger } = Upload;

type RcCustomRequestOptions = Parameters<
  NonNullable<UploadProps['customRequest']>
>[0];

export const UploadPDF = () => {
  const navigate = useNavigate();
  const isLoggedIn = authStore.isLoggedIn;
  const [link, setLink] = useState<string>();
  const [fileList, setFileList] = useState<Array<UploadFile>>();

  const customRequest = useCallback(
    async (info: RcCustomRequestOptions) => {
      if (!isLoggedIn) {
        await authStore.guestLogin();
      }
      const file = info.file as {
        uid: string;
        name: string;
      };

      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: 'uploading',
        },
      ]);

      const result = await uploadFile(file);

      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: result.status,
        },
      ]);

      if (result.status === 'error') {
        message.warning(result.message);
        // message.open({
        //   content: (
        //     <div className="flex gap-2 justify-start items-start">
        //       <CloseCircleFilled className="text-red-600" />
        //       {result.message}
        //     </div>
        //   ),
        //   duration: 0,
        // });
        return;
      }
      navigate(`/chat/${result.conversation_id}`);
    },
    [isLoggedIn, navigate],
  );

  return (
    <div className="w-[300px]">
      <div>
        <Dragger
          accept=".pdf"
          onRemove={_ => {
            setFileList([]);
          }}
          customRequest={customRequest}
          fileList={fileList}
          className="w-full"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text px-4">
            将文件拖拽至此处或<span className="text-blue-500">点击上传</span>
          </p>
          <div className="ant-upload-hint px-4 text-left text-[#00000052]">
            <div>* 目前仅支持上传 PDF 文件</div>
            <div>* 严禁上传保密数据或其他被禁止的文件</div>
          </div>
        </Dragger>
      </div>
      <SwitchModelSelect className="w-full mt-4" />
      <Space.Compact className="w-full mt-4">
        <Input
          placeholder="输入在线PDF文档链接"
          allowClear
          style={{
            width: '100%',
          }}
          onChange={e => {
            setLink(e.target.value);
          }}
          prefix={<LinkOutlined />}
        />
        <Button
          type="primary"
          onClick={async () => {
            const reg = new RegExp('^(http|https)://.*.pdf$');
            if (link == null || !reg.test(link)) {
              message.warning('请输入正确的pdf链接');
              return;
            }
            if (isLoggedIn) {
              await authStore.guestLogin();
            }
            try {
              const res = await fetch(link, {
                method: 'GET',
              });

              const blob = await res.blob();
              const file = new File([blob], 'file.pdf', {});
              const result = await uploadFile(file);

              if (result.status === 'error') {
                message.warning(result.message);
                return;
              }

              navigate(`/chat/${result.conversation_id}`);
            } catch (e) {
              message.warning('请输入正确的pdf链接: ' + e);
            }
          }}
        >
          确认
        </Button>
      </Space.Compact>
    </div>
  );
};
