import { Avatar, Tooltip } from 'antd';
import { AntdIconWithSvgIcon } from '../icon';

export const Feedback = ({ showTitle }: { showTitle?: boolean }) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        window.open('https://jinshuju.net/f/nwBQqU?x_field_1=eloise');
      }}
    >
      <Tooltip title="反馈建议" className="flex flex-col items-center">
        <Avatar
          className="bg-transparent"
          size="large"
          icon={<AntdIconWithSvgIcon iconName="Feedback" />}
        />
        {showTitle && (
          <span className="text-[12px] text-blue-400 select-none">
            反馈建议
          </span>
        )}
      </Tooltip>
    </div>
  );
};
