import React, {
  ComponentClass,
  ComponentType,
  DependencyList,
  EffectCallback,
  isValidElement,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { usePersistFn } from './usePersistFn';

export function useAutoUpdatingRef<T>(
  value: T,
  isEqual: (a: T, b: T) => boolean = Object.is,
) {
  const ref = useRef(value);
  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }
  return ref;
}

export function useLazyRef<T>(generator: () => T): MutableRefObject<T> {
  // previously, we always use useMemo
  // but useMemo's will change when concurrent mode released
  // and it will no longer guarantee stable ref.
  // as it might clear out cache value

  const ref = useRef<undefined | T>(undefined);

  if (ref.current == null) {
    ref.current = generator();
  }

  return ref as any;
}

export function useStableRef<T>(generator: () => T): T {
  // previously, we always use useMemo
  // but useMemo's will change when concurrent mode released
  // and it will no longer guarantee stable ref.
  // as it might clear out cache value

  return useLazyRef(generator).current;
}

export function useRerender(): [rerenderId: number, rerender: () => void] {
  return useReducer((s: number) => s + 1, 0);
}

export function useThrowErrorInReactLifecycle(): (error: any) => void {
  const [, setValue] = useState();

  return useCallback((e: any) => {
    setValue(() => {
      throw e;
    });
  }, []);
}

export function useIsMounted(): () => boolean {
  const ref = useRef(false);

  useLayoutEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return usePersistFn(() => ref.current);
}

export function useMountEffect(effect: EffectCallback): void {
  // eslint-disable-next-line
  useEffect(effect, []);
}

export function useUpdateEffect(
  effect: EffectCallback,
  deps: DependencyList,
): void {
  const isFirstTimeRef = useRef(true);

  useEffect(
    () => {
      if (isFirstTimeRef.current) {
        isFirstTimeRef.current = true;
        return;
      }

      return effect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
}

export function isComponentInstance<P>(
  componentType: ComponentType<P>,
  element: ReactNode,
): element is ReactElement<P> {
  if (!isValidElement(element)) return false;

  return element.type === componentType;
}

export function toClassComponent<P>(
  Component: ComponentType<P>,
): ComponentClass<P> {
  return class extends React.PureComponent<P> {
    static displayName = `toClassComponent(${
      Component.displayName || Component.name
    })`;

    render() {
      return <Component {...this.props} />;
    }
  };
}
