import { useUpdateUserPreferencesMutation } from '@clients/graphql-client';
import { TLanguageOption } from '@libs/types';
import { Button, Form, Select, message } from 'antd';
import { useCallback, useEffect } from 'react';
import authStore from '../../auth/auth.store';
import { languageItems } from '../../types/chat';

type PreferencesForm = {
  language: TLanguageOption;
};

export const Preferences = () => {
  const user = authStore.user;
  const [form] = Form.useForm<PreferencesForm>();
  const [{ fetching }, executeMutation] = useUpdateUserPreferencesMutation();

  const formItemLayout = {
    labelCol: {
      span: 6,
      style: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
    },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    async (values: PreferencesForm) => {
      const result = await executeMutation({
        id: user.id,
        language: values.language,
      });

      if (result.error) {
        message.warning('修改失败，请稍后再试！');
        return;
      }
      message.info('修改成功');
      await authStore.fetchUserProfile();
    },
    [executeMutation, user.id],
  );

  if (user == null) {
    return;
  }

  return (
    <div className="py-8 px-2">
      <Form
        {...formItemLayout}
        form={form}
        name="basic"
        style={{ maxWidth: 600 }}
        initialValues={{
          language: user.default_language,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="默认输出语言"
          name="language"
          rules={[
            {
              required: true,
              message: '请选择输出语音',
            },
          ]}
        >
          <Select>
            {languageItems.map((r, i) => {
              return (
                <Select.Option key={i} value={r.key}>
                  {r.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" loading={fetching}>
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
