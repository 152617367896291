import { Button, Form, Input, message } from 'antd';
import { useCallback, useEffect } from 'react';
import { assertIsError } from '../../utils/assert';
import authStore from '../auth.store';

type LoginFrom = {
  email: string;
  password: string;
};

export const Login = ({
  prePage,
  callback,
}: {
  callback?: () => void;
  prePage?: string;
}) => {
  const [form] = Form.useForm<LoginFrom>();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    async (values: LoginFrom) => {
      try {
        await authStore.login({
          email: values.email,
          password: values.password,
        });
        form.resetFields();
        callback?.();
      } catch (e) {
        assertIsError(e);
        message.warning(e.message);
      }
    },
    [callback, form],
  );

  return (
    <div>
      <Form
        form={form}
        name="basic"
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{
          prefix: '86',
          phone: '',
          password: '',
          confirmPassword: '',
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {/* <Form.Item
          name="phone"
          rules={[
            { required: true, message: '请输入手机号' },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确的手机号',
            },
          ]}
        >
          <Input
            addonBefore={
              <Form.Item name="prefix" noStyle>
                <Select style={{ width: 70 }}>
                  <Select.Option value="86">+86</Select.Option>
                </Select>
              </Form.Item>
            }
            placeholder="请输入11位手机号码"
          />
        </Form.Item> */}
        <Form.Item
          name="email"
          rules={[{ required: true, message: '请输入Email' }]}
        >
          <Input type="email" placeholder="请输入Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '请输入6-16位密码',
            },
            {
              pattern: /^.{6,16}$/,
              message: '请输入6-16位密码',
            },
          ]}
        >
          <Input.Password placeholder="请输入6-16位密码" />
        </Form.Item>
        {prePage === 'invite' && (
          <Form.Item className="text-red-500">
            请您先登录，再邀请好友来体验哦 ^ ^
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" className="w-full" htmlType="submit">
            立即登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
