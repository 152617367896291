import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  json: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: string; output: string; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "captchas" */
export type Captchas = {
  __typename?: 'captchas';
  code: Scalars['String']['output'];
  /** email, phone */
  contact_type: Scalars['String']['output'];
  contact_value: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  expired_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_verified?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** register, login, reset_password */
  verification_type: Scalars['String']['output'];
};

/** aggregated selection of "captchas" */
export type CaptchasAggregate = {
  __typename?: 'captchas_aggregate';
  aggregate?: Maybe<CaptchasAggregateFields>;
  nodes: Array<Captchas>;
};

/** aggregate fields of "captchas" */
export type CaptchasAggregateFields = {
  __typename?: 'captchas_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CaptchasMaxFields>;
  min?: Maybe<CaptchasMinFields>;
};


/** aggregate fields of "captchas" */
export type CaptchasAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CaptchasSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "captchas". All fields are combined with a logical 'AND'. */
export type CaptchasBoolExp = {
  _and?: InputMaybe<Array<CaptchasBoolExp>>;
  _not?: InputMaybe<CaptchasBoolExp>;
  _or?: InputMaybe<Array<CaptchasBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  contact_type?: InputMaybe<StringComparisonExp>;
  contact_value?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  expired_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_verified?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  verification_type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "captchas" */
export enum CaptchasConstraint {
  /** unique or primary key constraint on columns "id" */
  CaptchasPkey = 'captchas_pkey'
}

/** input type for inserting data into table "captchas" */
export type CaptchasInsertInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** email, phone */
  contact_type?: InputMaybe<Scalars['String']['input']>;
  contact_value?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_verified?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** register, login, reset_password */
  verification_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CaptchasMaxFields = {
  __typename?: 'captchas_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  /** email, phone */
  contact_type?: Maybe<Scalars['String']['output']>;
  contact_value?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expired_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** register, login, reset_password */
  verification_type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CaptchasMinFields = {
  __typename?: 'captchas_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  /** email, phone */
  contact_type?: Maybe<Scalars['String']['output']>;
  contact_value?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expired_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** register, login, reset_password */
  verification_type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "captchas" */
export type CaptchasMutationResponse = {
  __typename?: 'captchas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Captchas>;
};

/** on_conflict condition type for table "captchas" */
export type CaptchasOnConflict = {
  constraint: CaptchasConstraint;
  update_columns?: Array<CaptchasUpdateColumn>;
  where?: InputMaybe<CaptchasBoolExp>;
};

/** Ordering options when selecting data from "captchas". */
export type CaptchasOrderBy = {
  code?: InputMaybe<OrderBy>;
  contact_type?: InputMaybe<OrderBy>;
  contact_value?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  expired_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_verified?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  verification_type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: captchas */
export type CaptchasPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "captchas" */
export enum CaptchasSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  ContactValue = 'contact_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationType = 'verification_type'
}

/** input type for updating data in table "captchas" */
export type CaptchasSetInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** email, phone */
  contact_type?: InputMaybe<Scalars['String']['input']>;
  contact_value?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_verified?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** register, login, reset_password */
  verification_type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "captchas" */
export type CaptchasStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CaptchasStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CaptchasStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** email, phone */
  contact_type?: InputMaybe<Scalars['String']['input']>;
  contact_value?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_verified?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** register, login, reset_password */
  verification_type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "captchas" */
export enum CaptchasUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  ContactType = 'contact_type',
  /** column name */
  ContactValue = 'contact_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationType = 'verification_type'
}

export type CaptchasUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CaptchasSetInput>;
  /** filter the rows which have to be updated */
  where: CaptchasBoolExp;
};

/** columns and relationships of "chat_refs" */
export type ChatRefs = {
  __typename?: 'chat_refs';
  chat_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  distance: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  meta?: Maybe<Scalars['json']['output']>;
  ref_id: Scalars['uuid']['output'];
  ref_page: Scalars['Int']['output'];
};


/** columns and relationships of "chat_refs" */
export type ChatRefsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "chat_refs" */
export type ChatRefsAggregate = {
  __typename?: 'chat_refs_aggregate';
  aggregate?: Maybe<ChatRefsAggregateFields>;
  nodes: Array<ChatRefs>;
};

export type ChatRefsAggregateBoolExp = {
  count?: InputMaybe<ChatRefsAggregateBoolExpCount>;
};

export type ChatRefsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatRefsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ChatRefsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_refs" */
export type ChatRefsAggregateFields = {
  __typename?: 'chat_refs_aggregate_fields';
  avg?: Maybe<ChatRefsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ChatRefsMaxFields>;
  min?: Maybe<ChatRefsMinFields>;
  stddev?: Maybe<ChatRefsStddevFields>;
  stddev_pop?: Maybe<ChatRefsStddevPopFields>;
  stddev_samp?: Maybe<ChatRefsStddevSampFields>;
  sum?: Maybe<ChatRefsSumFields>;
  var_pop?: Maybe<ChatRefsVarPopFields>;
  var_samp?: Maybe<ChatRefsVarSampFields>;
  variance?: Maybe<ChatRefsVarianceFields>;
};


/** aggregate fields of "chat_refs" */
export type ChatRefsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatRefsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chat_refs" */
export type ChatRefsAggregateOrderBy = {
  avg?: InputMaybe<ChatRefsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatRefsMaxOrderBy>;
  min?: InputMaybe<ChatRefsMinOrderBy>;
  stddev?: InputMaybe<ChatRefsStddevOrderBy>;
  stddev_pop?: InputMaybe<ChatRefsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChatRefsStddevSampOrderBy>;
  sum?: InputMaybe<ChatRefsSumOrderBy>;
  var_pop?: InputMaybe<ChatRefsVarPopOrderBy>;
  var_samp?: InputMaybe<ChatRefsVarSampOrderBy>;
  variance?: InputMaybe<ChatRefsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "chat_refs" */
export type ChatRefsArrRelInsertInput = {
  data: Array<ChatRefsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatRefsOnConflict>;
};

/** aggregate avg on columns */
export type ChatRefsAvgFields = {
  __typename?: 'chat_refs_avg_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chat_refs" */
export type ChatRefsAvgOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "chat_refs". All fields are combined with a logical 'AND'. */
export type ChatRefsBoolExp = {
  _and?: InputMaybe<Array<ChatRefsBoolExp>>;
  _not?: InputMaybe<ChatRefsBoolExp>;
  _or?: InputMaybe<Array<ChatRefsBoolExp>>;
  chat_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  distance?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  meta?: InputMaybe<JsonComparisonExp>;
  ref_id?: InputMaybe<UuidComparisonExp>;
  ref_page?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "chat_refs" */
export enum ChatRefsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChatRefsPkey = 'chat_refs_pkey'
}

/** input type for incrementing numeric columns in table "chat_refs" */
export type ChatRefsIncInput = {
  distance?: InputMaybe<Scalars['numeric']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "chat_refs" */
export type ChatRefsInsertInput = {
  chat_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  ref_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ChatRefsMaxFields = {
  __typename?: 'chat_refs_max_fields';
  chat_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  distance?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ref_id?: Maybe<Scalars['uuid']['output']>;
  ref_page?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "chat_refs" */
export type ChatRefsMaxOrderBy = {
  chat_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ref_id?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatRefsMinFields = {
  __typename?: 'chat_refs_min_fields';
  chat_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  distance?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ref_id?: Maybe<Scalars['uuid']['output']>;
  ref_page?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "chat_refs" */
export type ChatRefsMinOrderBy = {
  chat_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ref_id?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_refs" */
export type ChatRefsMutationResponse = {
  __typename?: 'chat_refs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatRefs>;
};

/** on_conflict condition type for table "chat_refs" */
export type ChatRefsOnConflict = {
  constraint: ChatRefsConstraint;
  update_columns?: Array<ChatRefsUpdateColumn>;
  where?: InputMaybe<ChatRefsBoolExp>;
};

/** Ordering options when selecting data from "chat_refs". */
export type ChatRefsOrderBy = {
  chat_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrderBy>;
  ref_id?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_refs */
export type ChatRefsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "chat_refs" */
export enum ChatRefsSelectColumn {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Distance = 'distance',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  RefId = 'ref_id',
  /** column name */
  RefPage = 'ref_page'
}

/** input type for updating data in table "chat_refs" */
export type ChatRefsSetInput = {
  chat_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  ref_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ChatRefsStddevFields = {
  __typename?: 'chat_refs_stddev_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chat_refs" */
export type ChatRefsStddevOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChatRefsStddevPopFields = {
  __typename?: 'chat_refs_stddev_pop_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chat_refs" */
export type ChatRefsStddevPopOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChatRefsStddevSampFields = {
  __typename?: 'chat_refs_stddev_samp_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chat_refs" */
export type ChatRefsStddevSampOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "chat_refs" */
export type ChatRefsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatRefsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatRefsStreamCursorValueInput = {
  chat_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  ref_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ChatRefsSumFields = {
  __typename?: 'chat_refs_sum_fields';
  distance?: Maybe<Scalars['numeric']['output']>;
  ref_page?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "chat_refs" */
export type ChatRefsSumOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** update columns of table "chat_refs" */
export enum ChatRefsUpdateColumn {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Distance = 'distance',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  RefId = 'ref_id',
  /** column name */
  RefPage = 'ref_page'
}

export type ChatRefsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChatRefsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatRefsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatRefsBoolExp;
};

/** aggregate var_pop on columns */
export type ChatRefsVarPopFields = {
  __typename?: 'chat_refs_var_pop_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chat_refs" */
export type ChatRefsVarPopOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChatRefsVarSampFields = {
  __typename?: 'chat_refs_var_samp_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chat_refs" */
export type ChatRefsVarSampOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChatRefsVarianceFields = {
  __typename?: 'chat_refs_variance_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chat_refs" */
export type ChatRefsVarianceOrderBy = {
  distance?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** columns and relationships of "chat_type" */
export type ChatType = {
  __typename?: 'chat_type';
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};


/** columns and relationships of "chat_type" */
export type ChatTypeChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


/** columns and relationships of "chat_type" */
export type ChatTypeChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};

/** aggregated selection of "chat_type" */
export type ChatTypeAggregate = {
  __typename?: 'chat_type_aggregate';
  aggregate?: Maybe<ChatTypeAggregateFields>;
  nodes: Array<ChatType>;
};

/** aggregate fields of "chat_type" */
export type ChatTypeAggregateFields = {
  __typename?: 'chat_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ChatTypeMaxFields>;
  min?: Maybe<ChatTypeMinFields>;
};


/** aggregate fields of "chat_type" */
export type ChatTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "chat_type". All fields are combined with a logical 'AND'. */
export type ChatTypeBoolExp = {
  _and?: InputMaybe<Array<ChatTypeBoolExp>>;
  _not?: InputMaybe<ChatTypeBoolExp>;
  _or?: InputMaybe<Array<ChatTypeBoolExp>>;
  chats?: InputMaybe<ChatsBoolExp>;
  chats_aggregate?: InputMaybe<ChatsAggregateBoolExp>;
  comment?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_type" */
export enum ChatTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  ChatTypePkey = 'chat_type_pkey'
}

export enum ChatTypeEnum {
  /** normal chat */
  Normal = 'normal',
  /** gen question chat */
  Question = 'question',
  /** summary chat */
  Summary = 'summary'
}

/** Boolean expression to compare columns of type "chat_type_enum". All fields are combined with logical 'AND'. */
export type ChatTypeEnumComparisonExp = {
  _eq?: InputMaybe<ChatTypeEnum>;
  _in?: InputMaybe<Array<ChatTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ChatTypeEnum>;
  _nin?: InputMaybe<Array<ChatTypeEnum>>;
};

/** input type for inserting data into table "chat_type" */
export type ChatTypeInsertInput = {
  chats?: InputMaybe<ChatsArrRelInsertInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ChatTypeMaxFields = {
  __typename?: 'chat_type_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ChatTypeMinFields = {
  __typename?: 'chat_type_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chat_type" */
export type ChatTypeMutationResponse = {
  __typename?: 'chat_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatType>;
};

/** input type for inserting object relation for remote table "chat_type" */
export type ChatTypeObjRelInsertInput = {
  data: ChatTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatTypeOnConflict>;
};

/** on_conflict condition type for table "chat_type" */
export type ChatTypeOnConflict = {
  constraint: ChatTypeConstraint;
  update_columns?: Array<ChatTypeUpdateColumn>;
  where?: InputMaybe<ChatTypeBoolExp>;
};

/** Ordering options when selecting data from "chat_type". */
export type ChatTypeOrderBy = {
  chats_aggregate?: InputMaybe<ChatsAggregateOrderBy>;
  comment?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_type */
export type ChatTypePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "chat_type" */
export enum ChatTypeSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chat_type" */
export type ChatTypeSetInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "chat_type" */
export type ChatTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatTypeStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "chat_type" */
export enum ChatTypeUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type ChatTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ChatTypeBoolExp;
};

/** columns and relationships of "chats" */
export type Chats = {
  __typename?: 'chats';
  answer?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  chat_type: ChatType;
  /** An object relationship */
  conversation?: Maybe<Conversations>;
  conversation_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  language: Scalars['String']['output'];
  original_question: Scalars['String']['output'];
  prompt?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  refs: Array<ChatRefs>;
  /** An aggregate relationship */
  refs_aggregate: ChatRefsAggregate;
  token_usage?: Maybe<Scalars['json']['output']>;
  /** normal, summary */
  type: ChatTypeEnum;
};


/** columns and relationships of "chats" */
export type ChatsRefsArgs = {
  distinct_on?: InputMaybe<Array<ChatRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatRefsOrderBy>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsRefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatRefsOrderBy>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsTokenUsageArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "chats" */
export type ChatsAggregate = {
  __typename?: 'chats_aggregate';
  aggregate?: Maybe<ChatsAggregateFields>;
  nodes: Array<Chats>;
};

export type ChatsAggregateBoolExp = {
  count?: InputMaybe<ChatsAggregateBoolExpCount>;
};

export type ChatsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ChatsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chats" */
export type ChatsAggregateFields = {
  __typename?: 'chats_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ChatsMaxFields>;
  min?: Maybe<ChatsMinFields>;
};


/** aggregate fields of "chats" */
export type ChatsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chats" */
export type ChatsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatsMaxOrderBy>;
  min?: InputMaybe<ChatsMinOrderBy>;
};

/** input type for inserting array relation for remote table "chats" */
export type ChatsArrRelInsertInput = {
  data: Array<ChatsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatsOnConflict>;
};

/** Boolean expression to filter rows from the table "chats". All fields are combined with a logical 'AND'. */
export type ChatsBoolExp = {
  _and?: InputMaybe<Array<ChatsBoolExp>>;
  _not?: InputMaybe<ChatsBoolExp>;
  _or?: InputMaybe<Array<ChatsBoolExp>>;
  answer?: InputMaybe<StringComparisonExp>;
  chat_type?: InputMaybe<ChatTypeBoolExp>;
  conversation?: InputMaybe<ConversationsBoolExp>;
  conversation_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  deleted_at?: InputMaybe<TimestamptzComparisonExp>;
  failure_reason?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  original_question?: InputMaybe<StringComparisonExp>;
  prompt?: InputMaybe<StringComparisonExp>;
  refs?: InputMaybe<ChatRefsBoolExp>;
  refs_aggregate?: InputMaybe<ChatRefsAggregateBoolExp>;
  token_usage?: InputMaybe<JsonComparisonExp>;
  type?: InputMaybe<ChatTypeEnumComparisonExp>;
};

/** unique or primary key constraints on table "chats" */
export enum ChatsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChatsPkey = 'chats_pkey'
}

/** input type for inserting data into table "chats" */
export type ChatsInsertInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  chat_type?: InputMaybe<ChatTypeObjRelInsertInput>;
  conversation?: InputMaybe<ConversationsObjRelInsertInput>;
  conversation_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failure_reason?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  original_question?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  refs?: InputMaybe<ChatRefsArrRelInsertInput>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  /** normal, summary */
  type?: InputMaybe<ChatTypeEnum>;
};

/** aggregate max on columns */
export type ChatsMaxFields = {
  __typename?: 'chats_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  conversation_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  original_question?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "chats" */
export type ChatsMaxOrderBy = {
  answer?: InputMaybe<OrderBy>;
  conversation_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  failure_reason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  original_question?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatsMinFields = {
  __typename?: 'chats_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  conversation_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  original_question?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "chats" */
export type ChatsMinOrderBy = {
  answer?: InputMaybe<OrderBy>;
  conversation_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  failure_reason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  original_question?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chats" */
export type ChatsMutationResponse = {
  __typename?: 'chats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Chats>;
};

/** on_conflict condition type for table "chats" */
export type ChatsOnConflict = {
  constraint: ChatsConstraint;
  update_columns?: Array<ChatsUpdateColumn>;
  where?: InputMaybe<ChatsBoolExp>;
};

/** Ordering options when selecting data from "chats". */
export type ChatsOrderBy = {
  answer?: InputMaybe<OrderBy>;
  chat_type?: InputMaybe<ChatTypeOrderBy>;
  conversation?: InputMaybe<ConversationsOrderBy>;
  conversation_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  deleted_at?: InputMaybe<OrderBy>;
  failure_reason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  original_question?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  refs_aggregate?: InputMaybe<ChatRefsAggregateOrderBy>;
  token_usage?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chats */
export type ChatsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "chats" */
export enum ChatsSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  ConversationId = 'conversation_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  OriginalQuestion = 'original_question',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chats" */
export type ChatsSetInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  conversation_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failure_reason?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  original_question?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  /** normal, summary */
  type?: InputMaybe<ChatTypeEnum>;
};

/** Streaming cursor of the table "chats" */
export type ChatsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatsStreamCursorValueInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  conversation_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failure_reason?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  original_question?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  /** normal, summary */
  type?: InputMaybe<ChatTypeEnum>;
};

/** update columns of table "chats" */
export enum ChatsUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  ConversationId = 'conversation_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  OriginalQuestion = 'original_question',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  Type = 'type'
}

export type ChatsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatsBoolExp;
};

/** columns and relationships of "conversations" */
export type Conversations = {
  __typename?: 'conversations';
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  closed: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user_file?: Maybe<UserFiles>;
  user_file_id: Scalars['uuid']['output'];
};


/** columns and relationships of "conversations" */
export type ConversationsChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


/** columns and relationships of "conversations" */
export type ConversationsChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};

/** aggregated selection of "conversations" */
export type ConversationsAggregate = {
  __typename?: 'conversations_aggregate';
  aggregate?: Maybe<ConversationsAggregateFields>;
  nodes: Array<Conversations>;
};

export type ConversationsAggregateBoolExp = {
  bool_and?: InputMaybe<ConversationsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ConversationsAggregateBoolExpBoolOr>;
  count?: InputMaybe<ConversationsAggregateBoolExpCount>;
};

export type ConversationsAggregateBoolExpBoolAnd = {
  arguments: ConversationsSelectColumnConversationsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConversationsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConversationsAggregateBoolExpBoolOr = {
  arguments: ConversationsSelectColumnConversationsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConversationsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConversationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConversationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConversationsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "conversations" */
export type ConversationsAggregateFields = {
  __typename?: 'conversations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ConversationsMaxFields>;
  min?: Maybe<ConversationsMinFields>;
};


/** aggregate fields of "conversations" */
export type ConversationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConversationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "conversations" */
export type ConversationsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConversationsMaxOrderBy>;
  min?: InputMaybe<ConversationsMinOrderBy>;
};

/** input type for inserting array relation for remote table "conversations" */
export type ConversationsArrRelInsertInput = {
  data: Array<ConversationsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ConversationsOnConflict>;
};

/** Boolean expression to filter rows from the table "conversations". All fields are combined with a logical 'AND'. */
export type ConversationsBoolExp = {
  _and?: InputMaybe<Array<ConversationsBoolExp>>;
  _not?: InputMaybe<ConversationsBoolExp>;
  _or?: InputMaybe<Array<ConversationsBoolExp>>;
  chats?: InputMaybe<ChatsBoolExp>;
  chats_aggregate?: InputMaybe<ChatsAggregateBoolExp>;
  closed?: InputMaybe<BooleanComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_file?: InputMaybe<UserFilesBoolExp>;
  user_file_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "conversations" */
export enum ConversationsConstraint {
  /** unique or primary key constraint on columns "id" */
  ConversationsPkey = 'conversations_pkey'
}

/** input type for inserting data into table "conversations" */
export type ConversationsInsertInput = {
  chats?: InputMaybe<ChatsArrRelInsertInput>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_file?: InputMaybe<UserFilesObjRelInsertInput>;
  user_file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ConversationsMaxFields = {
  __typename?: 'conversations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_file_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "conversations" */
export type ConversationsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_file_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConversationsMinFields = {
  __typename?: 'conversations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_file_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "conversations" */
export type ConversationsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_file_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "conversations" */
export type ConversationsMutationResponse = {
  __typename?: 'conversations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Conversations>;
};

/** input type for inserting object relation for remote table "conversations" */
export type ConversationsObjRelInsertInput = {
  data: ConversationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ConversationsOnConflict>;
};

/** on_conflict condition type for table "conversations" */
export type ConversationsOnConflict = {
  constraint: ConversationsConstraint;
  update_columns?: Array<ConversationsUpdateColumn>;
  where?: InputMaybe<ConversationsBoolExp>;
};

/** Ordering options when selecting data from "conversations". */
export type ConversationsOrderBy = {
  chats_aggregate?: InputMaybe<ChatsAggregateOrderBy>;
  closed?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_file?: InputMaybe<UserFilesOrderBy>;
  user_file_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: conversations */
export type ConversationsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "conversations" */
export enum ConversationsSelectColumn {
  /** column name */
  Closed = 'closed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserFileId = 'user_file_id'
}

/** select "conversations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "conversations" */
export enum ConversationsSelectColumnConversationsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Closed = 'closed'
}

/** select "conversations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "conversations" */
export enum ConversationsSelectColumnConversationsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Closed = 'closed'
}

/** input type for updating data in table "conversations" */
export type ConversationsSetInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "conversations" */
export type ConversationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConversationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConversationsStreamCursorValueInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "conversations" */
export enum ConversationsUpdateColumn {
  /** column name */
  Closed = 'closed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserFileId = 'user_file_id'
}

export type ConversationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConversationsSetInput>;
  /** filter the rows which have to be updated */
  where: ConversationsBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "file_questions" */
export type FileQuestions = {
  __typename?: 'file_questions';
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  prompt: Scalars['String']['output'];
  question: Scalars['String']['output'];
  token_usage: Scalars['json']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "file_questions" */
export type FileQuestionsTokenUsageArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "file_questions" */
export type FileQuestionsAggregate = {
  __typename?: 'file_questions_aggregate';
  aggregate?: Maybe<FileQuestionsAggregateFields>;
  nodes: Array<FileQuestions>;
};

export type FileQuestionsAggregateBoolExp = {
  count?: InputMaybe<FileQuestionsAggregateBoolExpCount>;
};

export type FileQuestionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FileQuestionsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "file_questions" */
export type FileQuestionsAggregateFields = {
  __typename?: 'file_questions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<FileQuestionsMaxFields>;
  min?: Maybe<FileQuestionsMinFields>;
};


/** aggregate fields of "file_questions" */
export type FileQuestionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "file_questions" */
export type FileQuestionsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FileQuestionsMaxOrderBy>;
  min?: InputMaybe<FileQuestionsMinOrderBy>;
};

/** input type for inserting array relation for remote table "file_questions" */
export type FileQuestionsArrRelInsertInput = {
  data: Array<FileQuestionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FileQuestionsOnConflict>;
};

/** Boolean expression to filter rows from the table "file_questions". All fields are combined with a logical 'AND'. */
export type FileQuestionsBoolExp = {
  _and?: InputMaybe<Array<FileQuestionsBoolExp>>;
  _not?: InputMaybe<FileQuestionsBoolExp>;
  _or?: InputMaybe<Array<FileQuestionsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  file_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  prompt?: InputMaybe<StringComparisonExp>;
  question?: InputMaybe<StringComparisonExp>;
  token_usage?: InputMaybe<JsonComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "file_questions" */
export enum FileQuestionsConstraint {
  /** unique or primary key constraint on columns "id" */
  FileQuestionsPkey = 'file_questions_pkey'
}

/** input type for inserting data into table "file_questions" */
export type FileQuestionsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type FileQuestionsMaxFields = {
  __typename?: 'file_questions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "file_questions" */
export type FileQuestionsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  question?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FileQuestionsMinFields = {
  __typename?: 'file_questions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "file_questions" */
export type FileQuestionsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  question?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "file_questions" */
export type FileQuestionsMutationResponse = {
  __typename?: 'file_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FileQuestions>;
};

/** on_conflict condition type for table "file_questions" */
export type FileQuestionsOnConflict = {
  constraint: FileQuestionsConstraint;
  update_columns?: Array<FileQuestionsUpdateColumn>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};

/** Ordering options when selecting data from "file_questions". */
export type FileQuestionsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  question?: InputMaybe<OrderBy>;
  token_usage?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: file_questions */
export type FileQuestionsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "file_questions" */
export enum FileQuestionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Question = 'question',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "file_questions" */
export type FileQuestionsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "file_questions" */
export type FileQuestionsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FileQuestionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FileQuestionsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "file_questions" */
export enum FileQuestionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Question = 'question',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FileQuestionsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileQuestionsSetInput>;
  /** filter the rows which have to be updated */
  where: FileQuestionsBoolExp;
};

/** columns and relationships of "file_summaries" */
export type FileSummaries = {
  __typename?: 'file_summaries';
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  language: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  token_usage: Scalars['json']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "file_summaries" */
export type FileSummariesTokenUsageArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "file_summaries" */
export type FileSummariesAggregate = {
  __typename?: 'file_summaries_aggregate';
  aggregate?: Maybe<FileSummariesAggregateFields>;
  nodes: Array<FileSummaries>;
};

export type FileSummariesAggregateBoolExp = {
  count?: InputMaybe<FileSummariesAggregateBoolExpCount>;
};

export type FileSummariesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FileSummariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FileSummariesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "file_summaries" */
export type FileSummariesAggregateFields = {
  __typename?: 'file_summaries_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<FileSummariesMaxFields>;
  min?: Maybe<FileSummariesMinFields>;
};


/** aggregate fields of "file_summaries" */
export type FileSummariesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FileSummariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "file_summaries" */
export type FileSummariesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FileSummariesMaxOrderBy>;
  min?: InputMaybe<FileSummariesMinOrderBy>;
};

/** input type for inserting array relation for remote table "file_summaries" */
export type FileSummariesArrRelInsertInput = {
  data: Array<FileSummariesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FileSummariesOnConflict>;
};

/** Boolean expression to filter rows from the table "file_summaries". All fields are combined with a logical 'AND'. */
export type FileSummariesBoolExp = {
  _and?: InputMaybe<Array<FileSummariesBoolExp>>;
  _not?: InputMaybe<FileSummariesBoolExp>;
  _or?: InputMaybe<Array<FileSummariesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  file_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  prompt?: InputMaybe<StringComparisonExp>;
  summary?: InputMaybe<StringComparisonExp>;
  token_usage?: InputMaybe<JsonComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "file_summaries" */
export enum FileSummariesConstraint {
  /** unique or primary key constraint on columns "id" */
  FileSummariesPkey = 'file_summaries_pkey'
}

/** input type for inserting data into table "file_summaries" */
export type FileSummariesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type FileSummariesMaxFields = {
  __typename?: 'file_summaries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "file_summaries" */
export type FileSummariesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  summary?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FileSummariesMinFields = {
  __typename?: 'file_summaries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "file_summaries" */
export type FileSummariesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  summary?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "file_summaries" */
export type FileSummariesMutationResponse = {
  __typename?: 'file_summaries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FileSummaries>;
};

/** on_conflict condition type for table "file_summaries" */
export type FileSummariesOnConflict = {
  constraint: FileSummariesConstraint;
  update_columns?: Array<FileSummariesUpdateColumn>;
  where?: InputMaybe<FileSummariesBoolExp>;
};

/** Ordering options when selecting data from "file_summaries". */
export type FileSummariesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  summary?: InputMaybe<OrderBy>;
  token_usage?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: file_summaries */
export type FileSummariesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "file_summaries" */
export enum FileSummariesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Summary = 'summary',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "file_summaries" */
export type FileSummariesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "file_summaries" */
export type FileSummariesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FileSummariesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FileSummariesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  token_usage?: InputMaybe<Scalars['json']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "file_summaries" */
export enum FileSummariesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Summary = 'summary',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type FileSummariesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileSummariesSetInput>;
  /** filter the rows which have to be updated */
  where: FileSummariesBoolExp;
};

/** columns and relationships of "file_summary_refs" */
export type FileSummaryRefs = {
  __typename?: 'file_summary_refs';
  created_at: Scalars['timestamptz']['output'];
  distance: Scalars['numeric']['output'];
  file_summary_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  meta?: Maybe<Scalars['json']['output']>;
  ref_id: Scalars['uuid']['output'];
  ref_page: Scalars['Int']['output'];
};


/** columns and relationships of "file_summary_refs" */
export type FileSummaryRefsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "file_summary_refs" */
export type FileSummaryRefsAggregate = {
  __typename?: 'file_summary_refs_aggregate';
  aggregate?: Maybe<FileSummaryRefsAggregateFields>;
  nodes: Array<FileSummaryRefs>;
};

/** aggregate fields of "file_summary_refs" */
export type FileSummaryRefsAggregateFields = {
  __typename?: 'file_summary_refs_aggregate_fields';
  avg?: Maybe<FileSummaryRefsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FileSummaryRefsMaxFields>;
  min?: Maybe<FileSummaryRefsMinFields>;
  stddev?: Maybe<FileSummaryRefsStddevFields>;
  stddev_pop?: Maybe<FileSummaryRefsStddevPopFields>;
  stddev_samp?: Maybe<FileSummaryRefsStddevSampFields>;
  sum?: Maybe<FileSummaryRefsSumFields>;
  var_pop?: Maybe<FileSummaryRefsVarPopFields>;
  var_samp?: Maybe<FileSummaryRefsVarSampFields>;
  variance?: Maybe<FileSummaryRefsVarianceFields>;
};


/** aggregate fields of "file_summary_refs" */
export type FileSummaryRefsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FileSummaryRefsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type FileSummaryRefsAvgFields = {
  __typename?: 'file_summary_refs_avg_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "file_summary_refs". All fields are combined with a logical 'AND'. */
export type FileSummaryRefsBoolExp = {
  _and?: InputMaybe<Array<FileSummaryRefsBoolExp>>;
  _not?: InputMaybe<FileSummaryRefsBoolExp>;
  _or?: InputMaybe<Array<FileSummaryRefsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  distance?: InputMaybe<NumericComparisonExp>;
  file_summary_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  meta?: InputMaybe<JsonComparisonExp>;
  ref_id?: InputMaybe<UuidComparisonExp>;
  ref_page?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "file_summary_refs" */
export enum FileSummaryRefsConstraint {
  /** unique or primary key constraint on columns "id" */
  FileSummaryRefsPkey = 'file_summary_refs_pkey'
}

/** input type for incrementing numeric columns in table "file_summary_refs" */
export type FileSummaryRefsIncInput = {
  distance?: InputMaybe<Scalars['numeric']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "file_summary_refs" */
export type FileSummaryRefsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance?: InputMaybe<Scalars['numeric']['input']>;
  file_summary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  ref_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type FileSummaryRefsMaxFields = {
  __typename?: 'file_summary_refs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  distance?: Maybe<Scalars['numeric']['output']>;
  file_summary_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ref_id?: Maybe<Scalars['uuid']['output']>;
  ref_page?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type FileSummaryRefsMinFields = {
  __typename?: 'file_summary_refs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  distance?: Maybe<Scalars['numeric']['output']>;
  file_summary_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ref_id?: Maybe<Scalars['uuid']['output']>;
  ref_page?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "file_summary_refs" */
export type FileSummaryRefsMutationResponse = {
  __typename?: 'file_summary_refs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FileSummaryRefs>;
};

/** on_conflict condition type for table "file_summary_refs" */
export type FileSummaryRefsOnConflict = {
  constraint: FileSummaryRefsConstraint;
  update_columns?: Array<FileSummaryRefsUpdateColumn>;
  where?: InputMaybe<FileSummaryRefsBoolExp>;
};

/** Ordering options when selecting data from "file_summary_refs". */
export type FileSummaryRefsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  file_summary_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrderBy>;
  ref_id?: InputMaybe<OrderBy>;
  ref_page?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: file_summary_refs */
export type FileSummaryRefsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "file_summary_refs" */
export enum FileSummaryRefsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Distance = 'distance',
  /** column name */
  FileSummaryId = 'file_summary_id',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  RefId = 'ref_id',
  /** column name */
  RefPage = 'ref_page'
}

/** input type for updating data in table "file_summary_refs" */
export type FileSummaryRefsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance?: InputMaybe<Scalars['numeric']['input']>;
  file_summary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  ref_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type FileSummaryRefsStddevFields = {
  __typename?: 'file_summary_refs_stddev_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FileSummaryRefsStddevPopFields = {
  __typename?: 'file_summary_refs_stddev_pop_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FileSummaryRefsStddevSampFields = {
  __typename?: 'file_summary_refs_stddev_samp_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "file_summary_refs" */
export type FileSummaryRefsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FileSummaryRefsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FileSummaryRefsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance?: InputMaybe<Scalars['numeric']['input']>;
  file_summary_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  ref_id?: InputMaybe<Scalars['uuid']['input']>;
  ref_page?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type FileSummaryRefsSumFields = {
  __typename?: 'file_summary_refs_sum_fields';
  distance?: Maybe<Scalars['numeric']['output']>;
  ref_page?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "file_summary_refs" */
export enum FileSummaryRefsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Distance = 'distance',
  /** column name */
  FileSummaryId = 'file_summary_id',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  RefId = 'ref_id',
  /** column name */
  RefPage = 'ref_page'
}

export type FileSummaryRefsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FileSummaryRefsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileSummaryRefsSetInput>;
  /** filter the rows which have to be updated */
  where: FileSummaryRefsBoolExp;
};

/** aggregate var_pop on columns */
export type FileSummaryRefsVarPopFields = {
  __typename?: 'file_summary_refs_var_pop_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FileSummaryRefsVarSampFields = {
  __typename?: 'file_summary_refs_var_samp_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FileSummaryRefsVarianceFields = {
  __typename?: 'file_summary_refs_variance_fields';
  distance?: Maybe<Scalars['Float']['output']>;
  ref_page?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz']['output'];
  document_count?: Maybe<Scalars['Int']['output']>;
  embedding_usage?: Maybe<Scalars['Int']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  language?: Maybe<Scalars['String']['output']>;
  language_failure_count: Scalars['Int']['output'];
  /** only language prompt */
  prompt?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  questions: Array<FileQuestions>;
  /** An aggregate relationship */
  questions_aggregate: FileQuestionsAggregate;
  size?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  summaries: Array<FileSummaries>;
  /** An aggregate relationship */
  summaries_aggregate: FileSummariesAggregate;
  /** only language usage */
  token_usage?: Maybe<Scalars['json']['output']>;
  total_page?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "files" */
export type FilesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileQuestionsOrderBy>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


/** columns and relationships of "files" */
export type FilesQuestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileQuestionsOrderBy>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


/** columns and relationships of "files" */
export type FilesSummariesArgs = {
  distinct_on?: InputMaybe<Array<FileSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummariesOrderBy>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


/** columns and relationships of "files" */
export type FilesSummariesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummariesOrderBy>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


/** columns and relationships of "files" */
export type FilesTokenUsageArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "files" */
export type FilesAggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<FilesAggregateFields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type FilesAggregateFields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<FilesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FilesMaxFields>;
  min?: Maybe<FilesMinFields>;
  stddev?: Maybe<FilesStddevFields>;
  stddev_pop?: Maybe<FilesStddevPopFields>;
  stddev_samp?: Maybe<FilesStddevSampFields>;
  sum?: Maybe<FilesSumFields>;
  var_pop?: Maybe<FilesVarPopFields>;
  var_samp?: Maybe<FilesVarSampFields>;
  variance?: Maybe<FilesVarianceFields>;
};


/** aggregate fields of "files" */
export type FilesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type FilesAvgFields = {
  __typename?: 'files_avg_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type FilesBoolExp = {
  _and?: InputMaybe<Array<FilesBoolExp>>;
  _not?: InputMaybe<FilesBoolExp>;
  _or?: InputMaybe<Array<FilesBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  document_count?: InputMaybe<IntComparisonExp>;
  embedding_usage?: InputMaybe<IntComparisonExp>;
  failure_reason?: InputMaybe<StringComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  language_failure_count?: InputMaybe<IntComparisonExp>;
  prompt?: InputMaybe<StringComparisonExp>;
  questions?: InputMaybe<FileQuestionsBoolExp>;
  questions_aggregate?: InputMaybe<FileQuestionsAggregateBoolExp>;
  size?: InputMaybe<IntComparisonExp>;
  summaries?: InputMaybe<FileSummariesBoolExp>;
  summaries_aggregate?: InputMaybe<FileSummariesAggregateBoolExp>;
  token_usage?: InputMaybe<JsonComparisonExp>;
  total_page?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "files" */
export enum FilesConstraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for incrementing numeric columns in table "files" */
export type FilesIncInput = {
  document_count?: InputMaybe<Scalars['Int']['input']>;
  embedding_usage?: InputMaybe<Scalars['Int']['input']>;
  language_failure_count?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  total_page?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "files" */
export type FilesInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_count?: InputMaybe<Scalars['Int']['input']>;
  embedding_usage?: InputMaybe<Scalars['Int']['input']>;
  failure_reason?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_failure_count?: InputMaybe<Scalars['Int']['input']>;
  /** only language prompt */
  prompt?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<FileQuestionsArrRelInsertInput>;
  size?: InputMaybe<Scalars['Int']['input']>;
  summaries?: InputMaybe<FileSummariesArrRelInsertInput>;
  /** only language usage */
  token_usage?: InputMaybe<Scalars['json']['input']>;
  total_page?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type FilesMaxFields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_count?: Maybe<Scalars['Int']['output']>;
  embedding_usage?: Maybe<Scalars['Int']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  language_failure_count?: Maybe<Scalars['Int']['output']>;
  /** only language prompt */
  prompt?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  total_page?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type FilesMinFields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_count?: Maybe<Scalars['Int']['output']>;
  embedding_usage?: Maybe<Scalars['Int']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  language_failure_count?: Maybe<Scalars['Int']['output']>;
  /** only language prompt */
  prompt?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  total_page?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "files" */
export type FilesMutationResponse = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type FilesObjRelInsertInput = {
  data: FilesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FilesOnConflict>;
};

/** on_conflict condition type for table "files" */
export type FilesOnConflict = {
  constraint: FilesConstraint;
  update_columns?: Array<FilesUpdateColumn>;
  where?: InputMaybe<FilesBoolExp>;
};

/** Ordering options when selecting data from "files". */
export type FilesOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  document_count?: InputMaybe<OrderBy>;
  embedding_usage?: InputMaybe<OrderBy>;
  failure_reason?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  language_failure_count?: InputMaybe<OrderBy>;
  prompt?: InputMaybe<OrderBy>;
  questions_aggregate?: InputMaybe<FileQuestionsAggregateOrderBy>;
  size?: InputMaybe<OrderBy>;
  summaries_aggregate?: InputMaybe<FileSummariesAggregateOrderBy>;
  token_usage?: InputMaybe<OrderBy>;
  total_page?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: files */
export type FilesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "files" */
export enum FilesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentCount = 'document_count',
  /** column name */
  EmbeddingUsage = 'embedding_usage',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LanguageFailureCount = 'language_failure_count',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Size = 'size',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  TotalPage = 'total_page',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "files" */
export type FilesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_count?: InputMaybe<Scalars['Int']['input']>;
  embedding_usage?: InputMaybe<Scalars['Int']['input']>;
  failure_reason?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_failure_count?: InputMaybe<Scalars['Int']['input']>;
  /** only language prompt */
  prompt?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** only language usage */
  token_usage?: InputMaybe<Scalars['json']['input']>;
  total_page?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type FilesStddevFields = {
  __typename?: 'files_stddev_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FilesStddevPopFields = {
  __typename?: 'files_stddev_pop_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FilesStddevSampFields = {
  __typename?: 'files_stddev_samp_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "files" */
export type FilesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FilesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_count?: InputMaybe<Scalars['Int']['input']>;
  embedding_usage?: InputMaybe<Scalars['Int']['input']>;
  failure_reason?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  language_failure_count?: InputMaybe<Scalars['Int']['input']>;
  /** only language prompt */
  prompt?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** only language usage */
  token_usage?: InputMaybe<Scalars['json']['input']>;
  total_page?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type FilesSumFields = {
  __typename?: 'files_sum_fields';
  document_count?: Maybe<Scalars['Int']['output']>;
  embedding_usage?: Maybe<Scalars['Int']['output']>;
  language_failure_count?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  total_page?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "files" */
export enum FilesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentCount = 'document_count',
  /** column name */
  EmbeddingUsage = 'embedding_usage',
  /** column name */
  FailureReason = 'failure_reason',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LanguageFailureCount = 'language_failure_count',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  Size = 'size',
  /** column name */
  TokenUsage = 'token_usage',
  /** column name */
  TotalPage = 'total_page',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type FilesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FilesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FilesSetInput>;
  /** filter the rows which have to be updated */
  where: FilesBoolExp;
};

/** aggregate var_pop on columns */
export type FilesVarPopFields = {
  __typename?: 'files_var_pop_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FilesVarSampFields = {
  __typename?: 'files_var_samp_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FilesVarianceFields = {
  __typename?: 'files_variance_fields';
  document_count?: Maybe<Scalars['Float']['output']>;
  embedding_usage?: Maybe<Scalars['Float']['output']>;
  language_failure_count?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  total_page?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "invite_codes" */
export type InviteCodes = {
  __typename?: 'invite_codes';
  code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  inviter: Array<Users>;
  /** An aggregate relationship */
  inviter_aggregate: UsersAggregate;
  inviter_id: Scalars['uuid']['output'];
};


/** columns and relationships of "invite_codes" */
export type InviteCodesInviterArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** columns and relationships of "invite_codes" */
export type InviteCodesInviterAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** aggregated selection of "invite_codes" */
export type InviteCodesAggregate = {
  __typename?: 'invite_codes_aggregate';
  aggregate?: Maybe<InviteCodesAggregateFields>;
  nodes: Array<InviteCodes>;
};

/** aggregate fields of "invite_codes" */
export type InviteCodesAggregateFields = {
  __typename?: 'invite_codes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<InviteCodesMaxFields>;
  min?: Maybe<InviteCodesMinFields>;
};


/** aggregate fields of "invite_codes" */
export type InviteCodesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InviteCodesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "invite_codes". All fields are combined with a logical 'AND'. */
export type InviteCodesBoolExp = {
  _and?: InputMaybe<Array<InviteCodesBoolExp>>;
  _not?: InputMaybe<InviteCodesBoolExp>;
  _or?: InputMaybe<Array<InviteCodesBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inviter?: InputMaybe<UsersBoolExp>;
  inviter_aggregate?: InputMaybe<UsersAggregateBoolExp>;
  inviter_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "invite_codes" */
export enum InviteCodesConstraint {
  /** unique or primary key constraint on columns "code" */
  InviteCodesCodeKey = 'invite_codes_code_key',
  /** unique or primary key constraint on columns "id" */
  InviteCodesPkey = 'invite_codes_pkey'
}

/** input type for inserting data into table "invite_codes" */
export type InviteCodesInsertInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inviter?: InputMaybe<UsersArrRelInsertInput>;
  inviter_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type InviteCodesMaxFields = {
  __typename?: 'invite_codes_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inviter_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type InviteCodesMinFields = {
  __typename?: 'invite_codes_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inviter_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "invite_codes" */
export type InviteCodesMutationResponse = {
  __typename?: 'invite_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<InviteCodes>;
};

/** on_conflict condition type for table "invite_codes" */
export type InviteCodesOnConflict = {
  constraint: InviteCodesConstraint;
  update_columns?: Array<InviteCodesUpdateColumn>;
  where?: InputMaybe<InviteCodesBoolExp>;
};

/** Ordering options when selecting data from "invite_codes". */
export type InviteCodesOrderBy = {
  code?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inviter_aggregate?: InputMaybe<UsersAggregateOrderBy>;
  inviter_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: invite_codes */
export type InviteCodesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "invite_codes" */
export enum InviteCodesSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviterId = 'inviter_id'
}

/** input type for updating data in table "invite_codes" */
export type InviteCodesSetInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inviter_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "invite_codes" */
export type InviteCodesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InviteCodesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InviteCodesStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inviter_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "invite_codes" */
export enum InviteCodesUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviterId = 'inviter_id'
}

export type InviteCodesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InviteCodesSetInput>;
  /** filter the rows which have to be updated */
  where: InviteCodesBoolExp;
};

/** columns and relationships of "invite_records" */
export type InviteRecords = {
  __typename?: 'invite_records';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  invite_code_id: Scalars['uuid']['output'];
  invitee_id: Scalars['uuid']['output'];
};

/** aggregated selection of "invite_records" */
export type InviteRecordsAggregate = {
  __typename?: 'invite_records_aggregate';
  aggregate?: Maybe<InviteRecordsAggregateFields>;
  nodes: Array<InviteRecords>;
};

/** aggregate fields of "invite_records" */
export type InviteRecordsAggregateFields = {
  __typename?: 'invite_records_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<InviteRecordsMaxFields>;
  min?: Maybe<InviteRecordsMinFields>;
};


/** aggregate fields of "invite_records" */
export type InviteRecordsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InviteRecordsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "invite_records". All fields are combined with a logical 'AND'. */
export type InviteRecordsBoolExp = {
  _and?: InputMaybe<Array<InviteRecordsBoolExp>>;
  _not?: InputMaybe<InviteRecordsBoolExp>;
  _or?: InputMaybe<Array<InviteRecordsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invite_code_id?: InputMaybe<UuidComparisonExp>;
  invitee_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "invite_records" */
export enum InviteRecordsConstraint {
  /** unique or primary key constraint on columns "id" */
  InviteRecordsPkey = 'invite_records_pkey'
}

/** input type for inserting data into table "invite_records" */
export type InviteRecordsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invite_code_id?: InputMaybe<Scalars['uuid']['input']>;
  invitee_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type InviteRecordsMaxFields = {
  __typename?: 'invite_records_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invite_code_id?: Maybe<Scalars['uuid']['output']>;
  invitee_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type InviteRecordsMinFields = {
  __typename?: 'invite_records_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  invite_code_id?: Maybe<Scalars['uuid']['output']>;
  invitee_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "invite_records" */
export type InviteRecordsMutationResponse = {
  __typename?: 'invite_records_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<InviteRecords>;
};

/** on_conflict condition type for table "invite_records" */
export type InviteRecordsOnConflict = {
  constraint: InviteRecordsConstraint;
  update_columns?: Array<InviteRecordsUpdateColumn>;
  where?: InputMaybe<InviteRecordsBoolExp>;
};

/** Ordering options when selecting data from "invite_records". */
export type InviteRecordsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invite_code_id?: InputMaybe<OrderBy>;
  invitee_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: invite_records */
export type InviteRecordsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "invite_records" */
export enum InviteRecordsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteCodeId = 'invite_code_id',
  /** column name */
  InviteeId = 'invitee_id'
}

/** input type for updating data in table "invite_records" */
export type InviteRecordsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invite_code_id?: InputMaybe<Scalars['uuid']['input']>;
  invitee_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "invite_records" */
export type InviteRecordsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InviteRecordsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InviteRecordsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invite_code_id?: InputMaybe<Scalars['uuid']['input']>;
  invitee_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "invite_records" */
export enum InviteRecordsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteCodeId = 'invite_code_id',
  /** column name */
  InviteeId = 'invitee_id'
}

export type InviteRecordsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InviteRecordsSetInput>;
  /** filter the rows which have to be updated */
  where: InviteRecordsBoolExp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type JsonComparisonExp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "captchas" */
  delete_captchas?: Maybe<CaptchasMutationResponse>;
  /** delete single row from the table: "captchas" */
  delete_captchas_by_pk?: Maybe<Captchas>;
  /** delete data from the table: "chat_refs" */
  delete_chat_refs?: Maybe<ChatRefsMutationResponse>;
  /** delete single row from the table: "chat_refs" */
  delete_chat_refs_by_pk?: Maybe<ChatRefs>;
  /** delete data from the table: "chat_type" */
  delete_chat_type?: Maybe<ChatTypeMutationResponse>;
  /** delete single row from the table: "chat_type" */
  delete_chat_type_by_pk?: Maybe<ChatType>;
  /** delete data from the table: "chats" */
  delete_chats?: Maybe<ChatsMutationResponse>;
  /** delete single row from the table: "chats" */
  delete_chats_by_pk?: Maybe<Chats>;
  /** delete data from the table: "conversations" */
  delete_conversations?: Maybe<ConversationsMutationResponse>;
  /** delete single row from the table: "conversations" */
  delete_conversations_by_pk?: Maybe<Conversations>;
  /** delete data from the table: "file_questions" */
  delete_file_questions?: Maybe<FileQuestionsMutationResponse>;
  /** delete single row from the table: "file_questions" */
  delete_file_questions_by_pk?: Maybe<FileQuestions>;
  /** delete data from the table: "file_summaries" */
  delete_file_summaries?: Maybe<FileSummariesMutationResponse>;
  /** delete single row from the table: "file_summaries" */
  delete_file_summaries_by_pk?: Maybe<FileSummaries>;
  /** delete data from the table: "file_summary_refs" */
  delete_file_summary_refs?: Maybe<FileSummaryRefsMutationResponse>;
  /** delete single row from the table: "file_summary_refs" */
  delete_file_summary_refs_by_pk?: Maybe<FileSummaryRefs>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<FilesMutationResponse>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "invite_codes" */
  delete_invite_codes?: Maybe<InviteCodesMutationResponse>;
  /** delete single row from the table: "invite_codes" */
  delete_invite_codes_by_pk?: Maybe<InviteCodes>;
  /** delete data from the table: "invite_records" */
  delete_invite_records?: Maybe<InviteRecordsMutationResponse>;
  /** delete single row from the table: "invite_records" */
  delete_invite_records_by_pk?: Maybe<InviteRecords>;
  /** delete data from the table: "point_usage_logs" */
  delete_point_usage_logs?: Maybe<PointUsageLogsMutationResponse>;
  /** delete single row from the table: "point_usage_logs" */
  delete_point_usage_logs_by_pk?: Maybe<PointUsageLogs>;
  /** delete data from the table: "user_files" */
  delete_user_files?: Maybe<UserFilesMutationResponse>;
  /** delete single row from the table: "user_files" */
  delete_user_files_by_pk?: Maybe<UserFiles>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<UserRoleMutationResponse>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<UserRole>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "captchas" */
  insert_captchas?: Maybe<CaptchasMutationResponse>;
  /** insert a single row into the table: "captchas" */
  insert_captchas_one?: Maybe<Captchas>;
  /** insert data into the table: "chat_refs" */
  insert_chat_refs?: Maybe<ChatRefsMutationResponse>;
  /** insert a single row into the table: "chat_refs" */
  insert_chat_refs_one?: Maybe<ChatRefs>;
  /** insert data into the table: "chat_type" */
  insert_chat_type?: Maybe<ChatTypeMutationResponse>;
  /** insert a single row into the table: "chat_type" */
  insert_chat_type_one?: Maybe<ChatType>;
  /** insert data into the table: "chats" */
  insert_chats?: Maybe<ChatsMutationResponse>;
  /** insert a single row into the table: "chats" */
  insert_chats_one?: Maybe<Chats>;
  /** insert data into the table: "conversations" */
  insert_conversations?: Maybe<ConversationsMutationResponse>;
  /** insert a single row into the table: "conversations" */
  insert_conversations_one?: Maybe<Conversations>;
  /** insert data into the table: "file_questions" */
  insert_file_questions?: Maybe<FileQuestionsMutationResponse>;
  /** insert a single row into the table: "file_questions" */
  insert_file_questions_one?: Maybe<FileQuestions>;
  /** insert data into the table: "file_summaries" */
  insert_file_summaries?: Maybe<FileSummariesMutationResponse>;
  /** insert a single row into the table: "file_summaries" */
  insert_file_summaries_one?: Maybe<FileSummaries>;
  /** insert data into the table: "file_summary_refs" */
  insert_file_summary_refs?: Maybe<FileSummaryRefsMutationResponse>;
  /** insert a single row into the table: "file_summary_refs" */
  insert_file_summary_refs_one?: Maybe<FileSummaryRefs>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<FilesMutationResponse>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "invite_codes" */
  insert_invite_codes?: Maybe<InviteCodesMutationResponse>;
  /** insert a single row into the table: "invite_codes" */
  insert_invite_codes_one?: Maybe<InviteCodes>;
  /** insert data into the table: "invite_records" */
  insert_invite_records?: Maybe<InviteRecordsMutationResponse>;
  /** insert a single row into the table: "invite_records" */
  insert_invite_records_one?: Maybe<InviteRecords>;
  /** insert data into the table: "point_usage_logs" */
  insert_point_usage_logs?: Maybe<PointUsageLogsMutationResponse>;
  /** insert a single row into the table: "point_usage_logs" */
  insert_point_usage_logs_one?: Maybe<PointUsageLogs>;
  /** insert data into the table: "user_files" */
  insert_user_files?: Maybe<UserFilesMutationResponse>;
  /** insert a single row into the table: "user_files" */
  insert_user_files_one?: Maybe<UserFiles>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<UserRoleMutationResponse>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<UserRole>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "captchas" */
  update_captchas?: Maybe<CaptchasMutationResponse>;
  /** update single row of the table: "captchas" */
  update_captchas_by_pk?: Maybe<Captchas>;
  /** update multiples rows of table: "captchas" */
  update_captchas_many?: Maybe<Array<Maybe<CaptchasMutationResponse>>>;
  /** update data of the table: "chat_refs" */
  update_chat_refs?: Maybe<ChatRefsMutationResponse>;
  /** update single row of the table: "chat_refs" */
  update_chat_refs_by_pk?: Maybe<ChatRefs>;
  /** update multiples rows of table: "chat_refs" */
  update_chat_refs_many?: Maybe<Array<Maybe<ChatRefsMutationResponse>>>;
  /** update data of the table: "chat_type" */
  update_chat_type?: Maybe<ChatTypeMutationResponse>;
  /** update single row of the table: "chat_type" */
  update_chat_type_by_pk?: Maybe<ChatType>;
  /** update multiples rows of table: "chat_type" */
  update_chat_type_many?: Maybe<Array<Maybe<ChatTypeMutationResponse>>>;
  /** update data of the table: "chats" */
  update_chats?: Maybe<ChatsMutationResponse>;
  /** update single row of the table: "chats" */
  update_chats_by_pk?: Maybe<Chats>;
  /** update multiples rows of table: "chats" */
  update_chats_many?: Maybe<Array<Maybe<ChatsMutationResponse>>>;
  /** update data of the table: "conversations" */
  update_conversations?: Maybe<ConversationsMutationResponse>;
  /** update single row of the table: "conversations" */
  update_conversations_by_pk?: Maybe<Conversations>;
  /** update multiples rows of table: "conversations" */
  update_conversations_many?: Maybe<Array<Maybe<ConversationsMutationResponse>>>;
  /** update data of the table: "file_questions" */
  update_file_questions?: Maybe<FileQuestionsMutationResponse>;
  /** update single row of the table: "file_questions" */
  update_file_questions_by_pk?: Maybe<FileQuestions>;
  /** update multiples rows of table: "file_questions" */
  update_file_questions_many?: Maybe<Array<Maybe<FileQuestionsMutationResponse>>>;
  /** update data of the table: "file_summaries" */
  update_file_summaries?: Maybe<FileSummariesMutationResponse>;
  /** update single row of the table: "file_summaries" */
  update_file_summaries_by_pk?: Maybe<FileSummaries>;
  /** update multiples rows of table: "file_summaries" */
  update_file_summaries_many?: Maybe<Array<Maybe<FileSummariesMutationResponse>>>;
  /** update data of the table: "file_summary_refs" */
  update_file_summary_refs?: Maybe<FileSummaryRefsMutationResponse>;
  /** update single row of the table: "file_summary_refs" */
  update_file_summary_refs_by_pk?: Maybe<FileSummaryRefs>;
  /** update multiples rows of table: "file_summary_refs" */
  update_file_summary_refs_many?: Maybe<Array<Maybe<FileSummaryRefsMutationResponse>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<FilesMutationResponse>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<FilesMutationResponse>>>;
  /** update data of the table: "invite_codes" */
  update_invite_codes?: Maybe<InviteCodesMutationResponse>;
  /** update single row of the table: "invite_codes" */
  update_invite_codes_by_pk?: Maybe<InviteCodes>;
  /** update multiples rows of table: "invite_codes" */
  update_invite_codes_many?: Maybe<Array<Maybe<InviteCodesMutationResponse>>>;
  /** update data of the table: "invite_records" */
  update_invite_records?: Maybe<InviteRecordsMutationResponse>;
  /** update single row of the table: "invite_records" */
  update_invite_records_by_pk?: Maybe<InviteRecords>;
  /** update multiples rows of table: "invite_records" */
  update_invite_records_many?: Maybe<Array<Maybe<InviteRecordsMutationResponse>>>;
  /** update data of the table: "point_usage_logs" */
  update_point_usage_logs?: Maybe<PointUsageLogsMutationResponse>;
  /** update single row of the table: "point_usage_logs" */
  update_point_usage_logs_by_pk?: Maybe<PointUsageLogs>;
  /** update multiples rows of table: "point_usage_logs" */
  update_point_usage_logs_many?: Maybe<Array<Maybe<PointUsageLogsMutationResponse>>>;
  /** update data of the table: "user_files" */
  update_user_files?: Maybe<UserFilesMutationResponse>;
  /** update single row of the table: "user_files" */
  update_user_files_by_pk?: Maybe<UserFiles>;
  /** update multiples rows of table: "user_files" */
  update_user_files_many?: Maybe<Array<Maybe<UserFilesMutationResponse>>>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<UserRoleMutationResponse>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<UserRole>;
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<UserRoleMutationResponse>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
};


/** mutation root */
export type MutationRootDeleteCaptchasArgs = {
  where: CaptchasBoolExp;
};


/** mutation root */
export type MutationRootDeleteCaptchasByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteChatRefsArgs = {
  where: ChatRefsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatRefsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteChatTypeArgs = {
  where: ChatTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatTypeByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteChatsArgs = {
  where: ChatsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteConversationsArgs = {
  where: ConversationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteConversationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFileQuestionsArgs = {
  where: FileQuestionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileQuestionsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFileSummariesArgs = {
  where: FileSummariesBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileSummariesByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFileSummaryRefsArgs = {
  where: FileSummaryRefsBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileSummaryRefsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFilesArgs = {
  where: FilesBoolExp;
};


/** mutation root */
export type MutationRootDeleteFilesByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteInviteCodesArgs = {
  where: InviteCodesBoolExp;
};


/** mutation root */
export type MutationRootDeleteInviteCodesByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteInviteRecordsArgs = {
  where: InviteRecordsBoolExp;
};


/** mutation root */
export type MutationRootDeleteInviteRecordsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeletePointUsageLogsArgs = {
  where: PointUsageLogsBoolExp;
};


/** mutation root */
export type MutationRootDeletePointUsageLogsByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteUserFilesArgs = {
  where: UserFilesBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserFilesByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteUserRoleArgs = {
  where: UserRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserRoleByPkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootInsertCaptchasArgs = {
  objects: Array<CaptchasInsertInput>;
  on_conflict?: InputMaybe<CaptchasOnConflict>;
};


/** mutation root */
export type MutationRootInsertCaptchasOneArgs = {
  object: CaptchasInsertInput;
  on_conflict?: InputMaybe<CaptchasOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatRefsArgs = {
  objects: Array<ChatRefsInsertInput>;
  on_conflict?: InputMaybe<ChatRefsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatRefsOneArgs = {
  object: ChatRefsInsertInput;
  on_conflict?: InputMaybe<ChatRefsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatTypeArgs = {
  objects: Array<ChatTypeInsertInput>;
  on_conflict?: InputMaybe<ChatTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatTypeOneArgs = {
  object: ChatTypeInsertInput;
  on_conflict?: InputMaybe<ChatTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatsArgs = {
  objects: Array<ChatsInsertInput>;
  on_conflict?: InputMaybe<ChatsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatsOneArgs = {
  object: ChatsInsertInput;
  on_conflict?: InputMaybe<ChatsOnConflict>;
};


/** mutation root */
export type MutationRootInsertConversationsArgs = {
  objects: Array<ConversationsInsertInput>;
  on_conflict?: InputMaybe<ConversationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertConversationsOneArgs = {
  object: ConversationsInsertInput;
  on_conflict?: InputMaybe<ConversationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileQuestionsArgs = {
  objects: Array<FileQuestionsInsertInput>;
  on_conflict?: InputMaybe<FileQuestionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileQuestionsOneArgs = {
  object: FileQuestionsInsertInput;
  on_conflict?: InputMaybe<FileQuestionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileSummariesArgs = {
  objects: Array<FileSummariesInsertInput>;
  on_conflict?: InputMaybe<FileSummariesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileSummariesOneArgs = {
  object: FileSummariesInsertInput;
  on_conflict?: InputMaybe<FileSummariesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileSummaryRefsArgs = {
  objects: Array<FileSummaryRefsInsertInput>;
  on_conflict?: InputMaybe<FileSummaryRefsOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileSummaryRefsOneArgs = {
  object: FileSummaryRefsInsertInput;
  on_conflict?: InputMaybe<FileSummaryRefsOnConflict>;
};


/** mutation root */
export type MutationRootInsertFilesArgs = {
  objects: Array<FilesInsertInput>;
  on_conflict?: InputMaybe<FilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFilesOneArgs = {
  object: FilesInsertInput;
  on_conflict?: InputMaybe<FilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertInviteCodesArgs = {
  objects: Array<InviteCodesInsertInput>;
  on_conflict?: InputMaybe<InviteCodesOnConflict>;
};


/** mutation root */
export type MutationRootInsertInviteCodesOneArgs = {
  object: InviteCodesInsertInput;
  on_conflict?: InputMaybe<InviteCodesOnConflict>;
};


/** mutation root */
export type MutationRootInsertInviteRecordsArgs = {
  objects: Array<InviteRecordsInsertInput>;
  on_conflict?: InputMaybe<InviteRecordsOnConflict>;
};


/** mutation root */
export type MutationRootInsertInviteRecordsOneArgs = {
  object: InviteRecordsInsertInput;
  on_conflict?: InputMaybe<InviteRecordsOnConflict>;
};


/** mutation root */
export type MutationRootInsertPointUsageLogsArgs = {
  objects: Array<PointUsageLogsInsertInput>;
  on_conflict?: InputMaybe<PointUsageLogsOnConflict>;
};


/** mutation root */
export type MutationRootInsertPointUsageLogsOneArgs = {
  object: PointUsageLogsInsertInput;
  on_conflict?: InputMaybe<PointUsageLogsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserFilesArgs = {
  objects: Array<UserFilesInsertInput>;
  on_conflict?: InputMaybe<UserFilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserFilesOneArgs = {
  object: UserFilesInsertInput;
  on_conflict?: InputMaybe<UserFilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserRoleArgs = {
  objects: Array<UserRoleInsertInput>;
  on_conflict?: InputMaybe<UserRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserRoleOneArgs = {
  object: UserRoleInsertInput;
  on_conflict?: InputMaybe<UserRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootUpdateCaptchasArgs = {
  _set?: InputMaybe<CaptchasSetInput>;
  where: CaptchasBoolExp;
};


/** mutation root */
export type MutationRootUpdateCaptchasByPkArgs = {
  _set?: InputMaybe<CaptchasSetInput>;
  pk_columns: CaptchasPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCaptchasManyArgs = {
  updates: Array<CaptchasUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatRefsArgs = {
  _inc?: InputMaybe<ChatRefsIncInput>;
  _set?: InputMaybe<ChatRefsSetInput>;
  where: ChatRefsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatRefsByPkArgs = {
  _inc?: InputMaybe<ChatRefsIncInput>;
  _set?: InputMaybe<ChatRefsSetInput>;
  pk_columns: ChatRefsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatRefsManyArgs = {
  updates: Array<ChatRefsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatTypeArgs = {
  _set?: InputMaybe<ChatTypeSetInput>;
  where: ChatTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatTypeByPkArgs = {
  _set?: InputMaybe<ChatTypeSetInput>;
  pk_columns: ChatTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatTypeManyArgs = {
  updates: Array<ChatTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatsArgs = {
  _set?: InputMaybe<ChatsSetInput>;
  where: ChatsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatsByPkArgs = {
  _set?: InputMaybe<ChatsSetInput>;
  pk_columns: ChatsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatsManyArgs = {
  updates: Array<ChatsUpdates>;
};


/** mutation root */
export type MutationRootUpdateConversationsArgs = {
  _set?: InputMaybe<ConversationsSetInput>;
  where: ConversationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateConversationsByPkArgs = {
  _set?: InputMaybe<ConversationsSetInput>;
  pk_columns: ConversationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConversationsManyArgs = {
  updates: Array<ConversationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateFileQuestionsArgs = {
  _set?: InputMaybe<FileQuestionsSetInput>;
  where: FileQuestionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileQuestionsByPkArgs = {
  _set?: InputMaybe<FileQuestionsSetInput>;
  pk_columns: FileQuestionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFileQuestionsManyArgs = {
  updates: Array<FileQuestionsUpdates>;
};


/** mutation root */
export type MutationRootUpdateFileSummariesArgs = {
  _set?: InputMaybe<FileSummariesSetInput>;
  where: FileSummariesBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileSummariesByPkArgs = {
  _set?: InputMaybe<FileSummariesSetInput>;
  pk_columns: FileSummariesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFileSummariesManyArgs = {
  updates: Array<FileSummariesUpdates>;
};


/** mutation root */
export type MutationRootUpdateFileSummaryRefsArgs = {
  _inc?: InputMaybe<FileSummaryRefsIncInput>;
  _set?: InputMaybe<FileSummaryRefsSetInput>;
  where: FileSummaryRefsBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileSummaryRefsByPkArgs = {
  _inc?: InputMaybe<FileSummaryRefsIncInput>;
  _set?: InputMaybe<FileSummaryRefsSetInput>;
  pk_columns: FileSummaryRefsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFileSummaryRefsManyArgs = {
  updates: Array<FileSummaryRefsUpdates>;
};


/** mutation root */
export type MutationRootUpdateFilesArgs = {
  _inc?: InputMaybe<FilesIncInput>;
  _set?: InputMaybe<FilesSetInput>;
  where: FilesBoolExp;
};


/** mutation root */
export type MutationRootUpdateFilesByPkArgs = {
  _inc?: InputMaybe<FilesIncInput>;
  _set?: InputMaybe<FilesSetInput>;
  pk_columns: FilesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFilesManyArgs = {
  updates: Array<FilesUpdates>;
};


/** mutation root */
export type MutationRootUpdateInviteCodesArgs = {
  _set?: InputMaybe<InviteCodesSetInput>;
  where: InviteCodesBoolExp;
};


/** mutation root */
export type MutationRootUpdateInviteCodesByPkArgs = {
  _set?: InputMaybe<InviteCodesSetInput>;
  pk_columns: InviteCodesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateInviteCodesManyArgs = {
  updates: Array<InviteCodesUpdates>;
};


/** mutation root */
export type MutationRootUpdateInviteRecordsArgs = {
  _set?: InputMaybe<InviteRecordsSetInput>;
  where: InviteRecordsBoolExp;
};


/** mutation root */
export type MutationRootUpdateInviteRecordsByPkArgs = {
  _set?: InputMaybe<InviteRecordsSetInput>;
  pk_columns: InviteRecordsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateInviteRecordsManyArgs = {
  updates: Array<InviteRecordsUpdates>;
};


/** mutation root */
export type MutationRootUpdatePointUsageLogsArgs = {
  _inc?: InputMaybe<PointUsageLogsIncInput>;
  _set?: InputMaybe<PointUsageLogsSetInput>;
  where: PointUsageLogsBoolExp;
};


/** mutation root */
export type MutationRootUpdatePointUsageLogsByPkArgs = {
  _inc?: InputMaybe<PointUsageLogsIncInput>;
  _set?: InputMaybe<PointUsageLogsSetInput>;
  pk_columns: PointUsageLogsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePointUsageLogsManyArgs = {
  updates: Array<PointUsageLogsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserFilesArgs = {
  _set?: InputMaybe<UserFilesSetInput>;
  where: UserFilesBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserFilesByPkArgs = {
  _set?: InputMaybe<UserFilesSetInput>;
  pk_columns: UserFilesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserFilesManyArgs = {
  updates: Array<UserFilesUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserRoleArgs = {
  _set?: InputMaybe<UserRoleSetInput>;
  where: UserRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserRoleByPkArgs = {
  _set?: InputMaybe<UserRoleSetInput>;
  pk_columns: UserRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserRoleManyArgs = {
  updates: Array<UserRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _inc?: InputMaybe<UsersIncInput>;
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _inc?: InputMaybe<UsersIncInput>;
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "point_usage_logs" */
export type PointUsageLogs = {
  __typename?: 'point_usage_logs';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['uuid']['output'];
  meta?: Maybe<Scalars['json']['output']>;
  source: Scalars['String']['output'];
  used_point: Scalars['Int']['output'];
  user_id: Scalars['uuid']['output'];
};


/** columns and relationships of "point_usage_logs" */
export type PointUsageLogsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "point_usage_logs" */
export type PointUsageLogsAggregate = {
  __typename?: 'point_usage_logs_aggregate';
  aggregate?: Maybe<PointUsageLogsAggregateFields>;
  nodes: Array<PointUsageLogs>;
};

/** aggregate fields of "point_usage_logs" */
export type PointUsageLogsAggregateFields = {
  __typename?: 'point_usage_logs_aggregate_fields';
  avg?: Maybe<PointUsageLogsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PointUsageLogsMaxFields>;
  min?: Maybe<PointUsageLogsMinFields>;
  stddev?: Maybe<PointUsageLogsStddevFields>;
  stddev_pop?: Maybe<PointUsageLogsStddevPopFields>;
  stddev_samp?: Maybe<PointUsageLogsStddevSampFields>;
  sum?: Maybe<PointUsageLogsSumFields>;
  var_pop?: Maybe<PointUsageLogsVarPopFields>;
  var_samp?: Maybe<PointUsageLogsVarSampFields>;
  variance?: Maybe<PointUsageLogsVarianceFields>;
};


/** aggregate fields of "point_usage_logs" */
export type PointUsageLogsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PointUsageLogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PointUsageLogsAvgFields = {
  __typename?: 'point_usage_logs_avg_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "point_usage_logs". All fields are combined with a logical 'AND'. */
export type PointUsageLogsBoolExp = {
  _and?: InputMaybe<Array<PointUsageLogsBoolExp>>;
  _not?: InputMaybe<PointUsageLogsBoolExp>;
  _or?: InputMaybe<Array<PointUsageLogsBoolExp>>;
  created_at?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  meta?: InputMaybe<JsonComparisonExp>;
  source?: InputMaybe<StringComparisonExp>;
  used_point?: InputMaybe<IntComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "point_usage_logs" */
export enum PointUsageLogsConstraint {
  /** unique or primary key constraint on columns "id" */
  PointUsageLogsPkey = 'point_usage_logs_pkey'
}

/** input type for incrementing numeric columns in table "point_usage_logs" */
export type PointUsageLogsIncInput = {
  used_point?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "point_usage_logs" */
export type PointUsageLogsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type PointUsageLogsMaxFields = {
  __typename?: 'point_usage_logs_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  used_point?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type PointUsageLogsMinFields = {
  __typename?: 'point_usage_logs_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  used_point?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "point_usage_logs" */
export type PointUsageLogsMutationResponse = {
  __typename?: 'point_usage_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PointUsageLogs>;
};

/** on_conflict condition type for table "point_usage_logs" */
export type PointUsageLogsOnConflict = {
  constraint: PointUsageLogsConstraint;
  update_columns?: Array<PointUsageLogsUpdateColumn>;
  where?: InputMaybe<PointUsageLogsBoolExp>;
};

/** Ordering options when selecting data from "point_usage_logs". */
export type PointUsageLogsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: point_usage_logs */
export type PointUsageLogsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "point_usage_logs" */
export enum PointUsageLogsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Source = 'source',
  /** column name */
  UsedPoint = 'used_point',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "point_usage_logs" */
export type PointUsageLogsSetInput = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type PointUsageLogsStddevFields = {
  __typename?: 'point_usage_logs_stddev_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PointUsageLogsStddevPopFields = {
  __typename?: 'point_usage_logs_stddev_pop_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PointUsageLogsStddevSampFields = {
  __typename?: 'point_usage_logs_stddev_samp_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "point_usage_logs" */
export type PointUsageLogsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PointUsageLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PointUsageLogsStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  meta?: InputMaybe<Scalars['json']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type PointUsageLogsSumFields = {
  __typename?: 'point_usage_logs_sum_fields';
  used_point?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "point_usage_logs" */
export enum PointUsageLogsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Source = 'source',
  /** column name */
  UsedPoint = 'used_point',
  /** column name */
  UserId = 'user_id'
}

export type PointUsageLogsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PointUsageLogsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PointUsageLogsSetInput>;
  /** filter the rows which have to be updated */
  where: PointUsageLogsBoolExp;
};

/** aggregate var_pop on columns */
export type PointUsageLogsVarPopFields = {
  __typename?: 'point_usage_logs_var_pop_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PointUsageLogsVarSampFields = {
  __typename?: 'point_usage_logs_var_samp_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PointUsageLogsVarianceFields = {
  __typename?: 'point_usage_logs_variance_fields';
  used_point?: Maybe<Scalars['Float']['output']>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "captchas" */
  captchas: Array<Captchas>;
  /** fetch aggregated fields from the table: "captchas" */
  captchas_aggregate: CaptchasAggregate;
  /** fetch data from the table: "captchas" using primary key columns */
  captchas_by_pk?: Maybe<Captchas>;
  /** fetch data from the table: "chat_refs" */
  chat_refs: Array<ChatRefs>;
  /** fetch aggregated fields from the table: "chat_refs" */
  chat_refs_aggregate: ChatRefsAggregate;
  /** fetch data from the table: "chat_refs" using primary key columns */
  chat_refs_by_pk?: Maybe<ChatRefs>;
  /** fetch data from the table: "chat_type" */
  chat_type: Array<ChatType>;
  /** fetch aggregated fields from the table: "chat_type" */
  chat_type_aggregate: ChatTypeAggregate;
  /** fetch data from the table: "chat_type" using primary key columns */
  chat_type_by_pk?: Maybe<ChatType>;
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  /** fetch data from the table: "chats" using primary key columns */
  chats_by_pk?: Maybe<Chats>;
  /** An array relationship */
  conversations: Array<Conversations>;
  /** An aggregate relationship */
  conversations_aggregate: ConversationsAggregate;
  /** fetch data from the table: "conversations" using primary key columns */
  conversations_by_pk?: Maybe<Conversations>;
  /** fetch data from the table: "file_questions" */
  file_questions: Array<FileQuestions>;
  /** fetch aggregated fields from the table: "file_questions" */
  file_questions_aggregate: FileQuestionsAggregate;
  /** fetch data from the table: "file_questions" using primary key columns */
  file_questions_by_pk?: Maybe<FileQuestions>;
  /** fetch data from the table: "file_summaries" */
  file_summaries: Array<FileSummaries>;
  /** fetch aggregated fields from the table: "file_summaries" */
  file_summaries_aggregate: FileSummariesAggregate;
  /** fetch data from the table: "file_summaries" using primary key columns */
  file_summaries_by_pk?: Maybe<FileSummaries>;
  /** fetch data from the table: "file_summary_refs" */
  file_summary_refs: Array<FileSummaryRefs>;
  /** fetch aggregated fields from the table: "file_summary_refs" */
  file_summary_refs_aggregate: FileSummaryRefsAggregate;
  /** fetch data from the table: "file_summary_refs" using primary key columns */
  file_summary_refs_by_pk?: Maybe<FileSummaryRefs>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "invite_codes" */
  invite_codes: Array<InviteCodes>;
  /** fetch aggregated fields from the table: "invite_codes" */
  invite_codes_aggregate: InviteCodesAggregate;
  /** fetch data from the table: "invite_codes" using primary key columns */
  invite_codes_by_pk?: Maybe<InviteCodes>;
  /** fetch data from the table: "invite_records" */
  invite_records: Array<InviteRecords>;
  /** fetch aggregated fields from the table: "invite_records" */
  invite_records_aggregate: InviteRecordsAggregate;
  /** fetch data from the table: "invite_records" using primary key columns */
  invite_records_by_pk?: Maybe<InviteRecords>;
  /** fetch data from the table: "point_usage_logs" */
  point_usage_logs: Array<PointUsageLogs>;
  /** fetch aggregated fields from the table: "point_usage_logs" */
  point_usage_logs_aggregate: PointUsageLogsAggregate;
  /** fetch data from the table: "point_usage_logs" using primary key columns */
  point_usage_logs_by_pk?: Maybe<PointUsageLogs>;
  /** An array relationship */
  user_files: Array<UserFiles>;
  /** An aggregate relationship */
  user_files_aggregate: UserFilesAggregate;
  /** fetch data from the table: "user_files" using primary key columns */
  user_files_by_pk?: Maybe<UserFiles>;
  /** fetch data from the table: "user_role" */
  user_role: Array<UserRole>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: UserRoleAggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<UserRole>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type QueryRootCaptchasArgs = {
  distinct_on?: InputMaybe<Array<CaptchasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CaptchasOrderBy>>;
  where?: InputMaybe<CaptchasBoolExp>;
};


export type QueryRootCaptchasAggregateArgs = {
  distinct_on?: InputMaybe<Array<CaptchasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CaptchasOrderBy>>;
  where?: InputMaybe<CaptchasBoolExp>;
};


export type QueryRootCaptchasByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootChatRefsArgs = {
  distinct_on?: InputMaybe<Array<ChatRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatRefsOrderBy>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


export type QueryRootChatRefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatRefsOrderBy>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


export type QueryRootChatRefsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootChatTypeArgs = {
  distinct_on?: InputMaybe<Array<ChatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatTypeOrderBy>>;
  where?: InputMaybe<ChatTypeBoolExp>;
};


export type QueryRootChatTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatTypeOrderBy>>;
  where?: InputMaybe<ChatTypeBoolExp>;
};


export type QueryRootChatTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type QueryRootChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type QueryRootChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type QueryRootChatsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootConversationsArgs = {
  distinct_on?: InputMaybe<Array<ConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy>>;
  where?: InputMaybe<ConversationsBoolExp>;
};


export type QueryRootConversationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy>>;
  where?: InputMaybe<ConversationsBoolExp>;
};


export type QueryRootConversationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootFileQuestionsArgs = {
  distinct_on?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileQuestionsOrderBy>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


export type QueryRootFileQuestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileQuestionsOrderBy>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


export type QueryRootFileQuestionsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootFileSummariesArgs = {
  distinct_on?: InputMaybe<Array<FileSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummariesOrderBy>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


export type QueryRootFileSummariesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummariesOrderBy>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


export type QueryRootFileSummariesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootFileSummaryRefsArgs = {
  distinct_on?: InputMaybe<Array<FileSummaryRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummaryRefsOrderBy>>;
  where?: InputMaybe<FileSummaryRefsBoolExp>;
};


export type QueryRootFileSummaryRefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSummaryRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummaryRefsOrderBy>>;
  where?: InputMaybe<FileSummaryRefsBoolExp>;
};


export type QueryRootFileSummaryRefsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type QueryRootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type QueryRootFilesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootInviteCodesArgs = {
  distinct_on?: InputMaybe<Array<InviteCodesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteCodesOrderBy>>;
  where?: InputMaybe<InviteCodesBoolExp>;
};


export type QueryRootInviteCodesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InviteCodesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteCodesOrderBy>>;
  where?: InputMaybe<InviteCodesBoolExp>;
};


export type QueryRootInviteCodesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootInviteRecordsArgs = {
  distinct_on?: InputMaybe<Array<InviteRecordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteRecordsOrderBy>>;
  where?: InputMaybe<InviteRecordsBoolExp>;
};


export type QueryRootInviteRecordsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InviteRecordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteRecordsOrderBy>>;
  where?: InputMaybe<InviteRecordsBoolExp>;
};


export type QueryRootInviteRecordsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootPointUsageLogsArgs = {
  distinct_on?: InputMaybe<Array<PointUsageLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PointUsageLogsOrderBy>>;
  where?: InputMaybe<PointUsageLogsBoolExp>;
};


export type QueryRootPointUsageLogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PointUsageLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PointUsageLogsOrderBy>>;
  where?: InputMaybe<PointUsageLogsBoolExp>;
};


export type QueryRootPointUsageLogsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootUserFilesArgs = {
  distinct_on?: InputMaybe<Array<UserFilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFilesOrderBy>>;
  where?: InputMaybe<UserFilesBoolExp>;
};


export type QueryRootUserFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFilesOrderBy>>;
  where?: InputMaybe<UserFilesBoolExp>;
};


export type QueryRootUserFilesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootUserRoleArgs = {
  distinct_on?: InputMaybe<Array<UserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleOrderBy>>;
  where?: InputMaybe<UserRoleBoolExp>;
};


export type QueryRootUserRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleOrderBy>>;
  where?: InputMaybe<UserRoleBoolExp>;
};


export type QueryRootUserRoleByPkArgs = {
  value: Scalars['String']['input'];
};


export type QueryRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "captchas" */
  captchas: Array<Captchas>;
  /** fetch aggregated fields from the table: "captchas" */
  captchas_aggregate: CaptchasAggregate;
  /** fetch data from the table: "captchas" using primary key columns */
  captchas_by_pk?: Maybe<Captchas>;
  /** fetch data from the table in a streaming manner: "captchas" */
  captchas_stream: Array<Captchas>;
  /** fetch data from the table: "chat_refs" */
  chat_refs: Array<ChatRefs>;
  /** fetch aggregated fields from the table: "chat_refs" */
  chat_refs_aggregate: ChatRefsAggregate;
  /** fetch data from the table: "chat_refs" using primary key columns */
  chat_refs_by_pk?: Maybe<ChatRefs>;
  /** fetch data from the table in a streaming manner: "chat_refs" */
  chat_refs_stream: Array<ChatRefs>;
  /** fetch data from the table: "chat_type" */
  chat_type: Array<ChatType>;
  /** fetch aggregated fields from the table: "chat_type" */
  chat_type_aggregate: ChatTypeAggregate;
  /** fetch data from the table: "chat_type" using primary key columns */
  chat_type_by_pk?: Maybe<ChatType>;
  /** fetch data from the table in a streaming manner: "chat_type" */
  chat_type_stream: Array<ChatType>;
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  /** fetch data from the table: "chats" using primary key columns */
  chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table in a streaming manner: "chats" */
  chats_stream: Array<Chats>;
  /** An array relationship */
  conversations: Array<Conversations>;
  /** An aggregate relationship */
  conversations_aggregate: ConversationsAggregate;
  /** fetch data from the table: "conversations" using primary key columns */
  conversations_by_pk?: Maybe<Conversations>;
  /** fetch data from the table in a streaming manner: "conversations" */
  conversations_stream: Array<Conversations>;
  /** fetch data from the table: "file_questions" */
  file_questions: Array<FileQuestions>;
  /** fetch aggregated fields from the table: "file_questions" */
  file_questions_aggregate: FileQuestionsAggregate;
  /** fetch data from the table: "file_questions" using primary key columns */
  file_questions_by_pk?: Maybe<FileQuestions>;
  /** fetch data from the table in a streaming manner: "file_questions" */
  file_questions_stream: Array<FileQuestions>;
  /** fetch data from the table: "file_summaries" */
  file_summaries: Array<FileSummaries>;
  /** fetch aggregated fields from the table: "file_summaries" */
  file_summaries_aggregate: FileSummariesAggregate;
  /** fetch data from the table: "file_summaries" using primary key columns */
  file_summaries_by_pk?: Maybe<FileSummaries>;
  /** fetch data from the table in a streaming manner: "file_summaries" */
  file_summaries_stream: Array<FileSummaries>;
  /** fetch data from the table: "file_summary_refs" */
  file_summary_refs: Array<FileSummaryRefs>;
  /** fetch aggregated fields from the table: "file_summary_refs" */
  file_summary_refs_aggregate: FileSummaryRefsAggregate;
  /** fetch data from the table: "file_summary_refs" using primary key columns */
  file_summary_refs_by_pk?: Maybe<FileSummaryRefs>;
  /** fetch data from the table in a streaming manner: "file_summary_refs" */
  file_summary_refs_stream: Array<FileSummaryRefs>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "invite_codes" */
  invite_codes: Array<InviteCodes>;
  /** fetch aggregated fields from the table: "invite_codes" */
  invite_codes_aggregate: InviteCodesAggregate;
  /** fetch data from the table: "invite_codes" using primary key columns */
  invite_codes_by_pk?: Maybe<InviteCodes>;
  /** fetch data from the table in a streaming manner: "invite_codes" */
  invite_codes_stream: Array<InviteCodes>;
  /** fetch data from the table: "invite_records" */
  invite_records: Array<InviteRecords>;
  /** fetch aggregated fields from the table: "invite_records" */
  invite_records_aggregate: InviteRecordsAggregate;
  /** fetch data from the table: "invite_records" using primary key columns */
  invite_records_by_pk?: Maybe<InviteRecords>;
  /** fetch data from the table in a streaming manner: "invite_records" */
  invite_records_stream: Array<InviteRecords>;
  /** fetch data from the table: "point_usage_logs" */
  point_usage_logs: Array<PointUsageLogs>;
  /** fetch aggregated fields from the table: "point_usage_logs" */
  point_usage_logs_aggregate: PointUsageLogsAggregate;
  /** fetch data from the table: "point_usage_logs" using primary key columns */
  point_usage_logs_by_pk?: Maybe<PointUsageLogs>;
  /** fetch data from the table in a streaming manner: "point_usage_logs" */
  point_usage_logs_stream: Array<PointUsageLogs>;
  /** An array relationship */
  user_files: Array<UserFiles>;
  /** An aggregate relationship */
  user_files_aggregate: UserFilesAggregate;
  /** fetch data from the table: "user_files" using primary key columns */
  user_files_by_pk?: Maybe<UserFiles>;
  /** fetch data from the table in a streaming manner: "user_files" */
  user_files_stream: Array<UserFiles>;
  /** fetch data from the table: "user_role" */
  user_role: Array<UserRole>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: UserRoleAggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<UserRole>;
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<UserRole>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type SubscriptionRootCaptchasArgs = {
  distinct_on?: InputMaybe<Array<CaptchasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CaptchasOrderBy>>;
  where?: InputMaybe<CaptchasBoolExp>;
};


export type SubscriptionRootCaptchasAggregateArgs = {
  distinct_on?: InputMaybe<Array<CaptchasSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CaptchasOrderBy>>;
  where?: InputMaybe<CaptchasBoolExp>;
};


export type SubscriptionRootCaptchasByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootCaptchasStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CaptchasStreamCursorInput>>;
  where?: InputMaybe<CaptchasBoolExp>;
};


export type SubscriptionRootChatRefsArgs = {
  distinct_on?: InputMaybe<Array<ChatRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatRefsOrderBy>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


export type SubscriptionRootChatRefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatRefsOrderBy>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


export type SubscriptionRootChatRefsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootChatRefsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChatRefsStreamCursorInput>>;
  where?: InputMaybe<ChatRefsBoolExp>;
};


export type SubscriptionRootChatTypeArgs = {
  distinct_on?: InputMaybe<Array<ChatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatTypeOrderBy>>;
  where?: InputMaybe<ChatTypeBoolExp>;
};


export type SubscriptionRootChatTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatTypeOrderBy>>;
  where?: InputMaybe<ChatTypeBoolExp>;
};


export type SubscriptionRootChatTypeByPkArgs = {
  value: Scalars['String']['input'];
};


export type SubscriptionRootChatTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChatTypeStreamCursorInput>>;
  where?: InputMaybe<ChatTypeBoolExp>;
};


export type SubscriptionRootChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type SubscriptionRootChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type SubscriptionRootChatsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootChatsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChatsStreamCursorInput>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type SubscriptionRootConversationsArgs = {
  distinct_on?: InputMaybe<Array<ConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy>>;
  where?: InputMaybe<ConversationsBoolExp>;
};


export type SubscriptionRootConversationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy>>;
  where?: InputMaybe<ConversationsBoolExp>;
};


export type SubscriptionRootConversationsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootConversationsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConversationsStreamCursorInput>>;
  where?: InputMaybe<ConversationsBoolExp>;
};


export type SubscriptionRootFileQuestionsArgs = {
  distinct_on?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileQuestionsOrderBy>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


export type SubscriptionRootFileQuestionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileQuestionsOrderBy>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


export type SubscriptionRootFileQuestionsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootFileQuestionsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FileQuestionsStreamCursorInput>>;
  where?: InputMaybe<FileQuestionsBoolExp>;
};


export type SubscriptionRootFileSummariesArgs = {
  distinct_on?: InputMaybe<Array<FileSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummariesOrderBy>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


export type SubscriptionRootFileSummariesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummariesOrderBy>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


export type SubscriptionRootFileSummariesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootFileSummariesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FileSummariesStreamCursorInput>>;
  where?: InputMaybe<FileSummariesBoolExp>;
};


export type SubscriptionRootFileSummaryRefsArgs = {
  distinct_on?: InputMaybe<Array<FileSummaryRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummaryRefsOrderBy>>;
  where?: InputMaybe<FileSummaryRefsBoolExp>;
};


export type SubscriptionRootFileSummaryRefsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSummaryRefsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileSummaryRefsOrderBy>>;
  where?: InputMaybe<FileSummaryRefsBoolExp>;
};


export type SubscriptionRootFileSummaryRefsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootFileSummaryRefsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FileSummaryRefsStreamCursorInput>>;
  where?: InputMaybe<FileSummaryRefsBoolExp>;
};


export type SubscriptionRootFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootFilesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootFilesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FilesStreamCursorInput>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootInviteCodesArgs = {
  distinct_on?: InputMaybe<Array<InviteCodesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteCodesOrderBy>>;
  where?: InputMaybe<InviteCodesBoolExp>;
};


export type SubscriptionRootInviteCodesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InviteCodesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteCodesOrderBy>>;
  where?: InputMaybe<InviteCodesBoolExp>;
};


export type SubscriptionRootInviteCodesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootInviteCodesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<InviteCodesStreamCursorInput>>;
  where?: InputMaybe<InviteCodesBoolExp>;
};


export type SubscriptionRootInviteRecordsArgs = {
  distinct_on?: InputMaybe<Array<InviteRecordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteRecordsOrderBy>>;
  where?: InputMaybe<InviteRecordsBoolExp>;
};


export type SubscriptionRootInviteRecordsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InviteRecordsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InviteRecordsOrderBy>>;
  where?: InputMaybe<InviteRecordsBoolExp>;
};


export type SubscriptionRootInviteRecordsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootInviteRecordsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<InviteRecordsStreamCursorInput>>;
  where?: InputMaybe<InviteRecordsBoolExp>;
};


export type SubscriptionRootPointUsageLogsArgs = {
  distinct_on?: InputMaybe<Array<PointUsageLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PointUsageLogsOrderBy>>;
  where?: InputMaybe<PointUsageLogsBoolExp>;
};


export type SubscriptionRootPointUsageLogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PointUsageLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PointUsageLogsOrderBy>>;
  where?: InputMaybe<PointUsageLogsBoolExp>;
};


export type SubscriptionRootPointUsageLogsByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootPointUsageLogsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PointUsageLogsStreamCursorInput>>;
  where?: InputMaybe<PointUsageLogsBoolExp>;
};


export type SubscriptionRootUserFilesArgs = {
  distinct_on?: InputMaybe<Array<UserFilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFilesOrderBy>>;
  where?: InputMaybe<UserFilesBoolExp>;
};


export type SubscriptionRootUserFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFilesOrderBy>>;
  where?: InputMaybe<UserFilesBoolExp>;
};


export type SubscriptionRootUserFilesByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootUserFilesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserFilesStreamCursorInput>>;
  where?: InputMaybe<UserFilesBoolExp>;
};


export type SubscriptionRootUserRoleArgs = {
  distinct_on?: InputMaybe<Array<UserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleOrderBy>>;
  where?: InputMaybe<UserRoleBoolExp>;
};


export type SubscriptionRootUserRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleOrderBy>>;
  where?: InputMaybe<UserRoleBoolExp>;
};


export type SubscriptionRootUserRoleByPkArgs = {
  value: Scalars['String']['input'];
};


export type SubscriptionRootUserRoleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserRoleStreamCursorInput>>;
  where?: InputMaybe<UserRoleBoolExp>;
};


export type SubscriptionRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootUsersStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user_files" */
export type UserFiles = {
  __typename?: 'user_files';
  /** An array relationship */
  conversations: Array<Conversations>;
  /** An aggregate relationship */
  conversations_aggregate: ConversationsAggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id: Scalars['uuid']['output'];
  file_name: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
};


/** columns and relationships of "user_files" */
export type UserFilesConversationsArgs = {
  distinct_on?: InputMaybe<Array<ConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy>>;
  where?: InputMaybe<ConversationsBoolExp>;
};


/** columns and relationships of "user_files" */
export type UserFilesConversationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy>>;
  where?: InputMaybe<ConversationsBoolExp>;
};

/** aggregated selection of "user_files" */
export type UserFilesAggregate = {
  __typename?: 'user_files_aggregate';
  aggregate?: Maybe<UserFilesAggregateFields>;
  nodes: Array<UserFiles>;
};

export type UserFilesAggregateBoolExp = {
  bool_and?: InputMaybe<UserFilesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserFilesAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserFilesAggregateBoolExpCount>;
};

export type UserFilesAggregateBoolExpBoolAnd = {
  arguments: UserFilesSelectColumnUserFilesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFilesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserFilesAggregateBoolExpBoolOr = {
  arguments: UserFilesSelectColumnUserFilesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFilesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserFilesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserFilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserFilesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_files" */
export type UserFilesAggregateFields = {
  __typename?: 'user_files_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserFilesMaxFields>;
  min?: Maybe<UserFilesMinFields>;
};


/** aggregate fields of "user_files" */
export type UserFilesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserFilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_files" */
export type UserFilesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserFilesMaxOrderBy>;
  min?: InputMaybe<UserFilesMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_files" */
export type UserFilesArrRelInsertInput = {
  data: Array<UserFilesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserFilesOnConflict>;
};

/** Boolean expression to filter rows from the table "user_files". All fields are combined with a logical 'AND'. */
export type UserFilesBoolExp = {
  _and?: InputMaybe<Array<UserFilesBoolExp>>;
  _not?: InputMaybe<UserFilesBoolExp>;
  _or?: InputMaybe<Array<UserFilesBoolExp>>;
  conversations?: InputMaybe<ConversationsBoolExp>;
  conversations_aggregate?: InputMaybe<ConversationsAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  file?: InputMaybe<FilesBoolExp>;
  file_id?: InputMaybe<UuidComparisonExp>;
  file_name?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_deleted?: InputMaybe<BooleanComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_files" */
export enum UserFilesConstraint {
  /** unique or primary key constraint on columns "id" */
  UserFilesPkey = 'user_files_pkey'
}

/** input type for inserting data into table "user_files" */
export type UserFilesInsertInput = {
  conversations?: InputMaybe<ConversationsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<FilesObjRelInsertInput>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type UserFilesMaxFields = {
  __typename?: 'user_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_files" */
export type UserFilesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  file_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserFilesMinFields = {
  __typename?: 'user_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_files" */
export type UserFilesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  file_id?: InputMaybe<OrderBy>;
  file_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_files" */
export type UserFilesMutationResponse = {
  __typename?: 'user_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserFiles>;
};

/** input type for inserting object relation for remote table "user_files" */
export type UserFilesObjRelInsertInput = {
  data: UserFilesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserFilesOnConflict>;
};

/** on_conflict condition type for table "user_files" */
export type UserFilesOnConflict = {
  constraint: UserFilesConstraint;
  update_columns?: Array<UserFilesUpdateColumn>;
  where?: InputMaybe<UserFilesBoolExp>;
};

/** Ordering options when selecting data from "user_files". */
export type UserFilesOrderBy = {
  conversations_aggregate?: InputMaybe<ConversationsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  file?: InputMaybe<FilesOrderBy>;
  file_id?: InputMaybe<OrderBy>;
  file_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_deleted?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_files */
export type UserFilesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_files" */
export enum UserFilesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_files" */
export enum UserFilesSelectColumnUserFilesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "user_files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_files" */
export enum UserFilesSelectColumnUserFilesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "user_files" */
export type UserFilesSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_files" */
export type UserFilesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserFilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserFilesStreamCursorValueInput = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_files" */
export enum UserFilesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type UserFilesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserFilesSetInput>;
  /** filter the rows which have to be updated */
  where: UserFilesBoolExp;
};

/** columns and relationships of "user_role" */
export type UserRole = {
  __typename?: 'user_role';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  user_role: Array<Users>;
  /** An aggregate relationship */
  user_role_aggregate: UsersAggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: UsersAggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "user_role" */
export type UserRoleUserRoleArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** columns and relationships of "user_role" */
export type UserRoleUserRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** columns and relationships of "user_role" */
export type UserRoleUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** columns and relationships of "user_role" */
export type UserRoleUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** aggregated selection of "user_role" */
export type UserRoleAggregate = {
  __typename?: 'user_role_aggregate';
  aggregate?: Maybe<UserRoleAggregateFields>;
  nodes: Array<UserRole>;
};

/** aggregate fields of "user_role" */
export type UserRoleAggregateFields = {
  __typename?: 'user_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserRoleMaxFields>;
  min?: Maybe<UserRoleMinFields>;
};


/** aggregate fields of "user_role" */
export type UserRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type UserRoleBoolExp = {
  _and?: InputMaybe<Array<UserRoleBoolExp>>;
  _not?: InputMaybe<UserRoleBoolExp>;
  _or?: InputMaybe<Array<UserRoleBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  user_role?: InputMaybe<UsersBoolExp>;
  user_role_aggregate?: InputMaybe<UsersAggregateBoolExp>;
  users?: InputMaybe<UsersBoolExp>;
  users_aggregate?: InputMaybe<UsersAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "user_role" */
export enum UserRoleConstraint {
  /** unique or primary key constraint on columns "value" */
  UserRolePkey = 'user_role_pkey'
}

export enum UserRoleEnum {
  /** can do anything */
  Admin = 'admin',
  /** public users */
  Guest = 'guest',
  /** can manage something like backend management */
  Moderator = 'moderator',
  /** Ordinary users */
  User = 'user',
  /** vip */
  Vip = 'vip'
}

/** Boolean expression to compare columns of type "user_role_enum". All fields are combined with logical 'AND'. */
export type UserRoleEnumComparisonExp = {
  _eq?: InputMaybe<UserRoleEnum>;
  _in?: InputMaybe<Array<UserRoleEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<UserRoleEnum>;
  _nin?: InputMaybe<Array<UserRoleEnum>>;
};

/** input type for inserting data into table "user_role" */
export type UserRoleInsertInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<UsersArrRelInsertInput>;
  users?: InputMaybe<UsersArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserRoleMaxFields = {
  __typename?: 'user_role_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserRoleMinFields = {
  __typename?: 'user_role_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user_role" */
export type UserRoleMutationResponse = {
  __typename?: 'user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserRole>;
};

/** input type for inserting object relation for remote table "user_role" */
export type UserRoleObjRelInsertInput = {
  data: UserRoleInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserRoleOnConflict>;
};

/** on_conflict condition type for table "user_role" */
export type UserRoleOnConflict = {
  constraint: UserRoleConstraint;
  update_columns?: Array<UserRoleUpdateColumn>;
  where?: InputMaybe<UserRoleBoolExp>;
};

/** Ordering options when selecting data from "user_role". */
export type UserRoleOrderBy = {
  comment?: InputMaybe<OrderBy>;
  user_role_aggregate?: InputMaybe<UsersAggregateOrderBy>;
  users_aggregate?: InputMaybe<UsersAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_role */
export type UserRolePkColumnsInput = {
  value: Scalars['String']['input'];
};

/** select columns of table "user_role" */
export enum UserRoleSelectColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_role" */
export type UserRoleSetInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_role" */
export type UserRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserRoleStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_role" */
export enum UserRoleUpdateColumn {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type UserRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserRoleSetInput>;
  /** filter the rows which have to be updated */
  where: UserRoleBoolExp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  avatar_url?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  default_language: Scalars['String']['output'];
  device_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  guest_registered?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['uuid']['output'];
  llm_model_name: Scalars['String']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  point_reset_date?: Maybe<Scalars['timestamptz']['output']>;
  role: UserRoleEnum;
  total_point: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  used_point: Scalars['Int']['output'];
  /** An array relationship */
  user_files: Array<UserFiles>;
  /** An aggregate relationship */
  user_files_aggregate: UserFilesAggregate;
  /** An object relationship */
  user_role: UserRole;
  username: Scalars['String']['output'];
  vip_expiry_date?: Maybe<Scalars['timestamptz']['output']>;
  vip_level?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "users" */
export type UsersUserFilesArgs = {
  distinct_on?: InputMaybe<Array<UserFilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFilesOrderBy>>;
  where?: InputMaybe<UserFilesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserFilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserFilesOrderBy>>;
  where?: InputMaybe<UserFilesBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

export type UsersAggregateBoolExp = {
  bool_and?: InputMaybe<UsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<UsersAggregateBoolExpCount>;
};

export type UsersAggregateBoolExpBoolAnd = {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UsersAggregateBoolExpBoolOr = {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<UsersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
  stddev?: Maybe<UsersStddevFields>;
  stddev_pop?: Maybe<UsersStddevPopFields>;
  stddev_samp?: Maybe<UsersStddevSampFields>;
  sum?: Maybe<UsersSumFields>;
  var_pop?: Maybe<UsersVarPopFields>;
  var_samp?: Maybe<UsersVarSampFields>;
  variance?: Maybe<UsersVarianceFields>;
};


/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users" */
export type UsersAggregateOrderBy = {
  avg?: InputMaybe<UsersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UsersMaxOrderBy>;
  min?: InputMaybe<UsersMinOrderBy>;
  stddev?: InputMaybe<UsersStddevOrderBy>;
  stddev_pop?: InputMaybe<UsersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UsersStddevSampOrderBy>;
  sum?: InputMaybe<UsersSumOrderBy>;
  var_pop?: InputMaybe<UsersVarPopOrderBy>;
  var_samp?: InputMaybe<UsersVarSampOrderBy>;
  variance?: InputMaybe<UsersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "users" */
export type UsersArrRelInsertInput = {
  data: Array<UsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** aggregate avg on columns */
export type UsersAvgFields = {
  __typename?: 'users_avg_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users" */
export type UsersAvgOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  avatar_url?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  default_language?: InputMaybe<StringComparisonExp>;
  device_id?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  guest_registered?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  llm_model_name?: InputMaybe<StringComparisonExp>;
  nickname?: InputMaybe<StringComparisonExp>;
  password?: InputMaybe<StringComparisonExp>;
  phone_number?: InputMaybe<StringComparisonExp>;
  point_reset_date?: InputMaybe<TimestamptzComparisonExp>;
  role?: InputMaybe<UserRoleEnumComparisonExp>;
  total_point?: InputMaybe<IntComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  used_point?: InputMaybe<IntComparisonExp>;
  user_files?: InputMaybe<UserFilesBoolExp>;
  user_files_aggregate?: InputMaybe<UserFilesAggregateBoolExp>;
  user_role?: InputMaybe<UserRoleBoolExp>;
  username?: InputMaybe<StringComparisonExp>;
  vip_expiry_date?: InputMaybe<TimestamptzComparisonExp>;
  vip_level?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** input type for incrementing numeric columns in table "users" */
export type UsersIncInput = {
  total_point?: InputMaybe<Scalars['Int']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  vip_level?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_language?: InputMaybe<Scalars['String']['input']>;
  device_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  guest_registered?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  llm_model_name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  point_reset_date?: InputMaybe<Scalars['timestamptz']['input']>;
  role?: InputMaybe<UserRoleEnum>;
  total_point?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  user_files?: InputMaybe<UserFilesArrRelInsertInput>;
  user_role?: InputMaybe<UserRoleObjRelInsertInput>;
  username?: InputMaybe<Scalars['String']['input']>;
  vip_expiry_date?: InputMaybe<Scalars['timestamptz']['input']>;
  vip_level?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_language?: Maybe<Scalars['String']['output']>;
  device_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  llm_model_name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  point_reset_date?: Maybe<Scalars['timestamptz']['output']>;
  total_point?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  used_point?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vip_expiry_date?: Maybe<Scalars['timestamptz']['output']>;
  vip_level?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "users" */
export type UsersMaxOrderBy = {
  avatar_url?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  default_language?: InputMaybe<OrderBy>;
  device_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  llm_model_name?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  point_reset_date?: InputMaybe<OrderBy>;
  total_point?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  vip_expiry_date?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_language?: Maybe<Scalars['String']['output']>;
  device_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  llm_model_name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  point_reset_date?: Maybe<Scalars['timestamptz']['output']>;
  total_point?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  used_point?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vip_expiry_date?: Maybe<Scalars['timestamptz']['output']>;
  vip_level?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "users" */
export type UsersMinOrderBy = {
  avatar_url?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  default_language?: InputMaybe<OrderBy>;
  device_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  llm_model_name?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  point_reset_date?: InputMaybe<OrderBy>;
  total_point?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  vip_expiry_date?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  avatar_url?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  default_language?: InputMaybe<OrderBy>;
  device_id?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  guest_registered?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  llm_model_name?: InputMaybe<OrderBy>;
  nickname?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  point_reset_date?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  total_point?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  user_files_aggregate?: InputMaybe<UserFilesAggregateOrderBy>;
  user_role?: InputMaybe<UserRoleOrderBy>;
  username?: InputMaybe<OrderBy>;
  vip_expiry_date?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultLanguage = 'default_language',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Email = 'email',
  /** column name */
  GuestRegistered = 'guest_registered',
  /** column name */
  Id = 'id',
  /** column name */
  LlmModelName = 'llm_model_name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PointResetDate = 'point_reset_date',
  /** column name */
  Role = 'role',
  /** column name */
  TotalPoint = 'total_point',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedPoint = 'used_point',
  /** column name */
  Username = 'username',
  /** column name */
  VipExpiryDate = 'vip_expiry_date',
  /** column name */
  VipLevel = 'vip_level'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  GuestRegistered = 'guest_registered'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  GuestRegistered = 'guest_registered'
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_language?: InputMaybe<Scalars['String']['input']>;
  device_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  guest_registered?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  llm_model_name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  point_reset_date?: InputMaybe<Scalars['timestamptz']['input']>;
  role?: InputMaybe<UserRoleEnum>;
  total_point?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vip_expiry_date?: InputMaybe<Scalars['timestamptz']['input']>;
  vip_level?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type UsersStddevFields = {
  __typename?: 'users_stddev_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users" */
export type UsersStddevOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UsersStddevPopFields = {
  __typename?: 'users_stddev_pop_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users" */
export type UsersStddevPopOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UsersStddevSampFields = {
  __typename?: 'users_stddev_samp_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users" */
export type UsersStddevSampOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_language?: InputMaybe<Scalars['String']['input']>;
  device_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  guest_registered?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  llm_model_name?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  point_reset_date?: InputMaybe<Scalars['timestamptz']['input']>;
  role?: InputMaybe<UserRoleEnum>;
  total_point?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  used_point?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vip_expiry_date?: InputMaybe<Scalars['timestamptz']['input']>;
  vip_level?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UsersSumFields = {
  __typename?: 'users_sum_fields';
  total_point?: Maybe<Scalars['Int']['output']>;
  used_point?: Maybe<Scalars['Int']['output']>;
  vip_level?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users" */
export type UsersSumOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultLanguage = 'default_language',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Email = 'email',
  /** column name */
  GuestRegistered = 'guest_registered',
  /** column name */
  Id = 'id',
  /** column name */
  LlmModelName = 'llm_model_name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PointResetDate = 'point_reset_date',
  /** column name */
  Role = 'role',
  /** column name */
  TotalPoint = 'total_point',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedPoint = 'used_point',
  /** column name */
  Username = 'username',
  /** column name */
  VipExpiryDate = 'vip_expiry_date',
  /** column name */
  VipLevel = 'vip_level'
}

export type UsersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UsersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** aggregate var_pop on columns */
export type UsersVarPopFields = {
  __typename?: 'users_var_pop_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users" */
export type UsersVarPopOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UsersVarSampFields = {
  __typename?: 'users_var_samp_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users" */
export type UsersVarSampOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UsersVarianceFields = {
  __typename?: 'users_variance_fields';
  total_point?: Maybe<Scalars['Float']['output']>;
  used_point?: Maybe<Scalars['Float']['output']>;
  vip_level?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users" */
export type UsersVarianceOrderBy = {
  total_point?: InputMaybe<OrderBy>;
  used_point?: InputMaybe<OrderBy>;
  vip_level?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;


export type UserByIdQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: string, username: string, email?: string | null, phone_number?: string | null, role: UserRoleEnum, device_id?: string | null, avatar_url?: string | null, nickname?: string | null, default_language: string, llm_model_name: string, total_point: number, used_point: number, vip_level?: number | null, user_files: Array<{ __typename?: 'user_files', conversations: Array<{ __typename?: 'conversations', chats_aggregate: { __typename?: 'chats_aggregate', aggregate?: { __typename?: 'chats_aggregate_fields', count: number } | null } }> }> } | null };

export type UserConversationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationsOrderBy> | ConversationsOrderBy>;
}>;


export type UserConversationsQuery = { __typename?: 'query_root', conversations_aggregate: { __typename?: 'conversations_aggregate', aggregate?: { __typename?: 'conversations_aggregate_fields', count: number } | null }, conversations: Array<{ __typename?: 'conversations', id: string, user_file?: { __typename?: 'user_files', id: string, file_name: string, created_at: any, file?: { __typename?: 'files', size?: number | null, total_page?: number | null } | null } | null }> };

export type UpdateUserInfoMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserInfoMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: string } | null };

export type UpdateUserPreferencesMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserPreferencesMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: string } | null };

export type ConversationSubscriptionVariables = Exact<{
  conversation_id: Scalars['uuid']['input'];
}>;


export type ConversationSubscription = { __typename?: 'subscription_root', conversations_by_pk?: { __typename?: 'conversations', id: string, chats: Array<{ __typename?: 'chats', id: string, original_question: string, answer?: string | null, type: ChatTypeEnum, failure_reason?: string | null, refs: Array<{ __typename?: 'chat_refs', ref_page: number, distance: any }> }>, user_file?: { __typename?: 'user_files', file_name: string, file?: { __typename?: 'files', url?: string | null, total_page?: number | null, token_usage?: any | null, language?: string | null, embedding_usage?: number | null, failure_reason?: string | null, questions: Array<{ __typename?: 'file_questions', question: string }> } | null } | null } | null };

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = { __typename?: 'query_root', test: Array<{ __typename?: 'users', id: string }> };


export const UserByIdDocument = gql`
    query userById($id: uuid!) {
  users_by_pk(id: $id) {
    id
    username
    email
    phone_number
    role
    device_id
    avatar_url
    nickname
    default_language
    llm_model_name
    total_point
    used_point
    vip_level
    user_files {
      conversations {
        chats_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

export function useUserByIdQuery(options: Omit<Urql.UseQueryArgs<UserByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<UserByIdQuery, UserByIdQueryVariables>({ query: UserByIdDocument, ...options });
};
export const UserConversationsDocument = gql`
    query userConversations($limit: Int, $offset: Int, $order_by: [conversations_order_by!]) {
  conversations_aggregate(where: {closed: {_eq: false}}) {
    aggregate {
      count
    }
  }
  conversations(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {closed: {_eq: false}}
  ) {
    id
    user_file {
      id
      file_name
      created_at
      file {
        size
        total_page
      }
    }
  }
}
    `;

export function useUserConversationsQuery(options?: Omit<Urql.UseQueryArgs<UserConversationsQueryVariables>, 'query'>) {
  return Urql.useQuery<UserConversationsQuery, UserConversationsQueryVariables>({ query: UserConversationsDocument, ...options });
};
export const UpdateUserInfoDocument = gql`
    mutation update_user_info($id: uuid!, $avatar_url: String, $nickname: String) {
  update_users_by_pk(
    pk_columns: {id: $id}
    _set: {avatar_url: $avatar_url, nickname: $nickname}
  ) {
    id
  }
}
    `;

export function useUpdateUserInfoMutation() {
  return Urql.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument);
};
export const UpdateUserPreferencesDocument = gql`
    mutation update_user_preferences($id: uuid!, $language: String) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {default_language: $language}) {
    id
  }
}
    `;

export function useUpdateUserPreferencesMutation() {
  return Urql.useMutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>(UpdateUserPreferencesDocument);
};
export const ConversationDocument = gql`
    subscription conversation($conversation_id: uuid!) {
  conversations_by_pk(id: $conversation_id) {
    id
    chats(
      order_by: {created_at: asc}
      where: {deleted_at: {_is_null: true}, type: {_neq: question}}
    ) {
      id
      original_question
      answer
      type
      failure_reason
      refs(order_by: {distance: desc}) {
        ref_page
        distance
      }
    }
    user_file {
      file_name
      file {
        url
        total_page
        token_usage
        language
        embedding_usage
        failure_reason
        questions(limit: 3, order_by: {created_at: asc}) {
          question
        }
      }
    }
  }
}
    `;

export function useConversationSubscription<TData = ConversationSubscription>(options: Omit<Urql.UseSubscriptionArgs<ConversationSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<ConversationSubscription, TData>) {
  return Urql.useSubscription<ConversationSubscription, TData, ConversationSubscriptionVariables>({ query: ConversationDocument, ...options }, handler);
};
export const TestDocument = gql`
    query test {
  test: users {
    id
  }
}
    `;

export function useTestQuery(options?: Omit<Urql.UseQueryArgs<TestQueryVariables>, 'query'>) {
  return Urql.useQuery<TestQuery, TestQueryVariables>({ query: TestDocument, ...options });
};