import { Avatar, Tooltip } from 'antd';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleLoginRegisterModal } from '../../auth/components/LoginRegister';
import authStore from '../../auth/auth.store';
import { AntdIconWithSvgIcon } from '../icon';

export const PlanButton = ({ showTitle }: { showTitle?: boolean }) => {
  const navigate = useNavigate();

  const onClick = useCallback(async () => {
    if (authStore.isGuest || !authStore.isLoggedIn) {
      toggleLoginRegisterModal(true);
      return;
    }

    navigate('/plan');
  }, [navigate]);
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <Tooltip title="升级Prod版" className="flex flex-col items-center">
        <Avatar
          className="bg-transparent "
          size="large"
          icon={<AntdIconWithSvgIcon iconName="Plan" />}
        />

        {showTitle && (
          <span className="text-[12px] text-blue-400 select-none">
            升级Prod版
          </span>
        )}
      </Tooltip>
    </div>
  );
};
