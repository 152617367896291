import {
  Observable,
  OperatorFunction,
  ReplaySubject,
  SchedulerLike,
  ShareReplayConfig,
  Subscribable,
  timer,
} from 'rxjs';
import { shareReplay as rxShareReplay, share } from 'rxjs/operators';
/**
 * Replacement of rxjs.shareReplay with default `refCount: true`
 *
 * We don't patch rxjs.shareReplay directly because maybe some other libraries
 * are also using it, and they know what they are doing.
 */
export const shareReplay = <T>(
  ...args:
    | [ShareReplayConfig]
    | [bufferSize?: number, windowTime?: number, schaduler?: SchedulerLike]
): OperatorFunction<T, T> => {
  if (typeof args[0] !== 'number') {
    return rxShareReplay({
      refCount: true,
      ...args[0],
    });
  } else {
    return rxShareReplay({
      refCount: true,
      bufferSize: args[0],
      windowTime: args[1],
      scheduler: args[2],
    });
  }
};

export const shareReplayWithDelay = <T>(
  config: Omit<ShareReplayConfig, 'refCount'>,
  delay: number,
): OperatorFunction<T, T> => {
  return share<T>({
    connector: () =>
      new ReplaySubject(
        config.bufferSize ?? 1,
        config.windowTime ?? Infinity,
        config.scheduler,
      ),
    resetOnError: true,
    resetOnComplete: false,
    resetOnRefCountZero: () => timer(delay),
  });
};

export const fromSubscribable = <T>(
  subscribable: Subscribable<T>,
): Observable<T> => {
  return new Observable(observer => {
    const subscription = subscribable.subscribe(observer);
    return () => subscription.unsubscribe();
  });
};
