import { Button, Input } from 'antd';
import { useCallback, useState } from 'react';
import { useGenSummary, useSendQuestion } from '../../../chat.store';
import { GenQuestions } from './GenQuestions';
import { ReportError } from './ReportError';
import { SwitchLanguage } from './SwitchLanguage';
import { SwitchModel } from '../../../../../components/SwitchModel';

export const FooterTool = () => {
  const [message, setMessage] = useState('');

  const [genSummary] = useGenSummary();
  const [sendQuestion, sendQuestionLoading] = useSendQuestion();

  const onSend = useCallback(async () => {
    if (message.trim() === '') {
      return;
    }
    setMessage('');
    sendQuestion(message);
  }, [message, sendQuestion, setMessage]);

  return (
    <div className="pt-2 ">
      <div className="flex gap-2">
        <GenQuestions sendQuestion={sendQuestion} />
        <Button size="small" onClick={() => genSummary()}>
          总结摘要
        </Button>
        <SwitchModel />
        <SwitchLanguage />
        <ReportError />
      </div>
      <div className="w-full mt-2 flex gap-4 justify-center items-end">
        <Input.TextArea
          placeholder="跟我聊聊这个文档吧，按回车发送"
          allowClear
          autoSize={{
            minRows: 1,
            maxRows: 7,
          }}
          style={{
            width: '100%',
          }}
          value={message}
          onPressEnter={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              onSend();
            }
          }}
          onChange={e => {
            setMessage(e.target.value);
          }}
        />
        <Button type="primary" loading={sendQuestionLoading} onClick={onSend}>
          发送
        </Button>
      </div>
    </div>
  );
};
