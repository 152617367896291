import { Button } from 'antd';
import { Markdown } from '../Markdown';
import { ModalButton } from './ModalButton';

type PrivacyProps = {
  buttonProps?: React.ComponentProps<typeof Button>;
  children?: React.ReactNode;
};

export const Privacy = ({ buttonProps, children }: PrivacyProps) => {
  return (
    <ModalButton
      modalProps={{
        title: <div className="text-center">软件隐私条款</div>,
      }}
      modalContent={<Markdown>{privacyDoc}</Markdown>}
    >
      {children ?? <Button {...buttonProps}>隐私</Button>}
    </ModalButton>
  );
};

const privacyDoc = `
**最近更新时间2023 年 10 月 01 日**

本隐私声明适用于聊天文档（”**公司**、”“**我们**”、“**我们**”或“**我们的**”），描述了我们如何以及为何收集、存储、使用和/或共享（“**过程**”）您在使用我们的服务时的信息（“**服务”**），例如当您：
- 访问我们的网站在聊天文档网站，或我们的任何链接到本隐私声明的网站。 
- 通过其他相关方式与我们互动，包括任何销售、营销或活动。

阅读本隐私声明将帮助您了解您的隐私权利和选择。如果您不同意我们的政策和做法，请不要使用我们的服务。如果您仍有任何问题或疑虑，请通过以下方式联系我们：[hello@placeholdersoft.com](mailto:hello@placeholdersoft.com)。
### 1. 数据管理方 
本隐私政策描述了 ChatPDF
网站和产品以及我们展示或引用本政策的任何地方的隐私惯例。通过我们的网站和产品，ChatPDF
及其附属实体（统称为“ChatPDF”、“我们”或“我们”）对下列可用于识别您身份的信息（“个人数据”）进行处理。ChatPDF
致力于保护您的个人数据。我们了解您的个人数据的安全非常重要。我们提供合理的管理、技术和物理安全控制措施来保护您的个人数据。我们只会按照本隐私政策中的规定收集和使用您的信息。未经您的明确同意，我们不会将您的数据出售给任何第三方。
### 2. 信息收集 
**2.1. 收集的个人信息类型**

“个人数据”是指与已识别或可识别的自然人（“数据主体”）相关的任何信息；可识别的自然人是指可以直接或间接识别的自然人，特别是通过参考诸如姓名、身份证号码、位置数据、在线标识符等标识符或特定于身体、生理、心理的一个或多个因素来识别的自然人。该自然人的遗传、心理、经济、文化或社会身份。根据您与我们互动的方式，我们会以不同的方式处理不同类型的数据。如果您访问我们的网站或使用我们的产品，某些数据会被自动处理。仅当您主动向我们提交其他数据（例如使用我们的网络表单与我们联系或将内容上传到我们的网站）时，才会处理其他数据。

为了提高用户体验和功能，软件收集了必要的数据，如电子邮件、上传文件的时间。

**2.2. 收集方式**

数据主要在注册时、使用某些软件功能时以及通过用户自愿反馈时收集。 

当用户访问我们的网站时，ChatPDF 会对个人数据进行如下处理： 

2.1.1 我们在您访问我们的网站时收集的个人数据 
- 访问日期和时间以及使用本网站的持续时间； 
- 有关您如何访问我们网站的信息（包括引导您访问 ChatPDF
网站的网页、在搜索引擎中输入引导您访问 ChatPDF 网站的搜索词）； 
- 您对我们网站的操作和点击； - 访问日期和时间以及网站的使用持续时间； 
- 有关您的设备的信息（设备类型、浏览器类型和版本，以及设置、安装的插件、操作系统）。

上述个人数据称为网站使用数据。 

2.1.2 处理目的

我们处理网站使用数据，以便您浏览网站并确保其功能。我们还处理网站使用数据，以对网站的性能进行分析，不断改进网站并纠正错误，确保
IT 安全和我们系统的运行，以及防止或发现滥用行为。 

**2.2 仅当您向我们提交数据时，我们才会处理数据：** 

2.2.1 如果您在购买过程或其他过程中通过数据收集表格向我们提交，我们收集的个人数据：
- 帐户数据； 
- 交易数据：账单信息，您购买的产品类型、您支付的货币金额、订阅 ID、订阅相关
URL（例如购买确认页面 URL、订阅取消 URL、发票 PDF URL）； 
- 评论数据； 
- 客户支持沟通的内容； 
- 您自愿向我们提供的其他数据。 

2.2.2 处理目的

我们使用帐户数据来帮助您创建帐户，提供维护或为您提供支持服务。

我们将使用交易数据来完成您的订单、用于计费目的、内部会计和退款管理目的。

我们将利用评论数据来改进其产品，并为消费者和潜在消费者提供有关 ChatPDF
产品的更好、透明的信息。 为了向您提供您购买的产品，或履行您与 ChatPDF
之间的合同。

我们不会收集或处理有关您的支付工具的数据，例如信用卡号、银行帐号。该信息由我们合格的支付处理商收集、处理和存储。

我们可能会不时通过邮件、电子邮件或电话直接联系我们的客户，通知您有关您告诉我们您有兴趣接收的升级、新产品、促销或特别优惠（包括我们的新闻通讯）的信息。订阅
ChatPDF的简讯以及相关的个人数据提供均是在您自愿的基础上进行的。如果您想取消订阅，您可以联系我们在产品内、通过指引自行操作。

### 3. 使用个人信息 
**3.1. 数据处理原则**

数据处理遵循数据隐私保护法中规定的原则，包括合法性、公平性、透明性、数据最小化、准确性和尊重用户权利。

**3.2. 与第三方分享**

私人用户数据只在中国境内。我们的政策限制将数据传输到中国境外，以符合当地的法规。只有在法律或监管要求下才会有例外。

### 4. 用户权利 
**4.1. 访问和修改个人信息**

根据数据隐私保护法，用户可以查看、修改或删除其数据。请求可以通过软件的用户界面进行。

**4.2. 退出和删除**

用户可以选择退出账户，并有权要求完全删除他们的帐户，该请求将在请求后的30天内处理。

### 5. 数据安全措施 
**5.1. 技术措施**

我们的目标是通过一个系统来保护您的个人信息组织和技术安全措施。我们使用合理的技术、安全的数据存储设施和持续监视潜在违规行为来确保数据的安全性。
然而，尽管我们采取保护措施并努力保护您的信息，但无法保证互联网上的电子传输或信息存储技术
100%安全，因此我们不能承诺或保证黑客、网络犯罪分子或其他人未经授权的第三方将无法破坏我们的安全措施并不当收集、访问、窃取或修改您的信息。我们将尽最大努力保护您的个人信息，但与我们的服务之间传输个人信息的风险由您自行承担。
<font color=red>您应该仅在安全的环境中访问服务。</font>

**5.2. 组织措施**

我们为员工定期提供培训，限制数据访问，并进行定期审计，以强调我们对数据保护的承诺。

### 6. 隐私条款的更新 
**6.1. 通知方法**

任何更改都会通过软件通知、电子邮件及时通知用户，确保始终保持最新状态。

**6.2. 审查和确认**

我们要求用户不时地查看隐私条款。在更新后继续使用软件意味着同意修改后的条款。

### 7. 反馈和投诉 
**7.1. 沟通渠道**

反馈和投诉可以通过我们的反馈表单、软件内聊天或官方电子邮件通道。 

**7.2. 响应时间** 

所有反馈和投诉都被视为最高优先级，回应通常在48小时内发出。 

### 8. 一般条款 
**8.1. 管辖法律**

这些条款符合中国的法律，特别是数据隐私保护法。 

### 9. 联系详情 
**9.1.公司地址和电子邮件**

重庆市沙坪坝区渝倍路街道天陈路12号重庆师范大学内综合实验楼A3-233
[hello@placeholdersoft.com](mailto:hello@placeholdersoft.com)
`;
