import { Provider } from 'urql';

import { ServiceFactoryProvider, services } from '@clients/rest-api-client';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router';
import { setupCrashTracking } from './sentry';
import graphqlClient from './utils/graphqlClient';
import { ObservableResourceHolderProvider } from './utils/rxReact/ObservableResourceHolder';

setupCrashTracking();

export function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <ServiceFactoryProvider services={services}>
        <Provider value={graphqlClient.urqlClient}>
          <ObservableResourceHolderProvider namespace={'ChatStoreContent/'}>
            <RouterProvider router={router}></RouterProvider>
          </ObservableResourceHolderProvider>
        </Provider>
      </ServiceFactoryProvider>
    </ConfigProvider>
  );
}

export default App;
