import { ChatTypeEnum } from '@clients/graphql-client';
import { services } from '@clients/rest-api-client';
import { TLanguageOption, ZLanguageOption } from '@libs/types';
import { message } from 'antd';
import { debounce } from 'lodash';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { createContext, useCallback, useContext, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import authStore from '../../auth/auth.store';
import { preferenceStore } from '../../preference.store';
import { assertIsError } from '../../utils/assert';
import { getLimitError } from '../../utils/limitError';
import type { Chat, Conversation, UserFile } from './chat.service';
import { fetchConversation } from './chat.service';

class ChatStore {
  private controller = new AbortController();

  @observable currentConversation: Conversation | null = null;

  @observable fileInfo: UserFile | null = null;
  currentPage$ = new BehaviorSubject(0);

  @observable chats: Chat[] = [];
  @observable awaitBotResponse = false;

  unsubscribe?: () => void;

  constructor(readonly conversation_id: string) {
    makeAutoObservable(this);
    this.initializeConversation();
  }

  initializeConversation = async () => {
    this.unsubscribe = await fetchConversation(this.conversation_id, v => {
      this.currentConversation = v;
      this.fileInfo = v.user_file;
      this.chats = v.chats;
    });
  };

  @computed get language() {
    return (preferenceStore.language[this.conversation_id] ??
      authStore.user.default_language ??
      ZLanguageOption.Enum.zh) as TLanguageOption;
  }

  @action
  changeLanguage = (language: TLanguageOption) => {
    preferenceStore.setLanguage(this.conversation_id, language);
  };

  @computed get conversation() {
    if (!this.currentConversation) {
      throw new Error('会话未初始化，请稍后再试');
    }
    return this.currentConversation;
  }

  @action
  sendMessage = debounce(async (msg: string) => {
    const uuid = `mock-${nanoid()}`;
    try {
      this.awaitBotResponse = true;
      this.chats = [
        ...this.chats,
        {
          id: uuid,
          original_question: msg,
          type: ChatTypeEnum.Normal,
          refs: [],
        },
      ];

      await services.chatService.chatApiControllerCreateChat(
        {
          CreateChatRequest: {
            question: msg,
            language_option: this.language,
            conversation_id: this.conversation.id,
          },
        },
        { signal: this.controller.signal },
      );

      this.awaitBotResponse = false;
    } catch (e) {
      const index = this.chats.findIndex(v => v.id === uuid);
      if (index !== -1) {
        this.chats[index] = {
          ...this.chats[index],
          failure_reason: '发送失败, 请重试！',
        };
      }
      assertIsError(e);
      message.warning(e.message);
    }
  }, 1000);

  @action
  retryMessage = debounce(async (id: string) => {
    const uuid = nanoid();
    try {
      this.awaitBotResponse = true;

      await services.chatService.chatApiControllerRetryChat(
        {
          chat_id: id,
        },
        { signal: this.controller.signal },
      );

      this.awaitBotResponse = false;
    } catch (e) {
      const index = this.chats.findIndex(v => v.id === uuid);
      if (index !== -1) {
        this.chats[index] = {
          ...this.chats[index],
          failure_reason: '发送失败, 请重试！',
        };
      }
      assertIsError(e);
      message.warning(e.message);
    }
  }, 1000);

  @action
  genSummary = debounce(async () => {
    const uuid = `mock-${nanoid()}`;
    try {
      this.awaitBotResponse = true;
      this.chats = [
        ...this.chats,
        {
          id: uuid,
          original_question: '请帮我生成当前文档的总结摘要',
          type: ChatTypeEnum.Summary,
          refs: [],
        },
      ];

      await services.chatService.chatApiControllerGetSummary(
        {
          GetSummaryRequest: {
            conversation_id: this.conversation.id,
            language_option: this.language,
          },
        },
        { signal: this.controller.signal },
      );

      this.awaitBotResponse = false;
    } catch (e) {
      const index = this.chats.findIndex(v => v.id === uuid);
      if (index !== -1) {
        this.chats[index] = {
          ...this.chats[index],
          failure_reason: '发送失败, 请重试！',
        };
      }
      assertIsError(e);
      message.warning(e.message);
    }
  }, 1000);

  @action
  resetConversation = debounce(async () => {
    try {
      this.controller.abort();
      this.controller = new AbortController();
      await services.chatService.chatApiControllerDeleteChats({
        conversation_id: this.conversation.id,
      });
      this.awaitBotResponse = false;
    } catch (e) {
      assertIsError(e);
      message.warning(e.message);
    }
  }, 1000);

  @action
  delConversation = debounce(async () => {
    await services.chatService.chatApiControllerCloseConversation({
      conversation_id: this.conversation.id,
    });
  }, 1000);
}

export default ChatStore;

export const ChatStoreContext = createContext<ChatStore>(null as any);

export const useGenQuestions = () => {
  const store = useContext(ChatStoreContext);
  const [loading, setLoading] = useState(false);

  const getQuestions = useCallback(
    async (questionNum = 3): Promise<string[]> => {
      try {
        setLoading(true);
        const result =
          await services.chatService.chatApiControllerCreateQuestionsBySummary(
            {
              CreateQuestionsBySummaryRequest: {
                question_num: questionNum,
                conversation_id: store.conversation_id,
                language_option: store.language,
              },
            },
          );
        return result.questions;
      } catch (e) {
        assertIsError(e);
        message.warning(getLimitError(e) ?? e.message);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [store.language, store.conversation_id],
  );

  return [getQuestions, loading] as const;
};

export const useResetConversation = () => {
  const store = useContext(ChatStoreContext);
  const [loading, setLoading] = useState(false);

  const resetConversation = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await store.resetConversation();
    } catch (e) {
      assertIsError(e);
      message.warning(e.message);
    } finally {
      setLoading(false);
    }
  }, [store]);

  return [resetConversation, loading] as const;
};

export const useSendQuestion = () => {
  const store = useContext(ChatStoreContext);
  const [loading, setLoading] = useState(false);

  const sendQuestion = useCallback(
    async (question: string): Promise<void> => {
      try {
        setLoading(true);
        await store.sendMessage(question);
      } catch (e) {
        assertIsError(e);
        message.warning(getLimitError(e) ?? e.message);
      } finally {
        setLoading(false);
      }
    },
    [store],
  );
  return [sendQuestion, loading] as const;
};

export const useGenSummary = () => {
  const store = useContext(ChatStoreContext);
  const [loading, setLoading] = useState(false);

  const getQuestions = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await store.genSummary();
    } catch (e) {
      assertIsError(e);
      message.warning(getLimitError(e) ?? e.message);
    } finally {
      setLoading(false);
    }
  }, [store]);

  return [getQuestions, loading] as const;
};
