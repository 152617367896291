import { Button, Divider, Dropdown, Space, theme } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { CSSProperties, cloneElement, useCallback, useState } from 'react';
import { useGenQuestions } from '../../../chat.store';

const { useToken } = theme;

export const GenQuestions = ({
  sendQuestion,
}: {
  sendQuestion: (question: string) => void;
}) => {
  const [questionItems, setQuestionItems] = useState<ItemType[]>([]);

  const { token } = useToken();
  const [getQuestions, getQuestionsLoading] = useGenQuestions();

  const contentStyle: CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: CSSProperties = {
    boxShadow: 'none',
  };

  const genQuestionItems = useCallback(async () => {
    const result = await getQuestions();
    setQuestionItems(
      result.map((r, i) => {
        return {
          key: i,
          label: <div className="max-w-[400px]">{r}</div>,
          onClick: () => {
            sendQuestion(r);
          },
        } as ItemType;
      }),
    );
  }, [getQuestions, sendQuestion]);
  return (
    <Dropdown
      placement="topLeft"
      menu={{
        items: questionItems,
      }}
      onOpenChange={open => {
        if (open && questionItems.length === 0) {
          genQuestionItems();
        }
      }}
      dropdownRender={menu => (
        <div style={contentStyle}>
          {cloneElement(menu as React.ReactElement, { style: menuStyle })}
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: 8 }}>
            <Button
              size="small"
              type="primary"
              loading={getQuestionsLoading}
              onClick={() => {
                genQuestionItems();
              }}
            >
              换批问题
            </Button>
          </Space>
        </div>
      )}
    >
      <Button size="small">帮我提问</Button>
    </Dropdown>
  );
};
