import { Button, Modal, ModalProps } from 'antd';
import { Children, ReactNode, cloneElement, useState } from 'react';

type ModalButtonProps = {
  buttonText?: ReactNode;
  buttonProps?: React.ComponentProps<typeof Button>;
  children?: ReactNode;
  modalProps?: ModalProps;
  modalContent?: ReactNode;
};

export const ModalButton = ({
  buttonText,
  buttonProps,
  children,
  modalProps,
  modalContent,
}: ModalButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const newChildren = Children.map(children, child => {
    return cloneElement(child as React.ReactElement, {
      onClick: showModal,
    });
  });

  const newModalContent = Children.map(modalContent, child => {
    return cloneElement(child as React.ReactElement, {
      setIsModalOpen: setIsModalOpen,
    });
  });

  return (
    <div>
      {newChildren ?? (
        <Button {...buttonProps} onClick={showModal}>
          {buttonText ?? 'modal button'}
        </Button>
      )}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleCancel}>
            关闭
          </Button>,
        ]}
        {...modalProps}
      >
        {newModalContent}
      </Modal>
    </div>
  );
};
