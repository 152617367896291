import { Avatar } from '../user/components/Avatar';
import { ChangeLog } from './ChangeLog';
import { Guide2 } from './button/Guide';

export const Header = () => {
  return (
    <div className="flex justify-end items-end m-4 gap-3">
      <div className="flex justify-end items-end">
        <Guide2 />
        <ChangeLog />
      </div>
      <Avatar />
    </div>
  );
};
