export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export function deepPartial<T>(input: DeepPartial<T>): T {
  return input as any;
}

export function assertNever(x: never, errorMessage?: string): never {
  if (errorMessage) {
    throw new Error(errorMessage);
  } else {
    throw new Error('Unexpected object: ' + x);
  }
}

export function noopNever(x: never): never {
  return x;
}

export type IntersectingTypes<T, U> = {
  [K in Extract<keyof T, keyof U>]: T[K];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type OmitUnderscoreKeys<Key extends string> = Key extends `_${infer _}`
  ? never
  : Key;

export type AnyFunc<Args extends any[] = any, Ret = any> = (
  ...args: Args
) => Ret;

export type LiteralFromEnum<T extends string> = `${T}`;
