import { env } from '@libs/env';
import { authExchange } from '@urql/exchange-auth';
import { message } from 'antd';
import { createClient, Client as WSClient } from 'graphql-ws';
import { from, map, Observable } from 'rxjs';
import {
  cacheExchange,
  Client,
  CombinedError,
  errorExchange,
  fetchExchange,
  Operation,
  OperationResult,
  subscriptionExchange,
} from 'urql';
import { Source, toObservable } from 'wonka';
import { refreshToken } from '../auth/auth.service';

const EXPIRED_CODE = 'invalid-jwt';

class GraphqlClient {
  wsClient!: WSClient;
  urqlClient!: Client;

  constructor() {
    this.createHasuraClient();
  }

  createHasuraClient() {
    this.wsClient = createClient({
      url: env.HASURA_WS_HOST,
      connectionParams: async () => {
        const token = localStorage.getItem('token');
        if (!token) return {};
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    });

    this.urqlClient = new Client({
      url: env.HASURA_HOST,
      fetchOptions: {
        credentials: 'include',
      },
      exchanges: [
        authExchange(async utils => {
          return {
            addAuthToOperation(operation: Operation): Operation {
              const token = localStorage.getItem('token');
              if (!token) return operation;
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
              });
            },
            didAuthError(error: CombinedError): boolean {
              console.error(error);
              return error.graphQLErrors.some(
                e => e.extensions?.code === EXPIRED_CODE,
              );
            },
            async refreshAuth() {
              await refreshToken();
            },
          };
        }),
        cacheExchange,
        fetchExchange,
        subscriptionExchange({
          forwardSubscription: request => {
            const input = { ...request, query: request.query || '' };

            return {
              subscribe: sink => {
                const unsubscribe = this.wsClient.subscribe(input, sink);
                return {
                  unsubscribe,
                };
              },
            };
          },
        }),
        errorExchange({
          onError(error) {
            console.error(error);
            const graphQLErrors = error.graphQLErrors
              .map(graphQLError => {
                if (graphQLError.extensions?.code === EXPIRED_CODE) {
                  return null;
                }
                const errorMsgEvent = new CustomEvent('errorMessage', {
                  detail: { message: graphQLError.message },
                });
                console.error(`${graphQLError.message}`);
                return errorMsgEvent;
              })
              .filter(Boolean);

            message.warning(graphQLErrors.join('\n'));
          },
        }),
      ],
    });
  }
}

const graphqlClient = new GraphqlClient();

export default graphqlClient;

export function fromUrqlSource<Data>(
  source: Source<OperationResult<Data>>,
): Observable<Data>;
export function fromUrqlSource<Data, TOutput>(
  source: Source<OperationResult<Data>>,
  getData: (result: { data: Data }) => TOutput,
): Observable<TOutput>;
export function fromUrqlSource<Data, TOutput>(
  source: Source<OperationResult<Data>>,
  getData?: (result: { data: Data }) => TOutput,
): Observable<TOutput | Data> {
  const _getData = getData ?? (result => result.data as Data);

  return from(
    new Observable<OperationResult<Data>>(subscriber => {
      const subscription = toObservable(source).subscribe(subscriber);
      return () => subscription.unsubscribe();
    }) as Observable<OperationResult<Data>>,
  ).pipe(
    map(data => {
      if (data.error) {
        throw data.error;
      }
      if (data.data == null) {
        throw new Error('Request returned empty payload', {
          cause: 'fromUrqlSource',
        });
      }
      return _getData({ data: data.data });
    }),
  );
}
