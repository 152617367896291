export interface RetryInfo {
  '@type': 'type.googleapis.com/google.rpc.RetryInfo';
  retryDelay: {
    nanos: number;
    seconds: number;
  };
}

// Describes additional debugging info.
export interface DebugInfo {
  '@type': 'type.googleapis.com/google.rpc.DebugInfo';
  detail: string;
  stackEntries: string[];
}

export interface QuotaFailure {
  '@type': 'type.googleapis.com/google.rpc.QuotaFailure';
  violations: {
    subject: string;
    description: string;
  }[];
}

/**
 * To add a new typed error info:
 *  1. Create a new error reason
 *  2. Create a new ErrorInfo w/ that error reason, and define string-typed metadata
 *  3. Add the new ErrorInfo to TypedErrorInfo literal
 */
export enum ErrorReasons {
  ACCESS_LIMITATION = 'ACCESS_LIMITATION',
}

export interface EnumerableErrorInfo {
  reason: ErrorReasons;
  domain?: string;
  metadata?: Record<string, string>;
}

export interface AccessLimitationErrorInfo extends EnumerableErrorInfo {
  reason: ErrorReasons.ACCESS_LIMITATION;
}

export type TypedErrorInfo = AccessLimitationErrorInfo;

export type ErrorInfo = TypedErrorInfo & {
  '@type': 'type.googleapis.com/google.rpc.ErrorInfo';
};

export interface PreconditionFailure {
  '@type': 'type.googleapis.com/google.rpc.PreconditionFailure';
  violations: {
    type: string;
    subject: string;
    description: string;
  }[];
}

// Describes violations in a client request. This error type focuses on the
// syntactic aspects of the request.
export interface BadRequest {
  '@type': 'type.googleapis.com/google.rpc.BadRequest';
  fieldViolations: {
    field: string;
    description: string;
  }[];
}

export interface RequestInfo {
  '@type': 'type.googleapis.com/google.rpc.RequestInfo';
  // An opaque string that should only be interpreted by the service generating
  // it. For example, it can be used to identify requests in the service's logs.
  requestId: string;

  // Any data that was used to serve this request. For example, an encrypted
  // stack trace that can be sent back to the service provider for debugging.
  servingData?: string;
}

// Describes the resource that is being accessed.
export interface ResourceInfo {
  '@type': 'type.googleapis.com/google.rpc.ResourceInfo';
  resourceType: string;
  resourceName: string;
  owner?: string;
  description?: string;
}

export interface Help {
  '@type': 'type.googleapis.com/google.rpc.Help';
  links: {
    // The URL of the link.
    url: string;
    // Describes what the link offers.
    description?: string;
  }[];
}

// Provides a localized error message that is safe to return to the user
// which can be attached to an RPC error.
export interface LocalizedMessage {
  '@type': 'type.googleapis.com/google.rpc.LocalizedMessage';
  locale: string;

  // The localized error message in the above locale.
  message: string;
}

export enum StatusCode {
  OK = 'OK', // = 0,

  // The operation was cancelled, typically by the caller.
  //
  // HTTP Mapping: 499 Client Closed Request
  CANCELLED = 'CANCELLED', // = 1,

  // Unknown error.  For example, this error may be returned when
  // a `Status` value received from another address space belongs to
  // an error space that is not known in this address space.  Also
  // errors raised by APIs that do not return enough error information
  // may be converted to this error.
  //
  // HTTP Mapping: 500 Internal Server Error
  UNKNOWN = 'UNKNOWN', // = 2,

  // The client specified an invalid argument.  Note that this differs
  // from `FAILED_PRECONDITION`.  `INVALID_ARGUMENT` indicates arguments
  // that are problematic regardless of the state of the system
  // (e.g., a malformed file name).
  //
  // HTTP Mapping: 400 Bad Request
  INVALID_ARGUMENT = 'INVALID_ARGUMENT', // = 3,

  // The deadline expired before the operation could complete. For operations
  // that change the state of the system, this error may be returned
  // even if the operation has completed successfully.  For example, a
  // successful response from a server could have been delayed long
  // enough for the deadline to expire.
  //
  // HTTP Mapping: 504 Gateway Timeout
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED', // = 4,

  // Some requested entity (e.g., file or directory) was not found.
  //
  // Note to server developers: if a request is denied for an entire class
  // of users, such as gradual feature rollout or undocumented whitelist,
  // `NOT_FOUND` may be used. If a request is denied for some users within
  // a class of users, such as user-based access control, `PERMISSION_DENIED`
  // must be used.
  //
  // HTTP Mapping: 404 Not Found
  NOT_FOUND = 'NOT_FOUND', // = 5,

  // The entity that a client attempted to create (e.g., file or directory)
  // already exists.
  //
  // HTTP Mapping: 409 Conflict
  ALREADY_EXISTS = 'ALREADY_EXISTS', // = 6,

  // The caller does not have permission to execute the specified
  // operation. `PERMISSION_DENIED` must not be used for rejections
  // caused by exhausting some resource (use `RESOURCE_EXHAUSTED`
  // instead for those errors). `PERMISSION_DENIED` must not be
  // used if the caller can not be identified (use `UNAUTHENTICATED`
  // instead for those errors). This error code does not imply the
  // request is valid or the requested entity exists or satisfies
  // other pre-conditions.
  //
  // HTTP Mapping: 403 Forbidden
  PERMISSION_DENIED = 'PERMISSION_DENIED', // = 7,

  // The request does not have valid authentication credentials for the
  // operation.
  //
  // HTTP Mapping: 401 Unauthorized
  UNAUTHENTICATED = 'UNAUTHENTICATED', // = 16,

  // Some resource has been exhausted, perhaps a per-user quota, or
  // perhaps the entire file system is out of space.
  //
  // HTTP Mapping: 429 Too Many Requests
  RESOURCE_EXHAUSTED = 'RESOURCE_EXHAUSTED', // = 8,

  // The operation was rejected because the system is not in a state
  // required for the operation's execution.  For example, the directory
  // to be deleted is non-empty, an rmdir operation is applied to
  // a non-directory, etc.
  //
  // Service implementors can use the following guidelines to decide
  // between `FAILED_PRECONDITION`, `ABORTED`, and `UNAVAILABLE`:
  //  (a) Use `UNAVAILABLE` if the client can retry just the failing call.
  //  (b) Use `ABORTED` if the client should retry at a higher level
  //      (e.g., when a client-specified test-and-set fails, indicating the
  //      client should restart a read-modify-write sequence).
  //  (c) Use `FAILED_PRECONDITION` if the client should not retry until
  //      the system state has been explicitly fixed.  E.g., if an "rmdir"
  //      fails because the directory is non-empty, `FAILED_PRECONDITION`
  //      should be returned since the client should not retry unless
  //      the files are deleted from the directory.
  //
  // HTTP Mapping: 400 Bad Request
  FAILED_PRECONDITION = 'FAILED_PRECONDITION', // = 9,

  // The operation was aborted, typically due to a concurrency issue such as
  // a sequencer check failure or transaction abort.
  //
  // See the guidelines above for deciding between `FAILED_PRECONDITION`,
  // `ABORTED`, and `UNAVAILABLE`.
  //
  // HTTP Mapping: 409 Conflict
  ABORTED = 'ABORTED', // = 10,

  // The operation was attempted past the valid range.  E.g., seeking or
  // reading past end-of-file.
  //
  // Unlike `INVALID_ARGUMENT`, this error indicates a problem that may
  // be fixed if the system state changes. For example, a 32-bit file
  // system will generate `INVALID_ARGUMENT` if asked to read at an
  // offset that is not in the range [0,2^32-1], but it will generate
  // `OUT_OF_RANGE` if asked to read from an offset past the current
  // file size.
  //
  // There is a fair bit of overlap between `FAILED_PRECONDITION` and
  // `OUT_OF_RANGE`.  We recommend using `OUT_OF_RANGE` (the more specific
  // error) when it applies so that callers who are iterating through
  // a space can easily look for an `OUT_OF_RANGE` error to detect when
  // they are done.
  //
  // HTTP Mapping: 400 Bad Request
  OUT_OF_RANGE = 'OUT_OF_RANGE', // = 11,

  // The operation is not implemented or is not supported/enabled in this
  // service.
  //
  // HTTP Mapping: 501 Not Implemented
  UNIMPLEMENTED = 'UNIMPLEMENTED', // = 12,

  // Internal errors.  This means that some invariants expected by the
  // underlying system have been broken.  This error code is reserved
  // for serious errors.
  //
  // HTTP Mapping: 500 Internal Server Error
  INTERNAL = 'INTERNAL', // = 13,

  // The service is currently unavailable.  This is most likely a
  // transient condition, which can be corrected by retrying with
  // a backoff.
  //
  // See the guidelines above for deciding between `FAILED_PRECONDITION`,
  // `ABORTED`, and `UNAVAILABLE`.
  //
  // HTTP Mapping: 503 Service Unavailable
  UNAVAILABLE = 'UNAVAILABLE', // = 14,

  // Unrecoverable data loss or corruption.
  //
  // HTTP Mapping: 500 Internal Server Error
  DATA_LOSS = 'DATA_LOSS', // = 15,
  // The 422 (Unprocessable Entity) status code means the server
  // understands the content type of the request entity (hence a 415(Unsupported Media Type)
  // status code is inappropriate), and the syntax of the request entity is correct
  // (thus a 400 (Bad Request) status code is inappropriate) but was unable to process the contained instructions.
  // For example, this error condition may occur if an XML request body contains well-formed (i.e., syntactically correct),
  // but semantically erroneous, XML instructions.
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
}

// https://cloud.google.com/apis/design/errors#error_details
export interface ApiError {
  // This message has the same semantics as `google.rpc.Status`. It uses HTTP
  // status code instead of gRPC status code. It has an extra field `status`
  // for backward compatibility with Google API Client Libraries.
  error: {
    // The HTTP status code that corresponds to `google.rpc.Status.code`.
    code: number;
    status: StatusCode;

    // A developer-facing error message, which should be in English. Any
    // user-facing error message should be localized and sent in the
    // details field, or localized by the client.
    message: string;

    // A list of messages that carry the error details.
    details: (
      | RetryInfo
      | DebugInfo
      | QuotaFailure
      | ErrorInfo
      | PreconditionFailure
      | BadRequest
      | RequestInfo
      | ResourceInfo
      | Help
      | LocalizedMessage
    )[];
  };
}
