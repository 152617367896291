import { message } from 'antd';
import { DependencyList, useCallback, useState } from 'react';
import { assertIsError } from './assert';

function isPromise(target: any): target is Promise<any> {
  return target instanceof Promise;
}

export const useQuery = <T extends any[], U>(
  fun: (...arg: T) => U | Promise<U>,
  deps: DependencyList = [],
  options?: { reset: boolean; onError?: (e: Error) => void },
) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<U | undefined>();

  const query = useCallback(
    async (...arg: Parameters<typeof fun>): Promise<void> => {
      try {
        setLoading(true);
        if (options?.reset) {
          result && setResult(undefined);
        }
        const r = fun(...arg);
        if (isPromise(r)) {
          const resolvedValue = await r;
          setResult(resolvedValue);
        } else {
          setResult(r);
        }
      } catch (e) {
        assertIsError(e);
        message.warning(e.message);
      } finally {
        setLoading(false);
      }
    },
    deps,
  );
  return [query, loading, result] as const;
};
