import { UserByIdQuery } from '@clients/graphql-client';
import {
  LoginByPasswordRequest,
  RegisterByEmailRequest,
} from '@clients/rest-api-client';
import { message } from 'antd';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { assertIsError } from '../utils/assert';
import { useRefreshToken } from '../utils/useRefresh';
import {
  fetchUserProfile,
  guestLogin,
  login,
  mockUserLogin,
  refreshToken,
  register,
} from './auth.service';

export class AuthStore {
  @observable currentUser:
    | (UserByIdQuery['users_by_pk'] & { exp: number })
    | undefined;
  @observable initialized = false;

  constructor() {
    makeAutoObservable(this);

    this.fetchUserProfile();
  }

  @computed({ keepAlive: true }) get user() {
    if (this.currentUser == null) {
      throw new Error('User is not logged in');
    }
    return this.currentUser;
  }

  @computed({ keepAlive: true }) get isLoggedIn() {
    return !!this.currentUser;
  }

  @computed({ keepAlive: true }) get isGuest() {
    return this.currentUser?.role === 'guest';
  }

  @computed({ keepAlive: true }) get isRealUser() {
    return this.currentUser?.role !== 'guest';
  }

  @computed({ keepAlive: true }) get isVipUser() {
    return this.currentUser?.vip_level != null;
  }

  private static parseJwt(token: string) {
    const base64Payload = token.split('.')[1];
    const payload = JSON.parse(atob(base64Payload));

    return {
      token,
      iat: payload.iat,
      exp: payload.exp,
      role: payload['https://hasura.io/jwt/claims']['x-hasura-default-role'],
      uid: payload['https://hasura.io/jwt/claims']['x-hasura-user-id'],
    };
  }

  async fetchUserProfile() {
    this.initialized = true;
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      const jwtUser = AuthStore.parseJwt(token);
      const user = await fetchUserProfile(jwtUser.uid);

      this.currentUser = {
        ...user,
        exp: jwtUser.exp,
      };
    } catch (e) {
      assertIsError(e);
      message.warning(e.message);
    }
  }

  async register(data: RegisterByEmailRequest) {
    await register(data);
    await this.fetchUserProfile();
  }

  async guestLogin() {
    await guestLogin();
    await this.fetchUserProfile();
  }

  async login(data: LoginByPasswordRequest) {
    await login(data);
    await this.fetchUserProfile();
  }

  async mockUserLogin(email: string) {
    await mockUserLogin(email);
    await this.fetchUserProfile();
  }

  async logout() {
    // this.userProfileUnsubscribe?.();
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    this.currentUser = undefined;
    this.initialized = false;
    // await sleep(1000);
  }

  @action
  private async _refreshToken() {
    try {
      await refreshToken();
    } catch (e) {
      assertIsError(e);
      if (e.message.includes('Invalid or expired')) {
        this.currentUser = undefined;
        this.initialized = false;
      } else {
        throw e;
      }
    }
  }

  private onGoingRefreshPromise?: Promise<void>;
  async refreshToken() {
    if (this.onGoingRefreshPromise != null) {
      return await this.onGoingRefreshPromise;
    }
    try {
      this.onGoingRefreshPromise = this._refreshToken();
      await this.onGoingRefreshPromise;
    } finally {
      this.onGoingRefreshPromise = undefined;
    }
  }

  refreshTokenIfNeeded = async () => {
    const refreshToken = localStorage.getItem('refresh_token')!;
    if (
      (this.currentUser == null ||
        this.currentUser.exp < new Date().getTime() / 1000 + 60 * 2) &&
      refreshToken != null
    ) {
      await this.refreshToken();
    }
  };

  useRefreshToken() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRefreshToken(this.refreshTokenIfNeeded, 1000 * 60 * 2);
  }
}

const authStore = new AuthStore();
export default authStore;
