import { ZLLMModelName, ZLLMModelName_ENUM } from '@chat/libs-types';
import { ZLanguageOption } from '@libs/types';

export const languageItems = [
  {
    key: ZLanguageOption.Enum.zh,
    label: '中文',
  },
  {
    key: ZLanguageOption.Enum.en,
    label: 'English',
  },
  {
    key: ZLanguageOption.Enum.sourceLanguage,
    label: '原文语言',
  },
];

export const modelItems: {
  key: ZLLMModelName_ENUM;
  label: string;
}[] = [
  {
    key: ZLLMModelName.Enum['openai:gpt-3.5'],
    label: 'GPT-3.5',
  },
  {
    key: ZLLMModelName.Enum['openai:gpt-4'],
    label: 'GPT-4.0(Pro版功能)',
  },
];
