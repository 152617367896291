import { FormOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useUpdateUserInfoMutation } from '@clients/graphql-client';
import { services } from '@clients/rest-api-client';
import {
  Avatar,
  Button,
  Form,
  Input,
  Tooltip,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import authStore from '../../auth/auth.store';
import { assertIsError } from '../../utils/assert';

type UserInfoForm = {
  avatar: string;
  nickname: string;
};

type UploadFileResponse =
  | {
      status: 'done';
      imageUrl: string;
    }
  | {
      status: 'error';
      message: string;
    };

export const UserInfo = () => {
  const user = authStore.user;
  const [form] = Form.useForm<UserInfoForm>();
  const [{ fetching }, executeMutation] = useUpdateUserInfoMutation();

  const formItemLayout = {
    labelCol: {
      span: 4,
      style: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
    },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    async (values: UserInfoForm) => {
      const result = await executeMutation({
        id: user.id,
        nickname: values.nickname,
        avatar_url: values.avatar,
      });

      if (result.error) {
        message.warning('修改失败，请稍后再试！');
        return;
      }

      message.info('修改成功');

      await authStore.fetchUserProfile();
    },
    [executeMutation, user.id],
  );

  if (user == null) {
    return;
  }

  return (
    <div className="py-8 px-2">
      <Form
        {...formItemLayout}
        form={form}
        name="basic"
        style={{ maxWidth: 600 }}
        initialValues={{
          avatar: user.avatar_url,
          nickname: user.nickname ?? user.email,
          email: user.email,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="头像" required name="avatar">
          <UploadAvatar />
        </Form.Item>
        <Form.Item
          label="昵称"
          name="nickname"
          rules={[
            {
              required: true,
              message: '请输入您的昵称',
            },
          ]}
        >
          <Input type="text" placeholder="请输入您的昵称" />
        </Form.Item>
        <Form.Item label="邮箱" name="email">
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" loading={fetching}>
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const UploadAvatar = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (v: string) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const props: UploadProps = useMemo(() => {
    return {
      accept: 'image/png, image/jpeg',
      showUploadList: false,
      customRequest: async info => {
        setLoading(true);

        const result = await uploadFile(info.file);
        setLoading(false);
        if (result.status === 'error') {
          message.warning(result.message);
          return;
        }

        onChange?.(result.imageUrl);
      },
    };
  }, [onChange]);

  return (
    <div>
      <Upload className="cursor-pointer" {...props}>
        {value && !loading ? (
          <Tooltip title="点击重新上传头像">
            <div>
              <Avatar
                size={56}
                className="bg-[#00000026]"
                src={value}
                alt="点击重新上传头像"
              />
              <FormOutlined className="ml-2" />
            </div>
          </Tooltip>
        ) : loading ? (
          <Avatar
            size={56}
            className="bg-[#00000026]"
            icon={<LoadingOutlined />}
          />
        ) : (
          <Avatar
            size={56}
            className="bg-[#00000026]"
            icon={<PlusOutlined />}
          />
        )}
      </Upload>
    </div>
  );
};

// limit file size
const limit = 10;

const uploadFile = async (file: any) => {
  const isLimit = file.size / 1024 / 1024 < limit;
  if (!isLimit) {
    const data: UploadFileResponse = {
      status: 'error',
      message: `文件必须小于 ${limit}MB!`,
    };
    return data;
  }
  try {
    const result =
      await services.imageService.imageApiControllerGenerateImagePresignedUrl({
        GenPublicImageUploadURLRequest: {
          fileName: file.name,
        },
      });

    await fetch(result.url, {
      method: 'PUT',
      body: file,
    });

    const data: UploadFileResponse = {
      imageUrl: result.imageUrl,
      status: 'done',
    };
    return data;
  } catch (e) {
    assertIsError(e);
    const data: UploadFileResponse = {
      status: 'error',
      message: e.message,
    };
    return data;
  }
};
