import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { Root } from './Root';
import { LoginRegister } from './auth/components/LoginRegister';
import { ErrorPage } from './components/ErrorPage';
import ProtectedRoute from './components/ProtectedRoute';
import { ChatPage } from './page/chat/ChatPage';
import { PlanPage } from './page/plan/PlanPage';
import { UploadPage } from './page/upload/UploadPage';
import { Invite } from './user/components/Invite';

const pdfRouter: RouteObject[] = [
  {
    path: '',
    element: <UploadPage />,
  },
  {
    path: 'invite',
    element: <Invite />,
  },
  {
    path: 'login',
    element: (
      <LoginRegister
        className="w-96 m-auto p-10 rounded"
        style={{
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        }}
      />
    ),
  },
  {
    path: 'plan',
    element: <PlanPage />,
  },
  {
    path: 'chat/:conversation_id',
    element: <ProtectedRoute component={ChatPage} />,
  },
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: pdfRouter,
  },
]);
