import { Avatar, Tooltip } from 'antd';
import { toggleGenInviteCodeModal } from '../../user/components/Invite';
import { AntdIconWithSvgIcon } from '../icon';

export const InviteButton = ({ showTitle }: { showTitle?: boolean }) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => toggleGenInviteCodeModal(true)}
    >
      <Tooltip title="邀请好友" className="flex flex-col items-center">
        <Avatar
          className="bg-transparent "
          size="large"
          icon={<AntdIconWithSvgIcon iconName="Gift" />}
        />

        {showTitle && (
          <span className="text-[12px] text-blue-400 select-none">
            邀请好友
          </span>
        )}
      </Tooltip>
    </div>
  );
};
