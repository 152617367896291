import {
  UserConversationsQuery,
  useUserConversationsQuery,
} from '@clients/graphql-client';
import { services } from '@clients/rest-api-client';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Conversation = UserConversationsQuery['conversations'][0];

export const PDFTable = memo(() => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  const [{ data }, reexecuteQuery] = useUserConversationsQuery({
    variables: {
      limit,
      offset: (page - 1) * limit,
    },
    requestPolicy: 'network-only',
  });

  const columns = [
    {
      title: '会话名称',
      dataIndex: ['user_file', 'file_name'],
      width: '45%',
      render: (file_name: string, data: Conversation) => {
        return (
          <div className="overflow-hidden">
            <Tooltip title={file_name} placement="topLeft">
              <Button
                type="link"
                onClick={() => {
                  navigate(`/chat/${data.id}`);
                }}
              >
                {file_name}
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: '总页数',
      dataIndex: ['user_file', 'file', 'total_page'],
      width: '10%',
      render: (total_page?: number) => {
        return total_page ?? <span className="text-green-500">文件处理中</span>;
      },
    },
    {
      title: '文件大小',
      dataIndex: ['user_file', 'file', 'size'],
      width: '15%',
      render: (size: number) => {
        return `${(size / 1024 / 1024).toFixed(2)} MB`;
      },
    },
    {
      title: '上传时间',
      dataIndex: ['user_file', 'created_at'],
      width: '20%',
      render: (created_at: string) => {
        return dayjs(created_at).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '12%',
      render: (_: any, data: Conversation) => {
        return (
          <Popconfirm
            title="确定删除吗?"
            description="删除后无法恢复"
            onConfirm={async e => {
              e?.stopPropagation();
              await services.chatService.chatApiControllerCloseConversation(
                {
                  conversation_id: data.id,
                },
              );
              reexecuteQuery();
            }}
          >
            <Button type="primary" danger>
              删除
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className="w-full">
      <Table
        size="small"
        tableLayout="fixed"
        rowKey="id"
        columns={columns}
        dataSource={data?.conversations}
        bordered
        pagination={{
          pageSize: limit,
          total: data?.conversations_aggregate.aggregate?.count ?? 0,
          current: page,
          showSizeChanger: true,
          showTotal: total => `共 ${total} 条`,
          pageSizeOptions: ['10', '20', '50', '100'],
          onShowSizeChange: (current, size) => {
            const limit = size ?? 10;
            setLimit(limit);
            setPage(current);
          },
        }}
        onChange={pagination => {
          setLimit(pagination.pageSize ?? 10);
          setPage(pagination.current ?? 1);
        }}
      />
    </div>
  );
});
