import { ZLLMModelName, ZLLMModelName_ENUM } from '@chat/libs-types';
import { services } from '@clients/rest-api-client';
import { Button, Dropdown, Select } from 'antd';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleLoginRegisterModal } from '../auth/components/LoginRegister';
import authStore from '../auth/auth.store';
import { modelItems } from '../types/chat';

type SwitchModelProps = {
  className?: string;
};

export const SwitchModel = ({ className }: SwitchModelProps) => {
  const navigate = useNavigate();

  const onChange = useCallback(
    async (value: ZLLMModelName_ENUM) => {
      if (authStore.isGuest || !authStore.isLoggedIn) {
        toggleLoginRegisterModal(true);
        return;
      }

      if (value === ZLLMModelName.Enum['openai:gpt-4'] && !authStore.isVipUser) {
        navigate('/plan');
        return;
      }

      await services.userService.userApiControllerChangeLLMModel({
        ChangeLLMModelRequest: {
          model: value,
        },
      });
      await authStore.fetchUserProfile();
    },
    [navigate],
  );

  return (
    <Dropdown
      placement="topLeft"
      className={className}
      menu={{
        selectable: true,
        defaultSelectedKeys: [authStore.currentUser?.llm_model_name ?? ''],
        items: modelItems.map(r => {
          return {
            key: r.key,
            label: r.label,
            onClick: () => {
              onChange(r.key);
            },
          };
        }),
      }}
    >
      <Button size="small">
        {modelItems.find(r => authStore.currentUser?.llm_model_name === r.key)
          ?.label ?? 'GPT-3.5'}
      </Button>
    </Dropdown>
  );
};

export const SwitchModelSelect = ({ className }: SwitchModelProps) => {
  const navigate = useNavigate();

  const onChange = useCallback(
    async (value: ZLLMModelName_ENUM) => {
      if (authStore.isGuest || !authStore.isLoggedIn) {
        toggleLoginRegisterModal(true);
        return;
      }

      if (value === ZLLMModelName.Enum['openai:gpt-4'] && !authStore.isVipUser) {
        navigate('/plan');
        return;
      }

      await services.userService.userApiControllerChangeLLMModel({
        ChangeLLMModelRequest: {
          model: value,
        },
      });
      await authStore.fetchUserProfile();
    },
    [navigate],
  );

  return (
    <Select
      className={className}
      value={
        authStore.currentUser?.llm_model_name ??
        ZLLMModelName.Enum['openai:gpt-3.5']
      }
      onChange={value => {
        onChange(value as ZLLMModelName_ENUM);
      }}
      options={modelItems.map(r => {
        return {
          key: r.key,
          value: r.key,
          label: r.label,
        };
      })}
    ></Select>
  );
};
