import { Helper } from './doc/Helper';
import { Privacy } from './doc/Privacy';
import { Terms } from './doc/Terms';

export const Footer = () => {
  return (
    <div className="flex flex-col justify-center items-center text-[12px] text-[#00000066] mt-4 mb-4">
      <div className="gap-3 flex mb-2">
        <Helper>
          <div className="cursor-pointer">帮助</div>
        </Helper>
        <Privacy>
          <div className="cursor-pointer">隐私</div>
        </Privacy>
        <Terms>
          <div className="cursor-pointer">条款</div>
        </Terms>
      </div>
      <div>
        <div>© 2023 Chat With PDF 重庆南杉图科技有限公司</div>
        {/* <div>京ICP备2021000000号</div> */}
      </div>
    </div>
  );
};
