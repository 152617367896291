import { services } from '@clients/rest-api-client';
import { Button, Col, Form, Input, Row, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { assertIsError } from '../../utils/assert';
import { useQuery } from '../../utils/useQuery';
import authStore from '../auth.store';

type RegisterForm = {
  invite_code?: string;
  email: string;
  prefix: string;
  code: string;
  password: string;
  confirmPassword: string;
};

export const Register = ({ callback }: { callback?: () => void }) => {
  const location = useLocation();

  const [form] = Form.useForm<RegisterForm>();
  const email = Form.useWatch('email', form);

  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    if (countDown <= 0) {
      return;
    }
    const timer = setTimeout(() => {
      setCountDown(countDown - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countDown]);

  const [onSendCode] = useQuery(async () => {
    setCountDown(3);
    try {
      await services.authService.authApiControllerSendRegisterCaptchaByEmail(
        {
          SendRegisterCaptchaByEmailRequest: {
            email,
          },
        },
      );
    } catch (e) {
      setCountDown(0);
      assertIsError(e);
      message.warning(e.message);
    }
    return;
  }, [form, email]);

  const onFinish = useCallback(
    async (values: RegisterForm) => {
      try {
        await authStore.register({
          invite_code: values.invite_code,
          email: values.email,
          password: values.password,
          register_code: values.code,
        });
        form.resetFields();
        callback?.();
      } catch (e) {
        assertIsError(e);
        message.warning(e.message);
      }
    },
    [callback, form],
  );

  return (
    <div>
      <Form
        form={form}
        name="basic"
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 600 }}
        initialValues={{
          invite_code: location.state?.invite_code,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: '请输入Email' }]}
        >
          <Input type="email" placeholder="请输入Email" />
        </Form.Item>

        <Form.Item
          shouldUpdate
          help={
            <div className="my-2 text-red-400">* 请注意查收【垃圾信箱】</div>
          }
        >
          <Row gutter={8}>
            <Col span={17}>
              <Form.Item
                name="code"
                noStyle
                rules={[
                  {
                    required: true,
                    message: '请输入验证码',
                  },
                ]}
              >
                <Input placeholder="请输入验证码" />
              </Form.Item>
            </Col>
            <Col span={7} className="text-right">
              <Button
                className="w-full"
                disabled={countDown > 0 || !email}
                onClick={onSendCode}
              >
                {countDown === 0 ? '获取验证码' : countDown}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '请输入6-16位密码',
            },
            {
              pattern: /^.{6,16}$/,
              message: '请输入6-16位密码',
            },
          ]}
        >
          <Input.Password placeholder="请输入6-16位密码" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              validator: (rule, value, callback) => {
                if (value !== form.getFieldValue('password')) {
                  callback('两次输入密码不一致');
                }
                callback();
              },
            },
          ]}
        >
          <Input.Password placeholder="确认密码" />
        </Form.Item>
        <Form.Item
          name="invite_code"
          // rules={[{ required: true, message: '请输入您的邀请码' }]}
        >
          <Input type="text" placeholder="请输入您的邀请码" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" className="w-full" htmlType="submit">
            立即注册
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
