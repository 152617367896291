import { Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authStore from '../auth/auth.store';
import { setIntervalWithTimeout } from '../utils/utils';

const ProtectedRoute = (props: {
  component: React.FC;
  componentProps?: any;
}) => {
  const user = authStore.currentUser;
  const initialized = authStore.initialized;
  const navigate = useNavigate();

  useEffect(() => {
    const checkoutLogin = () => {
      if (!user && initialized) {
        navigate('/login');
        return;
      }
    };
    return setIntervalWithTimeout(() => checkoutLogin(), 1000);
  }, [initialized, navigate, user]);

  if (!user || !initialized) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin />
      </div>
    );
  }
  return <props.component {...props.componentProps} />;
};

export default ProtectedRoute;
