import { Avatar as AntdAvatar, Button, Popover, Slider } from 'antd';
import clsx from 'clsx';
import authStore from '../../auth/auth.store';
import { toggleLoginRegisterModal } from '../../auth/components/LoginRegister';
import { UserCenterModal, toggleUserCenterModal } from './UserCenter';

export const Avatar = () => {
  if (!authStore.isLoggedIn || authStore.isGuest) {
    return (
      <Button type="primary" onClick={() => toggleLoginRegisterModal(true)}>
        登录/注册
      </Button>
    );
  }

  return (
    <>
      <Popover
        content={<UserInfoPopover />}
        overlayClassName="user_info_popover"
        onOpenChange={async visible => {
          if (visible) {
            await authStore.fetchUserProfile();
          }
        }}
      >
        <AntdAvatar
          className={clsx('cursor-pointer select-none', {
            'bg-gray-300': authStore.user.avatar_url != null,
            'bg-[#f56a00]': authStore.user.avatar_url == null,
          })}
          src={authStore.user.avatar_url}
        >
          {(authStore.user.nickname ?? authStore.user.email)
            ?.substring(0, 1)
            .toUpperCase() ?? 'U'}
        </AntdAvatar>
      </Popover>
      <UserCenterModal />
    </>
  );
};

export type TokenUsage = {
  promptTokens?: number;
  completionTokens?: number;
  totalTokens?: number;
  totalCost?: number;
};

const UserInfoPopover = () => {
  const user = authStore.user;

  const question_count = user.user_files.reduce((acc, cur) => {
    const question_count = cur.conversations.reduce(
      (pre_conversation, cur_conversation) =>
        pre_conversation +
        (cur_conversation.chats_aggregate.aggregate?.count ?? 0),
      acc,
    );

    return question_count;
  }, 0);

  return (
    <div
      className={clsx(
        'flex flex-col justify-center items-center gap-2 py-4',
        'w-[240px]',
      )}
    >
      <div
        className={clsx(
          'w-full px-4 pb-2 text-[12px]',
          'box-border border-0 border-b-1 border-[#00000033] border-solid',
          'flex justify-between gap-4',
        )}
      >
        <div className="flex-1 break-words overflow-hidden">
          {user.nickname ?? user.email}
        </div>
        <Button
          size="small"
          className="!text-[12px]"
          onClick={() => toggleUserCenterModal(true)}
        >
          个人中心
        </Button>
      </div>
      <div className="w-full px-4 box-border text-[12px] flex flex-col justify-start items-start gap-1">
        <div className="w-full">
          <div
            className={clsx(
              'py-[1px] px-[8px] mb-3',
              'rounded border-2 border-dotted border-gray-300 cursor-default',
              'text-gray-400 text-center text-[12px]',
            )}
          >
            {authStore.isVipUser ? 'Pro 版' : '免费版'}
          </div>
        </div>
        <div className="flex justify-start items-start">
          <div className="text-[#00000073] text-left">文件数：</div>
          <div className="flex-1 break-words overflow-hidden">
            {user.user_files.length}
          </div>
        </div>
        <div className="flex justify-start items-start">
          <div className="text-[#00000073] text-right">提问数：</div>
          <div className="flex-1 break-words overflow-hidden">
            {question_count}
          </div>
        </div>
        {authStore.isVipUser && (
          <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-start items-start">
              <div className="text-[#00000073] text-right">Token消耗情况：</div>
              <div className="flex-1 break-words overflow-hidden">
                {user.used_point ?? 0} / {user.total_point ?? 0}
              </div>
            </div>
            <div className="flex-1 w-full flex justify-center items-baseline gap-2">
              <Slider
                className="flex-1"
                tooltip={{
                  formatter: (value?: number) => `${value ?? 0}%`,
                }}
                marks={{
                  0: <span className="text-[12px]">0%</span>,
                  35: <span className="text-[12px]">35%</span>,
                  65: <span className="text-[12px]">65%</span>,
                  100: <span className="text-[12px]">100%</span>,
                }}
                value={((user.used_point ?? 0) / (user.total_point ?? 0)) * 100}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
