import { Button } from 'antd';
import { ModalButton } from './ModalButton';

type HelperProps = {
  buttonProps?: React.ComponentProps<typeof Button>;
  children?: React.ReactNode;
};

export const Helper = ({ buttonProps, children }: HelperProps) => {
  return (
    <ModalButton
      modalProps={{
        title: <div className="text-center">帮助手册</div>,
      }}
      modalContent={<HelpDoc />}
    >
      {children ?? <Button {...buttonProps}>帮助</Button>}
    </ModalButton>
  );
};

export const HelpDoc = () => {
  return (
    <>
      <div className="mt-4 mb-6">
        <div className="font-bold	">我的文件安全吗？</div>
        未经您的允许，我们不会与任何人共享您的文件，您可以随时删除您的文件。
      </div>
      <div className="mb-6">
        <div className="font-bold	">如何删除我的文件？</div>
        在聊天界面，您可以点击“删除文档”按钮来删除当前文档，在首页，您可以点击历史文档右侧的“删除”按钮来删除对应文件。
      </div>
      <div className="mb-6">
        <div className="font-bold	">PDF 文件中的图像和表格可以被解析吗？</div>
        我们暂时无法读取
        PDF文档中的图像。在处理PDF文档中的表格时，行和列的信息关联可能会出现问题。
      </div>
      <div className="mb-6">
        <div className="font-bold	">我在哪里可以提出建议或请求帮助？</div>
        请联系
        <a className="ml-2" href="mailto:help@chatdoc.pro">
          help@chatdoc.pro
        </a>
      </div>
    </>
  );
};
