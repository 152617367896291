import { CloudUploadOutlined } from '@ant-design/icons';
import { useUserConversationsQuery } from '@clients/graphql-client';
import {
  Button,
  List,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadFile } from '../../../utils/uploadfile';
import { useObservable } from '../../../utils/useObservable';
import { borderRound } from '../ChatPage';
import { sideBarOpen$ } from './Header';

export const HistoryDoc = () => {
  const navigate = useNavigate();
  const { conversation_id } = useParams();

  const [{ data }, reexecuteQuery] = useUserConversationsQuery({
    requestPolicy: 'network-only',
  });

  const sideBarOpen = useObservable(() => sideBarOpen$, 'sideBarState');

  return (
    <div
      className={clsx(
        borderRound,
        'w-32 h-full p-2 bg-[rgba(0,0,0,0.02)] box-border overflow-hidden',
        'flex flex-col gap-2',
        'transition-[width, padding] duration-150 ease-in-out',
        {
          '!w-0 !border-0 !p-0': !sideBarOpen,
        },
      )}
    >
      <div
        className={clsx('transition-transform origin-left', {
          '!scale-x-0': !sideBarOpen,
        })}
      >
        <UploadFileButton onRefresh={reexecuteQuery} />
      </div>
      <List
        className={clsx('transition-transform origin-left', {
          '!scale-x-0': !sideBarOpen,
        })}
        dataSource={data?.conversations}
        renderItem={item => (
          <List.Item
            className={clsx('!p-0', {
              'bg-[#e6f4ff]': item.id === conversation_id,
            })}
          >
            <Tooltip title={item.user_file?.file_name} placement="right">
              <Button
                type="link"
                onClick={() => {
                  navigate(`/chat/${item.id}`);
                }}
                className={clsx('w-full text-[12px]', {
                  'font-bold': item.id === conversation_id,
                })}
              >
                <span className="overflow-hidden whitespace-nowrap text-ellipsis block w-full text-left">
                  {item.user_file?.file_name}
                </span>
              </Button>
            </Tooltip>
          </List.Item>
        )}
      />
    </div>
  );
};

const UploadFileButton = ({ onRefresh }: { onRefresh: () => void }) => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<Array<UploadFile>>();

  const props: UploadProps = useMemo(() => {
    return {
      accept: '.pdf',
      onRemove: _ => {
        setFileList([]);
      },
      customRequest: async info => {
        const file = info.file as {
          uid: string;
          name: string;
        };

        setFileList([
          {
            uid: file.uid,
            name: file.name,
            status: 'uploading',
          },
        ]);

        const result = await uploadFile(file);
        if (result.status === 'error') {
          message.warning(result.message);
          return;
        }

        setFileList([
          {
            uid: file.uid,
            name: file.name,
            status: result.status,
          },
        ]);
        onRefresh();
        navigate(`/chat/${result.conversation_id}`);
      },
    };
  }, [navigate, onRefresh]);
  return (
    <div className="text-center">
      <Upload className="cursor-pointer" {...props} fileList={fileList}>
        <Button
          size="small"
          className="!text-[12px] mb-2"
          icon={<CloudUploadOutlined />}
        >
          上传文件
        </Button>
      </Upload>
      <div className="text-[12px] text-gray-400">支持扩展名 .pdf</div>
    </div>
  );
};
