import { env } from '@libs/env';
import * as Sentry from '@sentry/react';
import version from '../assets/version.json';
import { __DEV__ } from './utils/utils';

export const setupCrashTracking = () => {
  if (__DEV__) {
    return;
  }

  Sentry.init({
    environment: env.NODE_ENV || 'unknown',
    release: version.current,
    dsn: 'https://aa0352130f13cbde61b5fcb18bc37640@o4506094343421952.ingest.sentry.io/4506094347157504',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export function setCrashUser(uid?: string) {
  Sentry.setUser(uid != null ? { id: uid } : null);
}

export const captureException = (err: any) => {
  Sentry.captureException(err);
};

export const captureCrashTrackingEvent = (event: Sentry.Event) => {
  Sentry.captureEvent(event);
};
