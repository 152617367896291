import { Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoginRegisterModal } from '../../auth/components/LoginRegister';
import authStore from '../../auth/auth.store';
import { GenInviteCodeModal } from '../../user/components/Invite';
import ChatStore, { ChatStoreContext } from './chat.store';
import { Header } from './components/Header';
import { HistoryDoc } from './components/HistoryDoc';
import { PDFViewer } from './components/PDFViewer';
import { Chat } from './components/chat/Chat';

export const borderRound = 'border border-gray-300 rounded border-solid';

export const ChatPage = () => {
  const navigate = useNavigate();
  const { conversation_id } = useParams();
  const isInit = useRef<string>();

  const [store, setStore] = useState<ChatStore>();

  useEffect(() => {
    if (conversation_id && isInit.current !== conversation_id) {
      store?.unsubscribe?.();
      const newStore = new ChatStore(conversation_id);
      setStore(newStore);
      isInit.current = conversation_id;
    }
    return () => {
      store?.unsubscribe?.();
    };
  }, [store, conversation_id]);

  return (
    <>
      <Header />
      <div className="flex-1 flex p-4 gap-2 overflow-hidden">
        {authStore.isRealUser && <HistoryDoc />}
        {store?.currentConversation == null ? (
          <div className="flex-1 flex justify-center items-center">
            <Spin size="large" />
          </div>
        ) : (
          <ChatStoreContext.Provider value={store}>
            <div className="flex-1 overflow-hidden">
              <PDFViewer />
            </div>
            <div className="flex-1 overflow-hidden">
              <Chat />
            </div>
          </ChatStoreContext.Provider>
        )}
      </div>
      <LoginRegisterModal
        callback={() => {
          navigate('/');
        }}
      />
      <GenInviteCodeModal />
    </>
  );
};
