/* tslint:disable */
/* eslint-disable */
/**
 * AI Chat PDF API
 * AI Chat PDF API definitions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeFileUploadedRequest,
  ChangeFileUploadedResponse,
  GenerateFilePresignedUrlRequest,
  GenerateFilePresignedUrlResponse,
  GetFileUrlResponse,
} from '../models';

export interface FileApiControllerChangeFileUploadedRequest {
    ChangeFileUploadedRequest: ChangeFileUploadedRequest;
}

export interface FileApiControllerDeleteFileRequest {
    user_file_id: string;
}

export interface FileApiControllerGenerateFilePresignedUrlRequest {
    GenerateFilePresignedUrlRequest: GenerateFilePresignedUrlRequest;
}

export interface FileApiControllerGetFileUrlRequest {
    user_file_id: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * change file status 修改文件状态为uploaded或failed
     */
    async fileApiControllerChangeFileUploadedRaw(requestParameters: FileApiControllerChangeFileUploadedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeFileUploadedResponse>> {
        if (requestParameters.ChangeFileUploadedRequest === null || requestParameters.ChangeFileUploadedRequest === undefined) {
            throw new runtime.RequiredError('ChangeFileUploadedRequest','Required parameter requestParameters.ChangeFileUploadedRequest was null or undefined when calling fileApiControllerChangeFileUploaded.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/files/status:uploaded`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ChangeFileUploadedRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * change file status 修改文件状态为uploaded或failed
     */
    async fileApiControllerChangeFileUploaded(requestParameters: FileApiControllerChangeFileUploadedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeFileUploadedResponse> {
        const response = await this.fileApiControllerChangeFileUploadedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete file 标记删除文件
     */
    async fileApiControllerDeleteFileRaw(requestParameters: FileApiControllerDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.user_file_id === null || requestParameters.user_file_id === undefined) {
            throw new runtime.RequiredError('user_file_id','Required parameter requestParameters.user_file_id was null or undefined when calling fileApiControllerDeleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/files/{user_file_id}`.replace(`{${"user_file_id"}}`, encodeURIComponent(String(requestParameters.user_file_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete file 标记删除文件
     */
    async fileApiControllerDeleteFile(requestParameters: FileApiControllerDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fileApiControllerDeleteFileRaw(requestParameters, initOverrides);
    }

    /**
     * generate pre signed url 生成预签名上传文件URL
     */
    async fileApiControllerGenerateFilePresignedUrlRaw(requestParameters: FileApiControllerGenerateFilePresignedUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateFilePresignedUrlResponse>> {
        if (requestParameters.GenerateFilePresignedUrlRequest === null || requestParameters.GenerateFilePresignedUrlRequest === undefined) {
            throw new runtime.RequiredError('GenerateFilePresignedUrlRequest','Required parameter requestParameters.GenerateFilePresignedUrlRequest was null or undefined when calling fileApiControllerGenerateFilePresignedUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/files/pre-signed-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.GenerateFilePresignedUrlRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * generate pre signed url 生成预签名上传文件URL
     */
    async fileApiControllerGenerateFilePresignedUrl(requestParameters: FileApiControllerGenerateFilePresignedUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateFilePresignedUrlResponse> {
        const response = await this.fileApiControllerGenerateFilePresignedUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get file download url 获取文件下载URL
     */
    async fileApiControllerGetFileUrlRaw(requestParameters: FileApiControllerGetFileUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFileUrlResponse>> {
        if (requestParameters.user_file_id === null || requestParameters.user_file_id === undefined) {
            throw new runtime.RequiredError('user_file_id','Required parameter requestParameters.user_file_id was null or undefined when calling fileApiControllerGetFileUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/files/{user_file_id}/download-url`.replace(`{${"user_file_id"}}`, encodeURIComponent(String(requestParameters.user_file_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * get file download url 获取文件下载URL
     */
    async fileApiControllerGetFileUrl(requestParameters: FileApiControllerGetFileUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFileUrlResponse> {
        const response = await this.fileApiControllerGetFileUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
