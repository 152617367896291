import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UNAVAILABLE_ANSWER_SCHEMA } from '@chat/libs-types';
import { Button, Skeleton, Tooltip } from 'antd';
import clsx from 'clsx';
import { useContext, useLayoutEffect, useRef } from 'react';
import { borderRound } from '../../ChatPage';
import { Chat } from '../../chat.service';
import { ChatStoreContext, useSendQuestion } from '../../chat.store';

export const ChatContent = () => {
  const store = useContext(ChatStoreContext);
  const divRef = useRef<HTMLDivElement>(null);

  const [sendQuestion] = useSendQuestion();

  useLayoutEffect(() => {
    if (divRef.current != null) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [store.chats, store.awaitBotResponse]);

  return (
    <div ref={divRef} className="flex-1 overflow-auto">
      <div className="gap-4">
        <div className={`p-3 mb-4 ${borderRound}`}>
          <div>
            <div>您好，欢迎阅读这份文档，你可以：</div>
            <div className="mt-4 text-sm">
              <div>→ 为我设定一个身份，以便我更好的和你沟通</div>
              <div>→ 向我提问，我们聊聊这个文档讲了什么</div>
              <div>→ 告诉我，你会用这份文档的信息去做什么</div>
            </div>
          </div>
          {(store.fileInfo?.file?.questions ?? []).length > 0 && (
            <>
              <div className="mt-8">
                <div>这里有一些示例问题，点击即可获得回复</div>
                <div className="flex flex-col justify-start justify-items-start text-sm">
                  {(store.fileInfo?.file?.questions ?? []).map((r, i) => {
                    return (
                      <div key={i}>
                        <Button
                          type="link"
                          className="whitespace-pre-line text-left"
                          onClick={() => {
                            sendQuestion(r.question);
                          }}
                        >
                          {i + 1}. {r.question}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-4">
                <div>希望我和这份文档都对你有帮助 :）</div>
              </div>
            </>
          )}
        </div>
        {(store.chats ?? []).map((item, index) => {
          return (
            <div key={index}>
              <SendMessageView {...item} />
              {(item.answer || item.failure_reason) && (
                <ReceiveMessageView {...item} />
              )}
            </div>
          );
        })}
        {store.awaitBotResponse && <AwaitResponseView />}
      </div>
    </div>
  );
};

const SendMessageView = ({ original_question, failure_reason, id }: Chat) => {
  const store = useContext(ChatStoreContext);
  return (
    <div className="flex justify-end mb-2 items-center">
      <div
        className={clsx(
          borderRound,
          `px-3 py-2 text-white whitespace-pre-wrap max-w-[75%] bg-[#1677ff]`,
        )}
      >
        {original_question}
      </div>
      {failure_reason != null && (
        <div className="ml-2">
          <Tooltip title={failure_reason}>
            <ExclamationCircleOutlined
              className="text-red-500 cursor-pointer"
              onClick={async () => {
                if (id.startsWith('mock-')) {
                  await store.sendMessage(original_question);
                } else {
                  await store.retryMessage(id);
                }
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const ReceiveMessageView = ({ answer, refs, failure_reason }: Chat) => {
  const store = useContext(ChatStoreContext);

  const hasUnavailableAnswer = answer
    ? UNAVAILABLE_ANSWER_SCHEMA.safeParse(answer).success
    : false;

  return (
    <div className="flex flex-col justify-start gap-2 mb-2 max-w-[75%]">
      <div>
        {/* <Markdown
          className={`${borderRound} px-3 py-2`}
        >
          {message}
        </Markdown> */}
        <div
          className={clsx(
            borderRound,
            `px-3 py-2 whitespace-pre-wrap inline-block`,
            {
              'text-red-500 border-red-500': failure_reason != null,
            },
          )}
        >
          {failure_reason ?? answer}
        </div>
      </div>
      {!hasUnavailableAnswer &&
        failure_reason == null &&
        (refs?.length ?? 0) > 0 && (
          <div
            className={clsx(
              'flex justify-start items-start',
              borderRound,
              'border-gray-200',
              'px-3 py-1 whitespace-pre-wrap text-gray-400 text-[12px]',
              'overflow-hidden',
            )}
          >
            <div className="w-[60px]">引用来源：</div>
            <div
              className={clsx(
                'flex-1 flex justify-start items-center flex-wrap',
                '',
              )}
            >
              {refs?.map((r, i) => {
                return (
                  <div
                    key={i}
                    className="text-gray-400 cursor-pointer hover:text-gray-900"
                    onClick={() => store.currentPage$.next(r.ref_page - 1)}
                  >
                    <span className="underline">{r.ref_page}</span>
                    {i < refs.length - 1 && <span>，</span>}
                  </div>
                );
              })}
            </div>
          </div>
        )}
    </div>
  );
};

const AwaitResponseView = () => {
  return (
    <div className="flex justify-start mb-2">
      <div className={`${borderRound} px-3 py-2 whitespace-pre-wrap w-3/4`}>
        <Skeleton.Input active />
      </div>
    </div>
  );
};
