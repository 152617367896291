import { Button } from 'antd';
import { ModalButton } from './doc/ModalButton';
import { AntdIconWithSvgIcon } from './icon';

type PrivacyProps = {
  buttonProps?: React.ComponentProps<typeof Button>;
  children?: React.ReactNode;
};

export const ChangeLog = ({ buttonProps, children }: PrivacyProps) => {
  return (
    <ModalButton
      modalContent={<ChangeLogDoc />}
      modalProps={{
        footer: null,
      }}
    >
      {children ?? (
        <Button type="link" {...buttonProps}>
          <div className="flex justify-center items-center gap-2">
            <AntdIconWithSvgIcon className="text-[25px]" iconName="Rocket" />
            <span className="border-0 border-b border-solid px-2">
              更新日志
            </span>
          </div>
        </Button>
      )}
    </ModalButton>
  );
};

const ChangeLogDoc = ({
  setIsModalOpen,
}: {
  setIsModalOpen?: (v: boolean) => void;
}) => {
  return (
    <div className="pt-4 px-4">
      <div className="text-center">
        <AntdIconWithSvgIcon className="text-[120px]" iconName="Rocket" />
        <div className="text-lg font-bold">发现新版本</div>
        <div className="text-gray-500">V1.3.0</div>
      </div>
      <div>
        <div className="font-bold">更新内容</div>
        <div>
          <ol className="text-gray-500">
            <li>支持GPT4.0</li>
            <li>支持查看历史会话</li>
            <li>更新了使用指南</li>
            <li>修复一些已知的问题</li>
          </ol>
        </div>
      </div>
      <div className="text-center">
        <Button
          className="w-36"
          type="primary"
          onClick={() => setIsModalOpen?.(false)}
        >
          知道了
        </Button>
      </div>
    </div>
  );
};
