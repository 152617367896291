import { services } from '@clients/rest-api-client';
import { Button, Card, Table, message } from 'antd';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AntdIconWithSvgIcon } from '../../../components/icon';
import { assertIsError } from '../../../utils/assert';

const dataSource = [
  {
    id: 1,
    title: '文件上传限制',
    unlogin: '1个/天',
    free: '3个/天',
    pro: '200个/月',
  },
  {
    id: 2,
    title: '提问限制',
    unlogin: '10个/天',
    free: '20个/天',
    pro: '100个/天',
  },
  {
    id: 3,
    title: '单个文件页数限制',
    unlogin: '20页',
    free: '30页',
    pro: '-',
  },
  {
    id: 4,
    title: '单个文件最大文件尺寸',
    unlogin: '3M',
    free: '5M',
    pro: '30M',
  },
  {
    id: 5,
    title: 'GTP 4.0 使用限制',
    unlogin: '❌',
    free: '❌',
    pro: '✅',
  },
];

export const PlanTable = memo(() => {
  const navigate = useNavigate();
  const upgradeVip = useCallback(async () => {
    try {
      const res = await services.payService.payApiControllerCreateOrder();
      window.open(res.orderUrl);
      navigate('/');
    } catch (e) {
      assertIsError(e);
      message.error(e.message);
    }
  }, [navigate]);

  const columns = [
    {
      dataIndex: 'title',
      width: '45%',
      render: (value: string) => {
        return <div className="text-[#108EE9]">{value}</div>;
      },
    },
    {
      title: '未登录',
      dataIndex: 'unlogin',
      width: '10%',
      render: (value?: string) => {
        return value;
      },
    },
    {
      title: '免费',
      dataIndex: 'free',
      width: '15%',
      render: (value: string) => {
        return value;
      },
    },
    {
      title: 'Pro 版（￥39.9/月）',
      dataIndex: 'pro',
      width: '20%',
      render: (value: string) => {
        return <div className="text-[#108EE9]">{value}</div>;
      },
    },
  ];

  return (
    <div className="w-full flex flex-col gap-8">
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Card title={<div className="text-center text-[#1890FF]">温馨提示</div>}>
        <p>
          * GPT 3.5 和 GPT 4.0 的 token 费用不同，4.0 的价格约为 3.5 的 30 倍
        </p>
        <p>
          * input 和 output 消耗 token 量和 token
          单价不同，使用同一模型时，output 消耗的 token 是 input 的 3 倍
        </p>
        <p>
          * 升级后，总消耗额度为 6,000,000（可换算为 token 使用
          ），如有更大量的使用需求，请于我们联系
        </p>
      </Card>
      <div className="flex justify-center">
        <div className="flex flex-col justify-start items-start gap-4">
          <div>
            <Button type="primary" className="w-[200px]" onClick={upgradeVip}>
              去升级
            </Button>
          </div>

          <div className="flex justify-center gap-2 text-sm">
            <div>现有支付方式</div>
            <AntdIconWithSvgIcon iconName="Alipay" />
          </div>
        </div>
      </div>
    </div>
  );
});
