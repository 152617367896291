/* tslint:disable */
/* eslint-disable */
/**
 * AI Chat PDF API
 * AI Chat PDF API definitions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GenPublicImageUploadURLRequest,
  GenPublicImageUploadURLResponse,
} from '../models';

export interface ImageApiControllerGenerateImagePresignedUrlRequest {
    GenPublicImageUploadURLRequest: GenPublicImageUploadURLRequest;
}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     * generate pre signed url for image 生成预签名上传图片URL
     */
    async imageApiControllerGenerateImagePresignedUrlRaw(requestParameters: ImageApiControllerGenerateImagePresignedUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenPublicImageUploadURLResponse>> {
        if (requestParameters.GenPublicImageUploadURLRequest === null || requestParameters.GenPublicImageUploadURLRequest === undefined) {
            throw new runtime.RequiredError('GenPublicImageUploadURLRequest','Required parameter requestParameters.GenPublicImageUploadURLRequest was null or undefined when calling imageApiControllerGenerateImagePresignedUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/images/pre-signed-url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.GenPublicImageUploadURLRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * generate pre signed url for image 生成预签名上传图片URL
     */
    async imageApiControllerGenerateImagePresignedUrl(requestParameters: ImageApiControllerGenerateImagePresignedUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenPublicImageUploadURLResponse> {
        const response = await this.imageApiControllerGenerateImagePresignedUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
