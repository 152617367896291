import { HomeOutlined } from '@ant-design/icons';
import { Avatar as AntdAvatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import authStore from '../../../auth/auth.store';
import { Feedback } from '../../../components/button/Feedback';
import { Guide } from '../../../components/button/Guide';
import { InviteButton } from '../../../components/button/InviteButton';
import { PlanButton } from '../../../components/button/PlanButton';
import { AntdIconWithSvgIcon } from '../../../components/icon';
import { Avatar } from '../../../user/components/Avatar';
import { useObservable } from '../../../utils/useObservable';

export const sideBarOpen$ = new BehaviorSubject(false);

export const toggleSideBarOpenSubject = (isOpen: boolean) => {
  sideBarOpen$.next(isOpen);
};

export const Header = () => {
  const navigate = useNavigate();

  const sideBarOpen = useObservable(() => sideBarOpen$, 'sideBarOpen');

  return (
    <div
      className="flex justify-between items-center px-4 border-0 border-b border-gray-300 border-solid"
      style={{
        height: '50px',
      }}
    >
      <div className="flex justify-start items-center gap-4">
        {authStore.isRealUser && (
          <AntdAvatar
            className="bg-transparent cursor-pointer text-blue-500 hover:text-blue-300"
            size="large"
            icon={
              sideBarOpen ? (
                <AntdIconWithSvgIcon iconName="Collapse" />
              ) : (
                <AntdIconWithSvgIcon iconName="Expand" />
              )
            }
            onClick={() => {
              toggleSideBarOpenSubject(!sideBarOpen);
            }}
          />
        )}
        <AntdAvatar
          className="bg-transparent cursor-pointer text-blue-500 hover:text-blue-300"
          size="large"
          icon={<HomeOutlined />}
          onClick={() => navigate('/')}
        />
      </div>
      <div className="flex-1 flex justify-end items-center gap-4">
        <div className="flex-1 flex justify-end items-center gap-2">
          <Guide />
          {!authStore.isVipUser && <PlanButton />}
          <InviteButton />
          <Feedback />
        </div>
        <Avatar />
      </div>
    </div>
  );
};
