import {
  ConversationSubscription,
  ConversationSubscriptionVariables,
} from '@clients/graphql-client';
import { message } from 'antd';
import { gql } from 'urql';
import graphqlClient from '../../utils/graphqlClient';

export type Conversation = NonNullable<
  ConversationSubscription['conversations_by_pk']
>;
export type Chat = Conversation['chats'][0];
export type UserFile = Conversation['user_file'];

export const fetchConversation = async (
  conversation_id: string,
  subscribe?: (v: Conversation) => void,
): Promise<() => void> => {
  const result = graphqlClient.urqlClient
    .subscription<ConversationSubscription, ConversationSubscriptionVariables>(
      gql`
        subscription conversation($conversation_id: uuid!) {
          conversations_by_pk(id: $conversation_id) {
            id
            chats(
              order_by: { created_at: asc }
              where: {
                deleted_at: { _is_null: true }
                type: { _neq: question }
              }
            ) {
              id
              original_question
              answer
              type
              failure_reason
              refs(order_by: { distance: desc }) {
                ref_page
                distance
              }
            }
            user_file {
              file_name
              file {
                url
                total_page
                token_usage
                language
                embedding_usage
                failure_reason
                questions(limit: 3, order_by: { created_at: asc }) {
                  question
                }
              }
            }
          }
        }
      `,
      {
        conversation_id,
      },
    )
    .subscribe(result => {
      if (result.error) {
        message.warning(result.error.message);
        return;
      }
      if (!result.data || result.data.conversations_by_pk == null) {
        message.warning('会话未初始化，请稍后再试');
        return;
      }
      subscribe?.(result.data.conversations_by_pk);
    });

  return result.unsubscribe;
};
