import { env } from '@libs/env';

export const __DEV__ = env.NODE_ENV === 'development';

export function setIntervalWithTimeout(
  action: () => void,
  duration: number,
  clearTime?: () => boolean,
) {
  let timer = setTimeout(function repeat() {
    action();
    if (clearTime?.()) {
      return;
    }
    timer = setTimeout(repeat, duration);
  }, duration);
  return () => clearTimeout(timer);
}

export const sleep = (duration: number) =>
  new Promise(res => setTimeout(res, duration));

export async function retry<T>(
  action: () => Promise<T>,
  ifMatch: (error: Error) => boolean,
  backoff: number[],
): Promise<T> {
  try {
    return await action();
  } catch (e: any) {
    if (!ifMatch(e) || backoff.length === 0) {
      throw e;
    } else {
      console.error(e);
      const [duration, ...rest] = backoff;
      await sleep(duration);
      return retry(action, ifMatch, rest);
    }
  }
}

export async function calculatePDFHash(file: File) {
  const fileBuffer = await readFileAsArrayBuffer(file);
  if (fileBuffer == null) {
    throw new Error('file buffer is null');
  }
  const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);
  return bufferToHex(hashBuffer);
}

function readFileAsArrayBuffer(file: File) {
  return new Promise<ArrayBuffer | null | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event =>
      resolve(event.target?.result as ArrayBuffer | null | undefined);
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

function bufferToHex(buffer: ArrayBuffer) {
  return Array.from(new Uint8Array(buffer))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
}

export function copyText(text: string) {
  if (typeof navigator.clipboard == 'undefined') {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    return new Promise<void>((resolve, reject) => {
      try {
        const successful = document.execCommand('copy');
        successful ? resolve() : reject('failed');
      } catch (err) {
        reject(err);
      } finally {
        document.body.removeChild(textArea);
      }
    });
  }
  return navigator.clipboard.writeText(text);
}
