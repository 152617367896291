import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

const projectENV = createEnv({
  clientPrefix: '',
  client: {
    projectName: z.string().optional(),
    API_ENDPOINT: z.string(),
    NODE_ENV: z.string(),
  },
  runtimeEnv: process.env,
});

const hasuraENV = createEnv({
  clientPrefix: '',
  client: {
    HASURA_HOST: z.string(),
    HASURA_WS_HOST: z.string(),
  },
  runtimeEnv: process.env,
});

export const allENV = {
  ...projectENV,
  ...hasuraENV,
};

type ENV = typeof allENV;

export const chatfileENV = allENV;

export const env = (() => {
  const projectName = projectENV.projectName;

  if (!projectName) {
    return allENV;
  }

  switch (projectName) {
    case 'chatfile':
      return chatfileENV;
    default:
      throw new Error(`Unknown project name ${projectENV.projectName}`);
  }
})() as ENV;
