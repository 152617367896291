import { UserByIdQuery, UserByIdQueryVariables } from '@clients/graphql-client';
import {
  LoginByPasswordRequest,
  RegisterByEmailRequest,
  services,
} from '@clients/rest-api-client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { gql } from 'urql';
import graphqlClient from '../utils/graphqlClient';
import { retry } from '../utils/utils';

const fpPromise = FingerprintJS.load();

export const guestLogin = async (): Promise<void> => {
  const fp = await (await fpPromise).get();
  const result = await services.authService.authApiControllerGuestLogin({
    GuestLoginRequest: {
      device_id: fp.visitorId,
    },
  });
  localStorage.setItem('token', result.token);
  localStorage.setItem('refresh_token', result.refresh_token);
};

export const register = async ({
  email,
  password,
  register_code,
  invite_code,
}: RegisterByEmailRequest) => {
  const fp = await (await fpPromise).get();
  const result =
    await services.authService.authApiControllerRegisterByEmail({
      RegisterByEmailRequest: {
        device_id: fp.visitorId,
        email,
        register_code,
        invite_code: invite_code?.trim(),
        password,
      },
    });
  localStorage.setItem('token', result.token);
  localStorage.setItem('refresh_token', result.refresh_token);
};

export const login = async (data: LoginByPasswordRequest) => {
  const result =
    await services.authService.authApiControllerLoginByPassword({
      LoginByPasswordRequest: data,
    });
  localStorage.setItem('token', result.token);
  localStorage.setItem('refresh_token', result.refresh_token);
};

export const mockUserLogin = async (email: string) => {
  const result =
    await services.authService.authApiControllerMockUserLoginRequest({
      MockUserLoginRequest: {
        email,
      },
    });
  localStorage.setItem('token', result.token);
  localStorage.setItem('refresh_token', result.refresh_token);
};

export const refreshToken = async () => {
  const refreshTokenWithFetch = async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const refresh_token = localStorage.getItem('refresh_token')!;
    const result = await services.authService.authApiControllerRefreshToken(
      {
        RefreshTokenRequest: {
          refresh_token,
        },
      },
    );
    return result;
  };
  const result = await retry(
    () => refreshTokenWithFetch(),
    e => !e.message.includes('Invalid or expired'),
    [3000],
  );
  localStorage.setItem('token', result.token);
  localStorage.setItem('refresh_token', result.refresh_token);
};

export const fetchUserProfile = async (
  id: string,
): Promise<NonNullable<UserByIdQuery['users_by_pk']>> => {
  return graphqlClient.urqlClient
    .query<UserByIdQuery, UserByIdQueryVariables>(
      gql`
        query userById($id: uuid!) {
          users_by_pk(id: $id) {
            id
            username
            email
            phone_number
            role
            device_id
            avatar_url
            nickname
            default_language
            llm_model_name
            total_point
            used_point
            vip_level

            user_files {
              conversations {
                chats_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
          }
        }
      `,
      {
        id,
      },
      {
        requestPolicy: 'network-only',
      },
    )
    .toPromise()
    .then(result => {
      if (result.error) {
        throw result.error;
      }
      if (!result.data || result.data.users_by_pk == null) {
        throw new Error('User not found');
      }
      return result.data.users_by_pk;
    });
};
