import { memo, useContext, useEffect } from 'react';
import { ChatStoreContext } from '../chat.store';

// Import styles
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { borderRound } from '../ChatPage';

export const PDFViewer = () => {
  const store = useContext(ChatStoreContext);

  return (
    <div className="flex flex-col gap-2 h-full box-border">
      <div className={`p-2 ${borderRound} text-ellipsis overflow-hidden`}>
        {store.fileInfo?.file_name}
      </div>
      <div className={`flex-1 p-2 ${borderRound} overflow-auto`}>
        {store.fileInfo?.file?.url && (
          <ViewerWithToolbar fileVIewUrl={store.fileInfo?.file?.url} />
        )}
      </div>
    </div>
  );
};

export const ViewerWithToolbar = memo(
  ({ fileVIewUrl }: { fileVIewUrl: string }) => {
    const store = useContext(ChatStoreContext);
    const pageNavigationPluginInstance = pageNavigationPlugin();

    useEffect(() => {
      store.currentPage$.subscribe(page => {
        pageNavigationPluginInstance.jumpToPage(page);
      });
    }, [pageNavigationPluginInstance, store.currentPage$]);
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileVIewUrl}
          plugins={[defaultLayoutPlugin(), pageNavigationPluginInstance]}
          characterMap={{
            url: 'assets/bcmaps/',
            isCompressed: true,
          }}
        />
      </Worker>
    );
  },
);
