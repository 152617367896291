import { message } from 'antd';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import authStore from './auth/auth.store';
import { assertIsError } from './utils/assert';

export const Root = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const email = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('email');
  }, [search]);

  useEffect(() => {
    (async () => {
      if (email != null) {
        try {
          await authStore.mockUserLogin(email);
        } catch (error) {
          assertIsError(error);
          message.warning(error.message);
        } finally {
          navigate('/');
        }
      }
    })();
  }, [email, navigate]);

  if (email) {
    return;
  }

  return (
    <div className="flex flex-col w-screen h-screen overflow-hidden bg-white">
      <Outlet />
    </div>
  );
};
