import { Divider } from 'antd';
import clsx from 'clsx';
import authStore from '../../auth/auth.store';
import { LoginRegisterModal } from '../../auth/components/LoginRegister';
import { Layout } from '../../components/Layout';
import { Feedback } from '../../components/button/Feedback';
import { InviteButton } from '../../components/button/InviteButton';
import { PlanButton } from '../../components/button/PlanButton';
import { GenInviteCodeModal } from '../../user/components/Invite';
import { PDFTable } from './components/PDFTable';
import { UploadPDF } from './components/Upload';

export const UploadPage = () => {
  return (
    <Layout>
      <div className="flex-1 flex flex-col justify-start items-center w-4/5 mx-auto box-border">
        <h1 className="text-[#1890FF]">Chat with PDF</h1>
        <UploadPDF />
        <div className="flex flex-col items-center w-full mt-10">
          <Divider className="flex-1" dashed>
            历史记录
          </Divider>

          {!authStore.isLoggedIn || authStore.isGuest ? (
            <div className="text-gray-400">登录后即可查看</div>
          ) : (
            <PDFTable />
          )}
          <LoginRegisterModal />
          <GenInviteCodeModal />
        </div>
      </div>
      <FloatButtonGroup />
    </Layout>
  );
};

const FloatButtonGroup = () => {
  return (
    <div className="fixed right-4 lg:right-6 bottom-40 gap-4 flex flex-col">
      {!authStore.isVipUser && (
        <div
          className={clsx(
            'bg-white rounded p-2 shadow-[0_0_10px_0_rgba(0,0,0,0.1)]',
            'md:bg-transparent md:shadow-none md:p-0',
          )}
        >
          <PlanButton showTitle />
        </div>
      )}
      <div
        className={clsx(
          'bg-white rounded p-2 shadow-[0_0_10px_0_rgba(0,0,0,0.1)]',
          'md:bg-transparent md:shadow-none md:p-0',
        )}
      >
        <InviteButton showTitle />
      </div>
      <div
        className={clsx(
          'bg-white rounded p-2 shadow-[0_0_10px_0_rgba(0,0,0,0.1)]',
          'md:bg-transparent md:shadow-none md:p-0',
        )}
      >
        <Feedback showTitle />
      </div>
    </div>
  );
};
