import { Menu, MenuProps, Modal } from 'antd';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { useObservable } from '../../utils/useObservable';
import { Login } from './Login';
import { Register } from './Register';

const items: MenuProps['items'] = [
  {
    label: '登录',
    key: 'login',
  },
  {
    label: '注册',
    key: 'register',
  },
];

type LoginRegisterProps = {
  prePage?: string;
  className?: string;
  style?: React.CSSProperties;
  callback?: () => void;
};

export const LoginRegister = ({
  prePage,
  callback,
  className,
  style,
}: LoginRegisterProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const method = location.state?.method;

  const [current, setCurrent] = useState(method ?? 'login');

  const gotoPage = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className={className} style={style}>
      <Menu
        onClick={e => {
          setCurrent(e.key);
        }}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        className="mb-8"
      />
      {current === 'login' ? (
        <Login callback={callback ?? gotoPage} prePage={prePage} />
      ) : (
        <Register callback={callback ?? gotoPage} />
      )}
    </div>
  );
};

const loginRegisterModal$ = new BehaviorSubject(false);

export const toggleLoginRegisterModal = (isOpen: boolean) => {
  loginRegisterModal$.next(isOpen);
};

export const LoginRegisterModal = ({ callback }: { callback?: () => void }) => {
  const isModalOpen = useObservable(
    () => loginRegisterModal$,
    'loginRegisterModal',
  );

  const handleCancel = () => {
    toggleLoginRegisterModal(false);
  };

  return (
    <Modal
      open={!!isModalOpen}
      width={500}
      onCancel={handleCancel}
      footer={null}
      styles={{
        body: { padding: '0px 20px 0' },
      }}
    >
      <LoginRegister
        callback={() => {
          handleCancel();
          callback?.();
        }}
      />
    </Modal>
  );
};
