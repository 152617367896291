import { ErrorReasons, StatusCode } from '@chat/libs-types';
import { APIErrorWrapper } from '@clients/rest-api-client';
import authStore from '../auth/auth.store';

export const getLimitError = (e: any) => {
  if (
    e instanceof APIErrorWrapper &&
    e.status === StatusCode.FAILED_PRECONDITION &&
    e.details?.find(
      r =>
        r['@type'] === 'type.googleapis.com/google.rpc.ErrorInfo' &&
        r.reason === ErrorReasons.ACCESS_LIMITATION,
    )
  ) {
    if (!authStore.isLoggedIn || authStore.isGuest) {
      return (
        <div className="whitespace-pre-line text-left">{`您目前的使用已超出限额，请登录后再使用

        未登录用户权限：
        24h 内可上传 1 个文件，文件大小不超过 3MB
        24h 内可提出 10 个问题
        24h 内可用 AI 生成 12 个问题
        `}</div>
      );
    }

    return (
      <div className="whitespace-pre-line text-left">{`您已超出今日免费试用额度，请明天再来，或升级账号 ^ ^`}</div>
    );
  }

  return;
};
