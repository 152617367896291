import { useEffect, useState } from 'react';
import { assertNever } from '../typeHelper';
import { ObservableResource } from './ObservableResource';

export const useObservableResource = <T>(
  resource: ObservableResource<T>,
  startWithValue?: T,
): T => {
  const [latestValue, setLatestValue] = useState(() => {
    const resourceState = resource.reveal();

    if (resourceState.type === 'pending') {
      if (startWithValue != null) return startWithValue;
      throw resourceState.getPromise();
    } else if (resourceState.type === 'error') {
      throw resourceState.error;
    } else if (resourceState.type === 'value') {
      return resourceState.value;
    } else {
      assertNever(resourceState);
    }
  });

  useEffect(() => {
    const subscription = resource.subscribe({
      next: v => setLatestValue(() => v),
      error: e =>
        setLatestValue(() => {
          throw e;
        }),
    });
    return () => subscription.unsubscribe();
  }, [resource]);

  return latestValue;
};
