import { services } from '@clients/rest-api-client';
import { Button, Form, Input, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import authStore from '../../auth/auth.store';
import { assertIsError } from '../../utils/assert';

type ResetPasswordForm = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export const ResetPassword = () => {
  const user = authStore.user;
  const [form] = Form.useForm<ResetPasswordForm>();

  const [loading, setLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      span: 4,
      style: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
    },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    async (values: ResetPasswordForm) => {
      setLoading(true);
      try {
        await services.authService.authApiControllerResetPassword({
          ResetPasswordRequest: {
            old_password: values.oldPassword,
            new_password: values.password,
          },
        });

        message.info('密码修改成功');

        form.resetFields(['oldPassword', 'password', 'confirmPassword']);
      } catch (error) {
        assertIsError(error);
        message.warning(error.message);
      } finally {
        setLoading(false);
      }
    },
    [form],
  );

  if (user == null) {
    return;
  }

  return (
    <div className="py-8 px-2">
      <Form
        {...formItemLayout}
        form={form}
        name="basic"
        style={{ maxWidth: 600 }}
        initialValues={{
          avatar: user.avatar_url,
          nickname: user.nickname ?? user.email,
          email: user.email,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="旧密码"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: '请输入6-16位密码',
            },
            {
              pattern: /^.{6,16}$/,
              message: '请输入6-16位密码',
            },
          ]}
        >
          <Input.Password placeholder="请输入6-16位密码" />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="password"
          dependencies={['oldPassword']}
          rules={[
            {
              required: true,
              message: '请输入6-16位密码',
            },
            ({ getFieldValue }) => ({
              validator: (rule, value, callback) => {
                if (value == null && getFieldValue('oldPassword') != null) {
                  callback('请输入6-16位密码');
                }
                callback();
              },
            }),
            {
              pattern: /^.{6,16}$/,
              message: '请输入6-16位密码',
            },
          ]}
        >
          <Input.Password placeholder="请输入6-16位密码" />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: '请输入6-16位密码',
            },
            {
              validator: (rule, value, callback) => {
                if (value !== form.getFieldValue('password')) {
                  callback('两次输入密码不一致');
                }
                callback();
              },
            },
          ]}
        >
          <Input.Password placeholder="确认密码" />
        </Form.Item>
        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" loading={loading}>
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
