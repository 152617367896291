import * as ReactDOM from 'react-dom/client';
import 'reflect-metadata';
import App from './app/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// root.render(
//   <StrictMode>
//     <App />
//   </StrictMode>,
// );

root.render(<App />);
