import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you
import ReactMarkdown, { Options } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

export const Markdown = ({ children, ...rest }: Options) => {
  return (
    <ReactMarkdown
      components={{
        code(props) {
          const {
            children: code_children,
            className: code_className,
            ...code_rest
          } = props;
          const match = /language-(\w+)/.exec(code_className || '');
          return match ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <SyntaxHighlighter
              {...code_rest}
              children={String(code_children).replace(/\n$/, '')}
              style={dark}
              language={match[1]}
              PreTag="div"
            />
          ) : (
            <code {...code_rest} className={code_className}>
              {code_children}
            </code>
          );
        },
      }}
      rehypePlugins={[rehypeRaw, remarkGfm, rehypeKatex]}
      remarkPlugins={[remarkMath]}
      {...rest}
    >
      {children}
    </ReactMarkdown>
  );
};
