import { identity } from 'ramda';
import { useRef } from 'react';
import { AnyFunc } from './typeHelper';

export function usePersistFn<F extends AnyFunc>(
  fn: F,
  options?: {
    decorator?: <F extends AnyFunc>(fn: F) => F;
  },
): F {
  const fnRef = useRef<F>(fn);
  fnRef.current = fn;

  const persistFn = useRef<F>();
  if (!persistFn.current) {
    const decorator = options?.decorator ?? identity;
    persistFn.current = decorator(function (this: any, ...args) {
      return fnRef.current!.apply(this, args);
    } as F);
  }

  return persistFn.current!;
}
