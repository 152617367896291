import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import { useNavigate, useRouteError } from 'react-router-dom';

type ErrorResponse = {
  status?: ResultStatusType;
  statusText?: string;
  internal: boolean;
  data: string;
  message: string;
  error: Error;
};

export function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError() as ErrorResponse;
  console.error(error);

  return (
    <Result
      status={error.status || 'error'}
      title="Oops!"
      subTitle={
        <div>
          <div>"Sorry, an unexpected error has occurred."</div>
          <div>{error.statusText || error.message}</div>
        </div>
      }
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
}
