/* tslint:disable */
/* eslint-disable */
/**
 * AI Chat PDF API
 * AI Chat PDF API definitions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeLLMModelRequest,
} from '../models';

export interface UserApiControllerChangeLLMModelRequest {
    ChangeLLMModelRequest: ChangeLLMModelRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * 切换user的llm model
     */
    async userApiControllerChangeLLMModelRaw(requestParameters: UserApiControllerChangeLLMModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ChangeLLMModelRequest === null || requestParameters.ChangeLLMModelRequest === undefined) {
            throw new runtime.RequiredError('ChangeLLMModelRequest','Required parameter requestParameters.ChangeLLMModelRequest was null or undefined when calling userApiControllerChangeLLMModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/model:change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ChangeLLMModelRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 切换user的llm model
     */
    async userApiControllerChangeLLMModel(requestParameters: UserApiControllerChangeLLMModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerChangeLLMModelRaw(requestParameters, initOverrides);
    }

    /**
     * 升级到vip
     */
    async userApiControllerUpgradeVipRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/vip:upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 升级到vip
     */
    async userApiControllerUpgradeVip(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userApiControllerUpgradeVipRaw(initOverrides);
    }

}
