import { z } from 'zod';

export const UNAVAILABLE_ANSWER: { [key: string]: string } = {
  en: 'While analysing the document you provided, I met some challenges that prevented me from responding to this question. However, I can still try to answer your other questions.',
  zh: '很抱歉，根据文档里的信息，我暂时无法回答这个问题，请试试问我其他问题。',
} as const;

export const UNAVAILABLE_ANSWER_SCHEMA = z.nativeEnum(UNAVAILABLE_ANSWER);
export type UNAVAILABLE_ANSWER_SCHEMA_ENUM = z.infer<
  typeof UNAVAILABLE_ANSWER_SCHEMA
>;

export const ZLLMModelName = z.enum(['openai:gpt-3.5', 'openai:gpt-4']);

export type ZLLMModelName_ENUM = z.infer<typeof ZLLMModelName>;

export const LLM_MODEL_NAME_MAP = {
  [ZLLMModelName.Enum['openai:gpt-3.5']]: 'gpt-3.5-turbo-16k',
  [ZLLMModelName.Enum['openai:gpt-4']]: 'gpt-4',
} as const;
