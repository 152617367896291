import { Middleware, RequestContext } from '../../../generated/api';

export default class BearerAuthMiddleware implements Middleware {
  async pre(request: RequestContext) {
    const token = localStorage.getItem('token');
    return {
      ...request,
      init: {
        ...request.init,
        headers: {
          ...request.init.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    };
  }
}
