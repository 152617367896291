import { message } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import authStore from '../auth/auth.store';
import { Footer } from './Footer';
import { Header } from './Header';

type LayoutProps = {
  children: React.ReactNode;
};
export const Layout = ({ children }: LayoutProps) => {
  const { search } = useLocation();
  const pay = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('pay');
  }, [search]);

  useEffect(() => {
    if (pay === 'success') {
      authStore.fetchUserProfile();
      message.success('支付成功，请稍后刷新页面，查看会员等级变化！');
    }
  }, [pay]);

  return (
    <div className="overflow-auto flex flex-col justify-between min-h-screen">
      <Header />
      {children}
      <Footer />
    </div>
  );
};
