import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { borderRound } from '../../ChatPage';
import { ChatStoreContext } from '../../chat.store';
import { ChatContent } from './Content';
import { FooterTool } from './footer/Footer';
import { HeaderTool } from './Header';

export const Chat = () => {
  const store = useContext(ChatStoreContext);

  if (
    store.fileInfo?.file?.total_page == null &&
    store.fileInfo?.file?.failure_reason
  ) {
    return (
      <div className="flex justify-center items-center h-full">
        <span className="text-red-800">
          文件无法识别: {store.fileInfo?.file?.failure_reason}
        </span>
      </div>
    );
  }

  if (store.fileInfo?.file?.total_page == null) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col p-2 gap-1 ${borderRound} text-sm h-full box-border`}
    >
      <HeaderTool />
      <div className="text-center text-xs text-gray-600 p-1 ">
        {dayjs().format('HH:mm')}
      </div>
      <ChatContent />
      <FooterTool />
    </div>
  );
};
